import {
  MATERIAL_ALL,
  MATERIAL_GET,
  MATERIAL_DEL,
  MATERIAL_PUT,
  MATERIAL_POST,
  MATERIAL_LOADING,
  MATERIAL_ERROR,
} from '../../actions/types';

const initialState = {
  material: null,
  isLoading: true,
  isCompleted: true,
};
// eslint-disable-next-line func-names
export default function (state = initialState, action) {
  switch (action.type) {
    case MATERIAL_LOADING:
      return { isLoading: true, isCompleted: true };
    case MATERIAL_ERROR:
      return { isLoading: false, isCompleted: false, ...action.payload };
    case MATERIAL_ALL:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case MATERIAL_GET:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case MATERIAL_POST:
      return { isLoading: false, isCompleted: true };
    case MATERIAL_PUT:
      return { isLoading: false, isCompleted: true };
    case MATERIAL_DEL:
      return { isLoading: false, isCompleted: true };
    default:
      return state;
  }
}
