import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
// import useSound from 'use-sound';

// import { io } from 'socket.io-client';

// import { FullScreen, useFullScreenHandle } from 'react-full-screen';

// eslint-disable-next-line object-curly-newline
import {
  Backdrop,
  Button,
  Table,
  Fade,
  Modal,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Card,
  TextField,
  InputAdornment,
  Tooltip,
  // ToggleButtonGroup,
  // ToggleButton,
  Switch,
  FormControlLabel,
  Snackbar,
  // Slide,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  // IconButton,
} from '@mui/material';

// import LaptopIcon from '@mui/icons-material/Laptop';
// import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import DescriptionIcon from '@mui/icons-material/Description';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
// import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import SaveIcon from '@mui/icons-material/Save';
import EditLocationAltIcon from '@mui/icons-material/EditLocationAlt';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import CancelIcon from '@mui/icons-material/Cancel';
import AddBoxIcon from '@mui/icons-material/AddBox';

import { useForm, Controller } from 'react-hook-form';

import dayjs from 'dayjs';
import _ from 'lodash';
import 'dayjs/locale/th';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import PropTypes from 'prop-types';
// import NotificationSound from '../../assets/NotificationSound/Loud-Horn-Notification.mp3';
// import newGif from '../../assets/images/new-post.gif';
import * as actions from '../../redux/actions';
import Loading from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';
import { Clock } from '../../components/DigitalClock';

import * as constants from '../../utils/constants';
// import { fa } from 'faker/lib/locales';

dayjs.locale('th');
dayjs.extend(buddhistEra);

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90vw',
  boxShadow: 24,
  p: 4,
};

const defaultValue = {
  tracking_number: '',
  cancel_detail: '',
};

const OnlineManagement = ({ title, subtitle }) => {
  // const socket = useRef();
  // const [playAudio] = useSound(NotificationSound2);
  const dispatch = useDispatch();
  const me = useSelector((state) => state.me);
  // const product = useSelector((state) => state.product);
  const paymentTransaction = useSelector((state) => state.paymentTransaction);
  const option = useSelector((state) => state.option);
  const setting = useSelector((state) => state.setting);

  const [stateAlert, setStateAlert] = useState({
    open: false,
    vertical: 'top',
    horizontal: 'right',
  });

  const { vertical, horizontal, open } = stateAlert;

  const history = useHistory();

  // console.log('me', me);

  // const socket = io(process.env.REACT_APP_SOCKET_URL);

  // console.log('product', product?.rows);
  // const product = useSelector((state) => state.product);
  // console.log('paymentTransaction', paymentTransaction.rows);
  const [searchTerm, setSearchTerm] = useState('');
  const [name, setName] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(50);
  const [total, setTotal] = useState(undefined);
  const [openTrack, setOpenTrack] = useState(false);
  const [openOrderCancel, setOpenOrderCancel] = useState(false);
  const [layout, setLayout] = useState('computer');
  const [selectPaymentType, setSelectPaymentType] = useState('');
  const [selectOrderStatus, setSelectOrderStatus] = useState('');
  const [checkPaymentOrder, setCheckPaymentOrder] = useState(false);
  const [reload, setReload] = useState(false);
  const [openShop, setOpenShop] = useState(setting?.open_shop);
  // const [onlineUsers, setOnlineUsers] = useState([]);

  const newPaymentTransaction = _.filter(
    paymentTransaction?.rows,
    // eslint-disable-next-line consistent-return
    (eachPaymentTransaction) => {
      if (eachPaymentTransaction?.status_order !== 'PRODUCT_COMPLETE') {
        if (eachPaymentTransaction?.status_order !== 'PRODUCT_CANCEL') {
          return eachPaymentTransaction;
        }
      }
    },
  );

  console.log('newPaymentTransaction', newPaymentTransaction);
  console.log('paymentTransaction', paymentTransaction);

  // const handleLayout = (event, newLayout) => {
  //   if (newLayout !== null) {
  //     setLayout(newLayout);
  //     localStorage.setItem('layout', newLayout);
  //   }
  // };

  function toggleFullScreen() {
    const doc = window.document;
    const docEl = doc.documentElement;

    const requestFullScreen =
      docEl.requestFullscreen ||
      docEl.mozRequestFullScreen ||
      docEl.webkitRequestFullScreen ||
      docEl.msRequestFullscreen;
    const cancelFullScreen =
      doc.exitFullscreen ||
      doc.mozCancelFullScreen ||
      doc.webkitExitFullscreen ||
      doc.msExitFullscreen;

    if (
      !doc.fullscreenElement &&
      !doc.mozFullScreenElement &&
      !doc.webkitFullscreenElement &&
      !doc.msFullscreenElement
    ) {
      requestFullScreen.call(docEl);
    } else {
      cancelFullScreen.call(doc);
    }
  }

  const [selectOrder, setSelectOrder] = useState(null);

  const { control, handleSubmit, reset } = useForm({
    mode: 'onBlur',
  });

  const handleChangePaymentType = (event) => {
    console.log('type payment', event.target.value);
    setSelectPaymentType(event.target.value);
  };

  const handleChangeOrderStatus = (event) => {
    console.log('type order status', event.target.value);
    setSelectOrderStatus(event.target.value);
  };

  const handleOpenTrack = () => setOpenTrack(true);
  const handleCloseTrack = () => setOpenTrack(false);

  const handleOpenOrderCancel = () => setOpenOrderCancel(true);
  const handleCloseOrderCancel = () => setOpenOrderCancel(false);

  useEffect(() => {
    const fetchPaymentTransaction = async () => {
      try {
        await dispatch(actions.meGet());
        await dispatch(actions.settingAll());
        await dispatch(
          actions.paymentTransactionAll({
            name,
            page,
            size,
            checkPaymentOrder,
            selectPaymentType,
            selectOrderStatus,
          }),
        );
        await dispatch(actions.getAllOption(''));
        // await dispatch(actions.productAll(''));
      } catch (error) {
        console.error(error);
      }
    };
    fetchPaymentTransaction();

    return () => {};
  }, [
    name,
    page,
    size,
    checkPaymentOrder,
    selectPaymentType,
    selectOrderStatus,
  ]);

  // useEffect(() => {
  //   socket.current = io(process.env.REACT_APP_SOCKET_URL);
  //   // socket.current.emit('new-user-add', me?.userData?.id);
  // }, [me]);

  // useEffect(() => {
  //   socket.current.on('get-users', (users) => {
  //     console.log('users online', users);
  //     setOnlineUsers(users);
  //   });
  // }, [me]);

  // useEffect(() => {
  //   socket.current.on('receive-order', (data) => {
  //     console.log('receive-order', data);
  //     dispatch(
  //       actions.paymentTransactionAll({
  //         name,
  //         page,
  //         size,
  //         checkPaymentOrder,
  //         selectPaymentType,
  //         selectOrderStatus,
  //       }),
  //     );

  //     if (data === 'new-order') {
  //       setStateAlert({ open: true, vertical: 'top', horizontal: 'right' });

  //       setTimeout(() => {
  //         setStateAlert({ open: false, vertical: 'top', horizontal: 'right' });
  //       }, 8000);
  //     }
  //   });
  // }, []);

  const handleCloseAlert = () => {
    setStateAlert({ open: false, vertical: 'top', horizontal: 'right' });
  };

  useEffect(() => {
    if (reload) {
      setTimeout(() => {
        console.warn('FETCH TXN');
        dispatch(
          actions.paymentTransactionAll({
            name,
            page,
            size,
            checkPaymentOrder,
            selectPaymentType,
            selectOrderStatus,
          }),
        );
        setReload(false);
      }, 1000);
    }

    return () => {};
  }, [reload]);

  useEffect(() => {
    setTotal(newPaymentTransaction.length);
    setOpenShop(setting?.open_shop);
    return () => {};
  }, [paymentTransaction, setting]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchTerm);
      setPage(1);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleToInfoPage = async (id) => {
    history.push(`/spm/online/${id}`);
  };

  // const checkProduct = (id) => {
  //   const productOfId = _.filter(
  //     product.rows,
  //     (eachProduct) => eachProduct.id === id,
  //   );
  //   return productOfId[0];
  // };

  const checkOption = (optionId) => {
    const optionData = _.find(option?.rows, { id: optionId });

    return optionData;
  };

  const changeStatus = async (id, data, tel) => {
    const payload = {
      status_order: data,
      tel,
    };

    console.log('payload', payload, id);

    await dispatch(actions.paymentTransactionPut(id, payload));
    await dispatch(
      actions.paymentTransactionAll({
        name,
        page,
        size,
        selectPaymentType,
        selectOrderStatus,
      }),
    );
    // socket.current.emit('new-order', 'new-status');
  };

  // const handleChangeSelect = (event) => {
  //   setCheckPaymentOrder(event.target.checked);
  // };

  const handleCutStock = async (data, id, customer) => {
    // const confirm = window.confirm(
    //   'จัดเตรียมสินค้าเรียบร้อย จะทำการตัดสต็อกสินค้า',
    // );

    console.log('data cut stock', data, customer);

    // if (confirm) {
    const preprocessedData = {
      customer_name: customer.name,
      product_transaction_type: 'online',
      order: _.map(data, (each) => ({
        option: each.option,
        product: each.product,
        amount: each.quantity,
      })),
    };
    console.log('preprocessedData', preprocessedData);
    await dispatch(actions.productTransactionCreate(preprocessedData));
    changeStatus(id, constants.TRANSACTION_STATUS.PRODUCT_PREPARED.status_code);
  };
  // };

  const handleDeliveryTrack = async (data, id) => {
    const confirm = window.confirm(
      'ยืนยันการจัดส่ง พร้อมใส่หมายเลขติดตามพัสดุ',
    );

    console.log('data', data);

    if (confirm) {
      changeStatus(id, constants.TRANSACTION_STATUS.PRODUCT_TRANS.status_code);
    }
  };

  const handleCompleteOrder = async (data, id) => {
    console.log('data', data);

    // const confirm = window.confirm('ยืนยันการสำเร็จ');

    // if (confirm) {
    changeStatus(
      id,
      constants.TRANSACTION_STATUS.PRODUCT_COMPLETE.status_code,
      data?.order_id?.delivery?.tel,
    );
    // }
  };

  const handleChangeOpenShop = async (event) => {
    setOpenShop(event.target.checked);
    console.log('setting open shop data', event.target.checked);
    // socket.current.emit('change-open-shop', event.target.checked);
    try {
      await dispatch(
        actions.settingPut(setting.id, {
          ...setting,
          open_shop: event.target.checked,
          promptpay: {
            accountName: setting?.promptpay?.accountName,
            accountNumber: setting?.promptpay?.accountNumber,
            available: setting?.promptpay?.available,
            QrCode: setting?.promptpay?.QrCode?.id,
          },
          images: _.map(setting?.banner, (image) => ({
            // eslint-disable-next-line no-underscore-dangle
            id: image.image_id,
            image: image.data_url || image.image.url,
          })),
        }),
      );
      await dispatch(actions.settingAll());
      await dispatch(
        actions.paymentTransactionAll({
          name,
          page,
          size,
          checkPaymentOrder,
          selectPaymentType,
          selectOrderStatus,
        }),
      );
      await dispatch(actions.getAllOption(''));
      // await dispatch(actions.productAll(''));
    } catch (error) {
      console.error(error);
    }
  };

  const renderChangeLayout = () => (
    <div className="flex flex-row justify-end pb-4">
      <div className="pr-2 my-auto">
        <FormControlLabel
          control={
            <Switch checked={openShop} onChange={handleChangeOpenShop} />
          }
          label={openShop ? 'ร้านเปิด' : 'ร้านปิด'}
        />
      </div>
      <div className="pr-2 my-auto">
        <Link to="/spm/online/create">
          <Button startIcon={<AddBoxIcon />} variant="contained">
            สร้างบิล
          </Button>
        </Link>
      </div>
      <div className="pr-2 my-auto">
        <Button
          variant="contained"
          onClick={() => toggleFullScreen()}
          startIcon={<FullscreenIcon />}
        >
          เต็มจอ
        </Button>
      </div>
      {/* <div className="pr-2 my-auto">เลือกมุมมอง</div>
      <ToggleButtonGroup
        value={layout}
        exclusive
        onChange={handleLayout}
        aria-label="text alignment"
      >
        <ToggleButton value="computer" aria-label="left aligned">
          <LaptopIcon />
        </ToggleButton>
        <ToggleButton value="phone" aria-label="right aligned">
          <PhoneAndroidIcon />
        </ToggleButton>
      </ToggleButtonGroup> */}
    </div>
  );

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const renderSearch = () => (
    <Card>
      <div className="pt-4 px-4 md:flex gap-2">
        <div className="w-full md:w-1/2">
          <TextField
            label="ค้นหา"
            fullWidth
            size={'small'}
            id="outlined-start-adornment"
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <i className="fas fa-search"></i>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className="w-full md:w-1/2 text-center my-auto text-gray-800 flex justify-center">
          {/* <FormControlLabel
            control={
              <Checkbox
                checked={checkPaymentOrder}
                onChange={handleChangeSelect}
                name="checkOrder"
              />
            }
            label="แสดงเฉพาะออเดอร์ที่ชำระเงินเเล้ว"
          /> */}
          <Clock hour12={false} />
        </div>
      </div>
      <div className="w-full pb-4 px-4 pt-2 md:flex gap-2">
        <div className="w-full md:w-1/2">
          <FormControl sx={{ minWidth: 120 }} fullWidth={true}>
            <InputLabel id="type" size={'small'}>
              ประเภทคำสั่งซื้อ
            </InputLabel>
            <Select
              label="ประเภทคำสั่งซื้อ"
              size={'small'}
              value={selectPaymentType}
              onChange={handleChangePaymentType}
              fullWidth
            >
              <MenuItem key={0} value={''}>
                แสดงทั้งหมด
              </MenuItem>
              <MenuItem key={1} value={'online'}>
                ขายออนไลน์
              </MenuItem>
              <MenuItem key={2} value={'quotation'}>
                เสนอราคา
              </MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="w-full md:w-1/2">
          <FormControl sx={{ minWidth: 120 }} fullWidth={true}>
            <InputLabel id="type" size={'small'}>
              สถานะ
            </InputLabel>
            <Select
              label="สถานะ"
              size={'small'}
              value={selectOrderStatus}
              onChange={handleChangeOrderStatus}
              fullWidth
            >
              <MenuItem key={0} value={''}>
                แสดงทั้งหมด
              </MenuItem>
              <MenuItem key={1} value={'ORDER_WAIT'}>
                รอการยืนยัน
              </MenuItem>
              <MenuItem key={2} value={'ORDER_CONFIRM'}>
                ยืนยันเรียบร้อย
              </MenuItem>
              <MenuItem key={3} value={'QUOTATION_SEND'}>
                ส่งใบ QNเรียบร้อย
              </MenuItem>
              <MenuItem key={4} value={'PO_RECEIVE'}>
                ได้รับใบ PO เรียบร้อย รอเตรียมสินค้า
              </MenuItem>
              <MenuItem key={5} value={'PRODUCT_PREPARED'}>
                จัดเตรียมสินค้าเรียบร้อย
              </MenuItem>
              <MenuItem key={6} value={'PRODUCT_TRANS'}>
                จัดส่งสินค้าเรียบร้อย
              </MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>
    </Card>
  );

  // console.log('data in tracking', selectOrder);

  const onSubmitTracking = async (data) => {
    const payload = {
      tracking_number: data?.tracking_number,
    };
    const id = selectOrder?.source_id;
    await dispatch(actions.paymentTransactionPut(id, payload));
    reset(defaultValue);
    handleCloseTrack();
    await dispatch(
      actions.paymentTransactionAll({
        name,
        page,
        size,
        selectPaymentType,
        selectOrderStatus,
      }),
    );
  };

  // const handleDeleteOrder = async (data, id) => {
  //   const confirm = window.confirm('ยืนยันการยกเลิกออเดอร์');
  //   if (confirm) {
  //     try {
  //       changeStatus(
  //         id,
  //         constants.TRANSACTION_STATUS.PRODUCT_CANCEL.status_code,
  //         data?.order_id?.delivery?.tel,
  //       );
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   }
  // };

  const onSubmitOrderCancel = async (data) => {
    const payload = {
      cancel_detail: data?.cancel_detail,
      status_order: constants.TRANSACTION_STATUS.PRODUCT_CANCEL.status_code,
    };
    const confirm = window.confirm('ยืนยันการยกเลิกออเดอร์');
    if (confirm) {
      const id = selectOrder?.source_id;
      await dispatch(actions.paymentTransactionPut(id, payload));
      reset(defaultValue);
      handleCloseOrderCancel();
      await dispatch(
        actions.paymentTransactionAll({
          name,
          page,
          size,
          selectPaymentType,
          selectOrderStatus,
        }),
      );
    }
  };

  const renderModalOrderCancel = () => (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={openOrderCancel}
      onClose={handleCloseOrderCancel}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openOrderCancel}>
        <Card sx={style} className="max-w-sm ">
          <div className="py-2">เหตุผลที่ยกเลิก</div>
          <div className="py-2">
            <form onSubmit={handleSubmit(onSubmitOrderCancel)}>
              <div className="w-full  px-1 py-2">
                <Controller
                  name={'cancel_detail'}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="เหตุผล"
                      fullWidth
                      type="string"
                      size={'small'}
                      multiline
                      rows={3}
                      maxRows={5}
                      required
                    />
                  )}
                />
              </div>
              <div className="flex flex-row justify-end gap-1 py-4">
                <Button
                  variant="contained"
                  type="submit"
                  startIcon={<SaveIcon />}
                >
                  บันทึก
                </Button>
                <Button variant="outlined" onClick={handleCloseOrderCancel}>
                  ยกเลิก
                </Button>
              </div>
            </form>
          </div>
        </Card>
      </Fade>
    </Modal>
  );

  const renderModalAddTrackingNumber = () => (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={openTrack}
      onClose={handleCloseTrack}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openTrack}>
        <Card sx={style} className="max-w-sm ">
          <div className="py-2">เพิ่มหมายเลขติดตามสินค้า</div>
          <div className="py-2">
            <form onSubmit={handleSubmit(onSubmitTracking)}>
              <div className="w-full  px-1 py-2">
                <Controller
                  name={'tracking_number'}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="หมายเลขติดตามสินค้า"
                      fullWidth
                      type="string"
                      size={'small'}
                      required
                      defaultValue={selectOrder?.tracking_number}
                    />
                  )}
                />
              </div>
              <div className="flex flex-row justify-end gap-1 py-4">
                <Button
                  variant="contained"
                  type="submit"
                  startIcon={<SaveIcon />}
                >
                  บันทึก
                </Button>
                <Button variant="outlined" onClick={handleCloseTrack}>
                  ยกเลิก
                </Button>
              </div>
            </form>
          </div>
        </Card>
      </Fade>
    </Modal>
  );
  // eslint-disable-next-line max-len
  // Button Component ************************************************************************************
  /* status Button */

  const disabledButtonConfirm = (status) =>
    // eslint-disable-next-line implicit-arrow-linebreak
    status === constants.TRANSACTION_STATUS.ORDER_CONFIRM.status_code ||
    status === constants.TRANSACTION_STATUS.QUOTATION_SEND.status_code ||
    status === constants.TRANSACTION_STATUS.PO_RECEIVE.status_code ||
    status === constants.TRANSACTION_STATUS.PRODUCT_PREPARED.status_code ||
    status === constants.TRANSACTION_STATUS.PRODUCT_TRANS.status_code ||
    status === constants.TRANSACTION_STATUS.PRODUCT_COMPLETE.status_code;

  const disabledButtonQuotation = (status, paymentType) => {
    // console.log('paymentType', paymentType, status);
    if (paymentType === 'quotation') {
      return (
        status === constants.TRANSACTION_STATUS.ORDER_WAIT.status_code ||
        status === constants.TRANSACTION_STATUS.PRODUCT_PREPARED.status_code ||
        status === constants.TRANSACTION_STATUS.QUOTATION_SEND.status_code ||
        status === constants.TRANSACTION_STATUS.PO_RECEIVE.status_code ||
        status === constants.TRANSACTION_STATUS.PRODUCT_TRANS.status_code ||
        status === constants.TRANSACTION_STATUS.PRODUCT_COMPLETE.status_code
      );
    }
    return (
      status === constants.TRANSACTION_STATUS.ORDER_WAIT.status_code ||
      status === constants.TRANSACTION_STATUS.PRODUCT_PREPARED.status_code ||
      status === constants.TRANSACTION_STATUS.QUOTATION_SEND.status_code ||
      status === constants.TRANSACTION_STATUS.PO_RECEIVE.status_code ||
      status === constants.TRANSACTION_STATUS.PRODUCT_TRANS.status_code ||
      status === constants.TRANSACTION_STATUS.PRODUCT_COMPLETE.status_code ||
      paymentType !== 'quotation'
    );
  };

  const disabledButtonPOReceive = (status, paymentType) => {
    if (paymentType === 'quotation') {
      return (
        status === constants.TRANSACTION_STATUS.ORDER_WAIT.status_code ||
        status === constants.TRANSACTION_STATUS.ORDER_CONFIRM.status_code ||
        status === constants.TRANSACTION_STATUS.PRODUCT_PREPARED.status_code ||
        status === constants.TRANSACTION_STATUS.PO_RECEIVE.status_code ||
        status === constants.TRANSACTION_STATUS.PRODUCT_TRANS.status_code ||
        status === constants.TRANSACTION_STATUS.PRODUCT_COMPLETE.status_code
      );
    }
    return (
      status === constants.TRANSACTION_STATUS.ORDER_WAIT.status_code ||
      status === constants.TRANSACTION_STATUS.ORDER_CONFIRM.status_code ||
      status === constants.TRANSACTION_STATUS.PRODUCT_PREPARED.status_code ||
      status === constants.TRANSACTION_STATUS.PO_RECEIVE.status_code ||
      status === constants.TRANSACTION_STATUS.PRODUCT_TRANS.status_code ||
      status === constants.TRANSACTION_STATUS.PRODUCT_COMPLETE.status_code ||
      paymentType !== 'quotation'
    );
  };

  const disabledButtonCutStock = (status, paymentType) => {
    if (paymentType === 'quotation') {
      return (
        status === constants.TRANSACTION_STATUS.ORDER_WAIT.status_code ||
        status === constants.TRANSACTION_STATUS.ORDER_CONFIRM.status_code ||
        status === constants.TRANSACTION_STATUS.PRODUCT_PREPARED.status_code ||
        status === constants.TRANSACTION_STATUS.QUOTATION_SEND.status_code ||
        status === constants.TRANSACTION_STATUS.PRODUCT_TRANS.status_code ||
        status === constants.TRANSACTION_STATUS.PRODUCT_COMPLETE.status_code
      );
    }
    return (
      status === constants.TRANSACTION_STATUS.ORDER_WAIT.status_code ||
      status === constants.TRANSACTION_STATUS.PRODUCT_PREPARED.status_code ||
      status === constants.TRANSACTION_STATUS.QUOTATION_SEND.status_code ||
      status === constants.TRANSACTION_STATUS.PRODUCT_TRANS.status_code ||
      status === constants.TRANSACTION_STATUS.PRODUCT_COMPLETE.status_code
    );
  };

  const disabledButtonDelivery = (status) =>
    // eslint-disable-next-line implicit-arrow-linebreak
    status === constants.TRANSACTION_STATUS.ORDER_CONFIRM.status_code ||
    status === constants.TRANSACTION_STATUS.QUOTATION_SEND.status_code ||
    status === constants.TRANSACTION_STATUS.PO_RECEIVE.status_code ||
    status === constants.TRANSACTION_STATUS.ORDER_WAIT.status_code ||
    status === constants.TRANSACTION_STATUS.PRODUCT_TRANS.status_code ||
    status === constants.TRANSACTION_STATUS.PRODUCT_COMPLETE.status_code;

  const checkStatusMessage = (status, paymentType) => {
    if (paymentType === 'quotation') {
      if (status === constants.TRANSACTION_STATUS.ORDER_WAIT.status_code) {
        return 'รอการยืนยัน';
      }
      if (status === constants.TRANSACTION_STATUS.ORDER_CONFIRM.status_code) {
        return 'รอส่งใบ QN';
      }
      if (status === constants.TRANSACTION_STATUS.QUOTATION_SEND.status_code) {
        return 'รอรับใบ PO';
      }
      if (status === constants.TRANSACTION_STATUS.PO_RECEIVE.status_code) {
        return 'รอเตรียมสินค้า';
      }
      if (
        status === constants.TRANSACTION_STATUS.PRODUCT_PREPARED.status_code
      ) {
        return 'รอจัดส่ง';
      }
      if (status === constants.TRANSACTION_STATUS.PRODUCT_TRANS.status_code) {
        return 'จัดส่งเรียบร้อย';
      }
    } else {
      if (status === constants.TRANSACTION_STATUS.ORDER_WAIT.status_code) {
        return 'รอการยืนยัน';
      }
      if (status === constants.TRANSACTION_STATUS.ORDER_CONFIRM.status_code) {
        return 'รอเตรียมสินค้า';
      }
      if (status === constants.TRANSACTION_STATUS.QUOTATION_SEND.status_code) {
        return 'รอเตรียมสินค้า';
      }
      if (status === constants.TRANSACTION_STATUS.PO_RECEIVE.status_code) {
        return 'รอเตรียมสินค้า';
      }
      if (
        status === constants.TRANSACTION_STATUS.PRODUCT_PREPARED.status_code
      ) {
        return 'รอจัดส่ง';
      }
      if (status === constants.TRANSACTION_STATUS.PRODUCT_TRANS.status_code) {
        return 'จัดส่งเรียบร้อย';
      }
    }
    return '';
  };

  const buttonDetailOrderModule = (data) => (
    <>
      <Button
        variant="contained"
        color={'secondary'}
        fullWidth
        startIcon={<DescriptionIcon />}
        size={'small'}
        onClick={() => handleToInfoPage(data?.id)}
      >
        รายละเอียด
      </Button>
    </>
  );

  // const buttonDetailOrder = (data) => (
  //   <div className="w-full">
  //     <Button
  //       variant="contained"
  //       startIcon={<DescriptionIcon />}
  //       sx={{ backgroundColor: '#049dcc' }}
  //       fullWidth
  //       onClick={() => {
  //         handleToInfoPage(data?.id);
  //       }}
  //     >
  //       รายละเอียด
  //     </Button>
  //   </div>
  // );

  // ปุ่มของสินค้าที่มีการจัดส่ง
  const buttonConfirmOrder = (data) => (
    // const currentStatus = constants.TRANSACTION_STATUS[data.status_order];
    <div className={`${layout === 'computer' ? 'w-1/3' : 'w-full'}`}>
      <Button
        sx={{ backgroundColor: '#f005d0' }}
        variant="contained"
        fullWidth
        disabled={
          data?.status === 'failed' ||
          disabledButtonConfirm(data?.status_order, data?.payment_type)
        }
        size={'small'}
        onClick={() => {
          // const confirm = window.confirm('ยืนยันคำสั่งซื้อ');
          // if (confirm) {
          changeStatus(
            data?.source_id,
            constants.TRANSACTION_STATUS.ORDER_CONFIRM.status_code,
          );
          // }
        }}
      >
        {/* {statusButtonConfirm(data?.status_order, currentStatus) && (
            <i className="fas fa-check pr-1"></i>
          )} */}
        ยืนยัน
      </Button>
    </div>
  );

  const buttonQuotationSend = (data) => (
    // const currentStatus = constants.TRANSACTION_STATUS[data.status_order];
    <div className="w-1/3">
      <Button
        sx={{ backgroundColor: '#1607e8' }}
        variant="contained"
        size={'small'}
        fullWidth
        disabled={
          data?.status === 'failed' ||
          disabledButtonQuotation(data?.status_order, data?.payment_type)
        }
        onClick={() => {
          const confirm = window.confirm('ยืนยันการส่งใบ QNแล้ว');
          if (confirm) {
            // eslint-disable-next-line implicit-arrow-linebreak
            changeStatus(
              data?.source_id,
              constants.TRANSACTION_STATUS.QUOTATION_SEND.status_code,
            );
          }
        }}
      >
        {/* {statusButtonCutStock(data?.status_order, currentStatus) && (
            <i className="fas fa-check pr-1"></i>
          )} */}
        ส่งใบ QN
      </Button>
    </div>
  );

  const buttonPOReceive = (data) => (
    // const currentStatus = constants.TRANSACTION_STATUS[data.status_order];
    <div className="w-1/3">
      <Button
        sx={{ backgroundColor: '#1607e8' }}
        variant="contained"
        size={'small'}
        fullWidth
        disabled={
          data?.status === 'failed' ||
          disabledButtonPOReceive(data?.status_order, data?.payment_type)
        }
        onClick={() => {
          const confirm = window.confirm('ยืนยันการได้รับใบ PO แล้ว');
          if (confirm) {
            // eslint-disable-next-line implicit-arrow-linebreak
            changeStatus(
              data?.source_id,
              constants.TRANSACTION_STATUS.PO_RECEIVE.status_code,
            );
          }
        }}
      >
        {/* {statusButtonCutStock(data?.status_order, currentStatus) && (
            <i className="fas fa-check pr-1"></i>
          )} */}
        รับใบ PO
      </Button>
    </div>
  );

  const buttonCutStockOrder = (data) => (
    // const currentStatus = constants.TRANSACTION_STATUS[data.status_order];
    <div className={`${layout === 'computer' ? 'w-1/3' : 'w-full'}`}>
      <Button
        sx={{ backgroundColor: '#1607e8' }}
        variant="contained"
        size={'small'}
        fullWidth
        disabled={
          data?.status === 'failed' ||
          disabledButtonCutStock(data?.status_order, data?.payment_type)
        }
        onClick={() => {
          const confirmCut = window.confirm('ต้องการตัดสต๊อคสินค้าหรือไม่');
          if (confirmCut) {
            // eslint-disable-next-line implicit-arrow-linebreak
            handleCutStock(
              data?.order_id?.orders,
              data?.source_id,
              data.customer,
            );
          } else {
            changeStatus(
              data?.source_id,
              constants.TRANSACTION_STATUS.PRODUCT_PREPARED.status_code,
            );
          }
        }}
      >
        {/* {statusButtonCutStock(data?.status_order, currentStatus) && (
            <i className="fas fa-check pr-1"></i>
          )} */}
        เตรียม
      </Button>
    </div>
  );
  const buttonDeliveryOrder = (data) => (
    // const currentStatus = constants.TRANSACTION_STATUS[data.status_order];
    <div className={`${layout === 'computer' ? 'w-1/3' : 'w-full'}`}>
      <Button
        variant="contained"
        fullWidth
        sx={{ backgroundColor: '#e8d907' }}
        // startIcon={<LocalShippingIcon />}
        size={'small'}
        disabled={
          data?.status === 'failed' ||
          disabledButtonDelivery(data?.status_order, data?.payment_type)
        }
        onClick={() =>
          // eslint-disable-next-line implicit-arrow-linebreak
          handleDeliveryTrack(data?.order_id?.orders, data?.source_id)
        }
      >
        {/* {statusButtonDelivery(data?.status_order, currentStatus) && (
            <i className="fas fa-check pr-1"></i>
          )} */}
        จัดส่ง
      </Button>
    </div>
  );

  const buttonFinishOrder = (data) => (
    <div className={`${layout === 'computer' ? 'w-1/3' : 'w-full'}`}>
      <Button
        variant="contained"
        sx={{ backgroundColor: '#00ab03' }}
        fullWidth
        // startIcon={<CheckCircleOutlineIcon />}
        size={'small'}
        disabled={
          data?.status === 'failed' || data?.status_order !== 'PRODUCT_TRANS'
        }
        onClick={() =>
          // eslint-disable-next-line implicit-arrow-linebreak
          handleCompleteOrder(data, data?.source_id)
        }
        // onClick={() => handleToInfoPage(row.id)}
        // PRODUCT_COMPLETE
      >
        สำเร็จ
      </Button>
    </div>
  );

  // ปุ่มของสินค้าที่ไม่มีการจัดส่ง
  const buttonConfirmOrderNotDelivery = (data) => (
    // const currentStatus = constants.TRANSACTION_STATUS[data.status_order];
    <div className={`${layout === 'computer' ? 'w-1/3' : 'w-full'}`}>
      <Button
        sx={{ backgroundColor: '#f005d0' }}
        variant="contained"
        fullWidth
        disabled={
          data?.status === 'failed' || disabledButtonConfirm(data?.status_order)
        }
        size={'small'}
        onClick={() => {
          // const confirm = window.confirm('ยืนยันคำสั่งซื้อ');
          // if (confirm) {
          changeStatus(
            data?.source_id,
            constants.TRANSACTION_STATUS.ORDER_CONFIRM.status_code,
          );
          // }
        }}
      >
        {/* {statusButtonConfirm(data?.status_order, currentStatus) && (
            <i className="fas fa-check pr-1"></i>
          )} */}
        ยืนยัน
      </Button>
    </div>
  );
  const buttonCutStockOrderNotDelivery = (data) => (
    // const currentStatus = constants.TRANSACTION_STATUS[data.status_order];
    <div className={`${layout === 'computer' ? 'w-1/3' : 'w-full'}`}>
      <Button
        sx={{ backgroundColor: '#1607e8' }}
        variant="contained"
        size={'small'}
        fullWidth
        disabled={
          data?.status === 'failed' ||
          disabledButtonCutStock(data?.status_order)
        }
        onClick={() =>
          // eslint-disable-next-line implicit-arrow-linebreak
          handleCutStock(data?.order_id?.orders, data?.source_id, data.customer)
        }
      >
        {/* {statusButtonCutStock(data?.status_order, currentStatus) && (
            <i className="fas fa-check pr-1"></i>
          )} */}
        เตรียมสินค้า
      </Button>
    </div>
  );

  const buttonFinishOrderNotDelivery = (data) => (
    <div className={`${layout === 'computer' ? 'w-1/3' : 'w-full'}`}>
      <Button
        variant="contained"
        sx={{ backgroundColor: '#00ab03' }}
        fullWidth
        startIcon={<CheckCircleOutlineIcon />}
        size={'small'}
        disabled={
          data?.status === 'failed' ||
          disabledButtonDelivery(data?.status_order)
        }
        onClick={() =>
          // eslint-disable-next-line implicit-arrow-linebreak
          handleCompleteOrder(data, data?.source_id)
        }
        // onClick={() => handleToInfoPage(row.id)}
        // PRODUCT_COMPLETE
      >
        สำเร็จ
      </Button>
    </div>
  );

  const renderModules = () => (
    <>
      {!_.isEmpty(newPaymentTransaction) ? (
        <div className="my-2 grid grid-cols-1 sm:grid-cols-3 xl:grid-cols-4 gap-2">
          {newPaymentTransaction.map((row, index) => (
            <Card
              key={index}
              className={
                row?.status_order === 'ORDER_WAIT'
                  ? 'w-full border-2 border-yellow-400 duration-150 ease-in-out transform hover:-translate-y-1  hover:shadow-2xl p-4'
                  : 'w-full duration-150 ease-in-out transform hover:-translate-y-1  hover:shadow-2xl p-4'
              }
            >
              <div className="">
                <div className="pb-2 text-sm md:text-sm xl:text-lg">
                  {row?.order_id?.prefix}
                  {row?.status === 'failed' ? (
                    <Tooltip title="ชำระเงินไม่สำเร็จ" placement="top">
                      <i className="far fa-times-circle pl-1 fa-lg text-red-600"></i>
                    </Tooltip>
                  ) : (
                    <>
                      {row?.payment_type === 'paymentSlip' ? (
                        <Tooltip title="โปรดตรวจสอบการชำระเงิน" placement="top">
                          <i className="far fa-check-circle pl-1 fa-lg text-yellow-500"></i>
                        </Tooltip>
                      ) : (
                        <Tooltip title="ชำระเงินเสร็จสิ้น" placement="top">
                          <i className="far fa-check-circle pl-1 fa-lg text-green-600"></i>
                        </Tooltip>
                      )}
                    </>
                  )}
                </div>

                <div>
                  {_.map(row?.order_id?.orders, (eachProduct, index2) => (
                    <div key={index2} className="p-1">
                      <h1>
                        <div className="text-lg xl:text-xl text-red-600">
                          {eachProduct?.quantity} x {eachProduct?.product?.name}{' '}
                          {eachProduct?.product?.option_type && (
                            <>[ {checkOption(eachProduct?.option)?.name} ]</>
                          )}
                        </div>
                        {eachProduct?.detailMore !== '' && (
                          <div className="text-red-400 text-xs xl:text-base pl-2">
                            * หมายเหตุ {eachProduct?.detailMore}
                          </div>
                        )}
                      </h1>
                    </div>
                  ))}
                </div>

                <div className="">
                  <div className="pb-1 pt-2 text-sm xl:text-base">
                    ราคารวม : {row?.amount} บาท
                  </div>
                  {row?.order_id?.date_pickup && (
                    <div className="pb-1 text-sm xl:text-base">
                      เวลารับสินค้า :{' '}
                      <a>
                        {`${dayjs(row?.order_id?.date_pickup).format(
                          'D MMM BBBB',
                        )}`}
                        {' , '}
                        {`${dayjs(row?.order_id?.date_pickup).format(
                          'HH:mm',
                        )}`}{' '}
                        น.
                      </a>
                    </div>
                  )}
                  <div className="pb-2 text-sm xl:text-base">
                    ลูกค้า : {row?.customer?.name}
                  </div>
                  <div className="grid grid-cols-2 gap-1">
                    <div className="w-full col-span-2">
                      {buttonDetailOrderModule(row)}
                    </div>
                    {row?.delivery ? (
                      <>
                        <div className="w-full">{buttonConfirmOrder(row)}</div>
                        <div className="w-full">{buttonCutStockOrder(row)}</div>
                        <div className="w-full">{buttonDeliveryOrder(row)}</div>
                        <div className="w-full">{buttonFinishOrder(row)}</div>
                      </>
                    ) : (
                      <>
                        <div className="w-full">
                          {buttonConfirmOrderNotDelivery(row)}
                        </div>
                        <div className="w-full">
                          {buttonCutStockOrderNotDelivery(row)}
                        </div>
                        <div className="w-full col-span-2">
                          {buttonFinishOrderNotDelivery(row)}
                        </div>
                      </>
                    )}

                    {row?.status_order === 'PRODUCT_TRANS' && (
                      <div className="flex gap-1 py-1 col-span-2">
                        <div className="w-1/2">
                          <Button
                            variant="contained"
                            startIcon={<EditLocationAltIcon />}
                            sx={{ backgroundColor: '#049dcc' }}
                            fullWidth
                            onClick={() => {
                              handleOpenTrack();
                              setSelectOrder(row);
                            }}
                          >
                            หมายเลขติดตาม
                          </Button>
                        </div>
                        <a
                          className="cursor-pointer hover:underline my-auto border-4 p-1 text-sm text-center text-red-600 w-1/2"
                          onClick={() => {
                            navigator.clipboard.writeText(row?.tracking_number);
                            alert('คัดลอกเรียบร้อย');
                          }}
                        >
                          {row?.tracking_number}
                        </a>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {row?.status_order === 'ORDER_WAIT' && (
                <div className="animate-bounce absolute top-2 right-2">
                  <div className="p-2 font-bold text-red-600">ใหม่</div>
                </div>
              )}
            </Card>
          ))}
        </div>
      ) : (
        <div className="text-2xl text-center pt-4">ยังไม่มีออเดอร์</div>
      )}
    </>
  );

  const renderTable = () => (
    <div className="my-2">
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <div className="font-bold">ลำดับที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">รายละเอียด</div>
                </TableCell>
                {/* <TableCell>
                  <div className="font-bold">คู่ค้า</div>
                </TableCell> */}
                <TableCell>
                  <div className="font-bold">รายการสินค้า</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">จำนวนเงิน</div>
                </TableCell>

                <TableCell>
                  <div className="font-bold">ดำเนินการ</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(newPaymentTransaction) ? (
                newPaymentTransaction.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    className={index % 2 === 0 ? 'bg-gray-100' : ''}
                  >
                    <TableCell component="th" scope="row">
                      <div className="flex">
                        <p className="my-auto">
                          {(page - 1) * size + index + 1}
                        </p>
                        {row?.status_order === 'ORDER_WAIT' && (
                          <div className="animate-bounce">
                            <div className="p-2 font-bold text-red-600">
                              ใหม่
                            </div>
                          </div>
                        )}
                      </div>
                    </TableCell>
                    <TableCell>
                      {row?.payment_type === 'quotation' && (
                        <div className="bg-yellow-400 text-center my-1">
                          ใบเสนอราคา
                        </div>
                      )}
                      <Link to={`/spm/online/${row?.id}`}>
                        <div className="font-bold flex cursor-pointer hover:underline">
                          <a className="my-auto">{`${row?.customer?.name}`}</a>
                          {row?.payment_type !== 'quotation' ? (
                            <>
                              {row?.status === 'failed' ? (
                                <Tooltip
                                  title="ชำระเงินไม่สำเร็จ"
                                  placement="top"
                                >
                                  <i className="far fa-times-circle pl-1 fa-lg text-red-600"></i>
                                </Tooltip>
                              ) : (
                                <>
                                  {row?.payment_type === 'paymentLater' ? (
                                    <>
                                      <Tooltip
                                        title="ชำระปลายทาง"
                                        placement="top"
                                      >
                                        <i className="far fa-check-circle pl-1 fa-lg text-red-500"></i>
                                      </Tooltip>
                                    </>
                                  ) : (
                                    <>
                                      {row?.payment_type === 'paymentSlip' ? (
                                        <Tooltip
                                          title="โปรดตรวจสอบการชำระเงิน"
                                          placement="top"
                                        >
                                          <i className="far fa-check-circle pl-1 fa-lg text-yellow-500"></i>
                                        </Tooltip>
                                      ) : (
                                        <Tooltip
                                          title="ชำระเงินเสร็จสิ้น"
                                          placement="top"
                                        >
                                          <i className="far fa-check-circle pl-1 fa-lg text-green-600"></i>
                                        </Tooltip>
                                      )}
                                    </>
                                  )}
                                </>
                              )}
                            </>
                          ) : (
                            ''
                          )}
                        </div>
                      </Link>
                      <div>
                        {`${dayjs(row?.createdAt).format('D MMM BBBB')}`}
                        {' , '}
                        {`${dayjs(row?.createdAt).format('HH:mm')}`}
                      </div>
                      {setting?.hotel && <div>ห้อง {row?.order_id?.room}</div>}
                    </TableCell>
                    <TableCell>
                      {!_.isEmpty(row?.order_id?.orders) && (
                        <>
                          {row?.order_id?.orders.map((eachOrder, _index) => (
                            <div key={_index} className="w-full py-1 flex">
                              <div className="font-bold flex">
                                <p className="pr-1 text-red-500">
                                  {' '}
                                  {eachOrder?.quantity}{' '}
                                </p>
                                {eachOrder?.product?.name}
                                {eachOrder?.product?.option_type && (
                                  <a className="pl-1">
                                    ( {checkOption(eachOrder?.option)?.name} )
                                  </a>
                                )}
                              </div>
                            </div>
                          ))}
                        </>
                      )}
                    </TableCell>
                    <TableCell>
                      {row?.payment_type === 'quotation' ? (
                        <>-</>
                      ) : (
                        <>
                          <p className="text-lg">
                            {(row?.amount || 0)
                              .toFixed(2)
                              .replace(/\d(?=(\d{3})+\.)/g, '$&,')}{' '}
                            บาท
                          </p>
                          <p>
                            ({' '}
                            {(row?.order_id?.delivery_price || 0)
                              .toFixed(2)
                              .replace(/\d(?=(\d{3})+\.)/g, '$&,')}{' '}
                            บาท )
                          </p>
                        </>
                      )}
                    </TableCell>

                    <TableCell>
                      <div className="w-full">
                        <div className="w-full text-center mb-1">
                          ({' '}
                          {checkStatusMessage(
                            row?.status_order,
                            row?.payment_type,
                          )}{' '}
                          )
                        </div>
                        <div className="flex w-full gap-1 mb-1">
                          {buttonConfirmOrder(row)}
                          {buttonQuotationSend(row)}
                          {buttonPOReceive(row)}
                        </div>
                        <div className="flex w-full gap-1">
                          {buttonCutStockOrder(row)}
                          {buttonDeliveryOrder(row)}
                          {buttonFinishOrder(row)}
                        </div>
                      </div>
                      {row?.status_order === 'PRODUCT_TRANS' && (
                        <div className="flex gap-1 pt-1">
                          <div className="w-1/2">
                            <Button
                              variant="contained"
                              startIcon={<EditLocationAltIcon />}
                              sx={{ backgroundColor: '#049dcc' }}
                              fullWidth
                              onClick={() => {
                                handleOpenTrack();
                                setSelectOrder(row);
                              }}
                            >
                              หมายเลขติดตาม
                            </Button>
                          </div>
                          <a
                            className="cursor-pointer hover:underline my-auto border-4 p-1 text-sm text-center text-red-600 w-1/2"
                            onClick={() => {
                              navigator.clipboard.writeText(
                                row?.tracking_number,
                              );
                              alert('คัดลอกเรียบร้อย');
                            }}
                          >
                            {row?.tracking_number}
                          </a>
                        </div>
                      )}

                      <div className="w-full pt-1">
                        <Button
                          variant="contained"
                          sx={{ backgroundColor: '#818282' }}
                          startIcon={<CancelIcon />}
                          fullWidth
                          onClick={() => {
                            handleOpenOrderCancel();
                            setSelectOrder(row);
                            // handleDeleteOrder(row, row?.source_id);
                          }}
                        >
                          ยกเลิกออเดอร์
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <div className="text-center">ไม่มีข้อมูล</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={page - 1}
          count={total || 1}
          rowsPerPage={size}
          onPageChange={handleChangePage}
        />
      </Paper>
    </div>
  );

  const value1 = '00000001';
  const value2 = '0889999999';

  if (paymentTransaction.isLoading || option.isLoading) {
    return <Loading />;
  }
  if (
    !paymentTransaction.isLoading &&
    paymentTransaction.isCompleted &&
    !option.isLoading &&
    option.isCompleted
  ) {
    return (
      <div>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={open}
          onClose={handleCloseAlert}
          message="มีออเดอร์ใหม่"
          key={vertical + horizontal}
        />
        {renderModalAddTrackingNumber()}
        {renderModalOrderCancel()}
        <div className="flex justify-between">
          <div>{renderTitle()}</div>
          <div className="mt-6">{renderChangeLayout()}</div>
        </div>
        {renderSearch()}
        {layout === 'computer' ? renderTable() : renderModules()}
      </div>
    );
  }
  return <Error message={paymentTransaction?.message} />;
};

OnlineManagement.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

OnlineManagement.defaultProps = {
  title: '',
  subtitle: '',
};

export default OnlineManagement;
