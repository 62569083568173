/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import _ from 'lodash';
// eslint-disable-next-line object-curly-newline
import {
  Button,
  Card,
  Switch,
  ToggleButtonGroup,
  ToggleButton,
  Box,
  Tab,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';

import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

import PropTypes from 'prop-types';

import SaveIcon from '@mui/icons-material/Save';
import ViewListIcon from '@mui/icons-material/ViewList';
import GridOnIcon from '@mui/icons-material/GridOn';

import { ProductCard, ProductCardList } from '../../components/PreviewCard';

import * as actions from '../../redux/actions';
import Loading from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';

const SettingLayout = ({ title, subtitle }) => {
  const dispatch = useDispatch();
  const settingLayout = useSelector((state) => state.settingLayout);
  console.log('settingLayout', settingLayout);

  const [layout, setLayout] = useState('grid');
  const [tabValue, setTabValue] = useState('1');

  const [selectDiscountLayout, setSelectDiscountLayout] = useState(
    settingLayout?.discount_pattern,
  );
  const [selectDiscountLayoutList, setSelectDiscountLayoutList] = useState(
    settingLayout?.discount_pattern_list,
  );

  const handleChangeDiscountLayout = (event) => {
    setSelectDiscountLayout(event.target.value);
  };

  const handleChangeDiscountLayoutList = (event) => {
    setSelectDiscountLayoutList(event.target.value);
  };

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  const [state, setState] = useState({
    typeCodeCheck: settingLayout?.product_card?.type_code,
    tagCheck: settingLayout?.product_card?.tag,
    typeCheck: settingLayout?.product_card?.type,
    brandCheck: settingLayout?.product_card?.brand,
    statusCheck: settingLayout?.product_card?.status,
    scoreCheck: settingLayout?.product_card?.score,
    priceCheck: settingLayout?.product_card?.price,
    nameCheck: settingLayout?.product_card?.name,
    inventoryCheck: settingLayout?.product_card?.inventory,
    imageCheck: settingLayout?.product_card?.image,
    detailCheck: settingLayout?.product_card?.detail,
    addCardCheck: settingLayout?.product_card?.add_card_box,
  });

  const {
    typeCodeCheck,
    tagCheck,
    typeCheck,
    brandCheck,
    statusCheck,
    scoreCheck,
    priceCheck,
    nameCheck,
    inventoryCheck,
    imageCheck,
    detailCheck,
    addCardCheck,
  } = state;

  const [stateList, setStateList] = useState({
    typeCodeCheckList: settingLayout?.product_card_list?.type_code,
    tagCheckList: settingLayout?.product_card_list?.tag,
    typeCheckList: settingLayout?.product_card_list?.type,
    brandCheckList: settingLayout?.product_card_list?.brand,
    statusCheckList: settingLayout?.product_card_list?.status,
    scoreCheckList: settingLayout?.product_card_list?.score,
    priceCheckList: settingLayout?.product_card_list?.price,
    nameCheckList: settingLayout?.product_card_list?.name,
    inventoryCheckList: settingLayout?.product_card_list?.inventory,
    imageCheckList: settingLayout?.product_card_list?.image,
    detailCheckList: settingLayout?.product_card_list?.detail,
    addCardCheckList: settingLayout?.product_card_list?.add_card_box,
  });

  const {
    typeCodeCheckList,
    tagCheckList,
    typeCheckList,
    brandCheckList,
    statusCheckList,
    scoreCheckList,
    priceCheckList,
    nameCheckList,
    inventoryCheckList,
    imageCheckList,
    detailCheckList,
    addCardCheckList,
  } = stateList;

  useEffect(() => {
    dispatch(actions.settingLayoutAll({}));
    return () => {};
  }, []);

  console.log('stateList', stateList);

  const handleLayout = (event, newLayout) => {
    if (newLayout !== null) {
      console.log('newLayout', newLayout);
      setLayout(newLayout);
    }
  };

  useEffect(() => {
    setState({
      typeCodeCheck: settingLayout?.product_card?.type_code,
      tagCheck: settingLayout?.product_card?.tag,
      typeCheck: settingLayout?.product_card?.type,
      brandCheck: settingLayout?.product_card?.brand,
      statusCheck: settingLayout?.product_card?.status,
      scoreCheck: settingLayout?.product_card?.score,
      priceCheck: settingLayout?.product_card?.price,
      nameCheck: settingLayout?.product_card?.name,
      inventoryCheck: settingLayout?.product_card?.inventory,
      imageCheck: settingLayout?.product_card?.image,
      detailCheck: settingLayout?.product_card?.detail,
      addCardCheck: settingLayout?.product_card?.add_card_box,
    });

    setStateList({
      typeCodeCheckList: settingLayout?.product_card_list?.type_code,
      tagCheckList: settingLayout?.product_card_list?.tag,
      typeCheckList: settingLayout?.product_card_list?.type,
      brandCheckList: settingLayout?.product_card_list?.brand,
      statusCheckList: settingLayout?.product_card_list?.status,
      scoreCheckList: settingLayout?.product_card_list?.score,
      priceCheckList: settingLayout?.product_card_list?.price,
      nameCheckList: settingLayout?.product_card_list?.name,
      inventoryCheckList: settingLayout?.product_card_list?.inventory,
      imageCheckList: settingLayout?.product_card_list?.image,
      detailCheckList: settingLayout?.product_card_list?.detail,
      addCardCheckList: settingLayout?.product_card_list?.add_card_box,
    });

    setSelectDiscountLayout(settingLayout?.discount_pattern);
    setSelectDiscountLayoutList(settingLayout?.discount_pattern_list);
    return () => {};
  }, [settingLayout, layout, tabValue]);

  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };

  const handleChangeList = (event) => {
    console.log('handleChangeList use');
    setStateList({
      ...stateList,
      [event.target.name]: event.target.checked,
    });
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const onSubmitSettingLayout = async () => {
    const payload = {
      ...settingLayout,
      product_card: {
        add_card_box: addCardCheck,
        detail: detailCheck,
        image: imageCheck,
        type: typeCheck,
        brand: brandCheck,
        inventory: inventoryCheck,
        name: nameCheck,
        price: priceCheck,
        price_color: '',
        score: scoreCheck,
        status: statusCheck,
        tag: tagCheck,
        type_code: typeCodeCheck,
      },
    };
    console.log('payload :', payload);
    try {
      await dispatch(actions.settingLayoutPut(settingLayout?.id, payload));
      alert('สำเร็จ');
      await dispatch(actions.settingLayoutAll({}));
    } catch (error) {
      console.error(error);
    }
  };

  const onSubmitSettingLayoutList = async () => {
    const payload = {
      ...settingLayout,
      product_card_list: {
        add_card_box: addCardCheckList,
        detail: detailCheckList,
        image: imageCheckList,
        type: typeCheckList,
        brand: brandCheckList,
        inventory: inventoryCheckList,
        name: nameCheckList,
        price: priceCheckList,
        price_color: '',
        score: scoreCheckList,
        status: statusCheckList,
        tag: tagCheckList,
        type_code: typeCodeCheckList,
      },
    };
    console.log('payload :', payload);
    try {
      await dispatch(actions.settingLayoutPut(settingLayout?.id, payload));
      alert('สำเร็จ');
      await dispatch(actions.settingLayoutAll({}));
    } catch (error) {
      console.error(error);
    }
  };

  const onSubmitDiscountLayout = async () => {
    const payload = {
      ...settingLayout,
      discount_pattern: selectDiscountLayout,
    };
    console.log('payload :', payload);
    try {
      await dispatch(actions.settingLayoutPut(settingLayout?.id, payload));
      alert('สำเร็จ');
      await dispatch(actions.settingLayoutAll({}));
    } catch (error) {
      console.error(error);
    }
  };

  const onSubmitDiscountLayoutList = async () => {
    const payload = {
      ...settingLayout,
      discount_pattern_list: selectDiscountLayoutList,
    };
    console.log('payload :', payload);
    try {
      await dispatch(actions.settingLayoutPut(settingLayout?.id, payload));
      alert('สำเร็จ');
      await dispatch(actions.settingLayoutAll({}));
    } catch (error) {
      console.error(error);
    }
  };

  if (settingLayout.isLoading) {
    return <Loading />;
  }

  if (!settingLayout.isLoading && settingLayout.isCompleted) {
    return (
      <div>
        <div className="flex justify-between">
          <div>{renderTitle()}</div>
        </div>

        <Card className="p-6">
          <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={tabValue}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList
                  onChange={handleChangeTab}
                  aria-label="lab API tabs example"
                >
                  <Tab label="การแสดงสินค้า" value="1" />
                  <Tab label="การแสดงส่วนลด" value="2" />
                </TabList>
              </Box>
              <TabPanel value="1">
                <div className="flex justify-between w-full mb-2">
                  <div className="text-xl">
                    {layout === 'grid' ? 'รูปแบบตาราง' : 'รูปแบบรายการ'}
                  </div>
                  <ToggleButtonGroup
                    value={layout}
                    exclusive
                    size="small"
                    onChange={handleLayout}
                  >
                    <ToggleButton value="list" aria-label="left aligned">
                      <ViewListIcon />
                    </ToggleButton>
                    <ToggleButton value="grid" aria-label="right aligned">
                      <GridOnIcon />
                    </ToggleButton>
                  </ToggleButtonGroup>
                </div>
                {layout === 'grid' ? (
                  <div className=" block lg:flex">
                    <div className="w-full lg:w-1/2">
                      <div className="w-full pl-2">
                        <div className="flex mb-2">
                          <Switch
                            inputProps={{ 'aria-label': 'controlled' }}
                            checked={typeCodeCheck}
                            onChange={handleChange}
                            name="typeCodeCheck"
                          />
                          <p className="my-auto">แสดงรหัสสินค้า</p>
                        </div>
                        <div className="flex mb-2">
                          <Switch
                            inputProps={{ 'aria-label': 'controlled' }}
                            checked={tagCheck}
                            onChange={handleChange}
                            name="tagCheck"
                            disabled
                          />
                          <p className="my-auto">แสดงแท็กสินค้า</p>
                        </div>
                        <div className="flex mb-2">
                          <Switch
                            inputProps={{ 'aria-label': 'controlled' }}
                            checked={typeCheck}
                            onChange={handleChange}
                            name="typeCheck"
                          />
                          <p className="my-auto">แสดงหมวดหมู่สินค้า</p>
                        </div>
                        <div className="flex mb-2">
                          <Switch
                            inputProps={{ 'aria-label': 'controlled' }}
                            checked={brandCheck}
                            onChange={handleChange}
                            name="brandCheck"
                          />
                          <p className="my-auto">แสดงแบรนด์</p>
                        </div>
                        <div className="flex mb-2">
                          <Switch
                            inputProps={{ 'aria-label': 'controlled' }}
                            checked={statusCheck}
                            onChange={handleChange}
                            name="statusCheck"
                            disabled
                          />
                          <p className="my-auto">
                            แสดงสถานะสินค้า เช่น สินค้าใหม่
                          </p>
                        </div>
                        <div className="flex mb-2">
                          <Switch
                            inputProps={{ 'aria-label': 'controlled' }}
                            checked={scoreCheck}
                            onChange={handleChange}
                            name="scoreCheck"
                          />
                          <p className="my-auto">แสดงคะแนนสินค้า</p>
                        </div>
                        <div className="flex mb-2">
                          <Switch
                            inputProps={{ 'aria-label': 'controlled' }}
                            checked={priceCheck}
                            onChange={handleChange}
                            name="priceCheck"
                          />
                          <p className="my-auto">แสดงราคาสินค้า</p>
                        </div>
                        <div className="flex mb-2">
                          <Switch
                            inputProps={{ 'aria-label': 'controlled' }}
                            checked={nameCheck}
                            onChange={handleChange}
                            name="nameCheck"
                          />
                          <p className="my-auto">แสดงชื่อสินค้า</p>
                        </div>
                        <div className="flex mb-2">
                          <Switch
                            inputProps={{ 'aria-label': 'controlled' }}
                            checked={inventoryCheck}
                            onChange={handleChange}
                            name="inventoryCheck"
                          />
                          <p className="my-auto">แสดงจำนวนคงเหลือสินค้า</p>
                        </div>
                        <div className="flex mb-2">
                          <Switch
                            inputProps={{ 'aria-label': 'controlled' }}
                            checked={imageCheck}
                            onChange={handleChange}
                            name="imageCheck"
                          />
                          <p className="my-auto">แสดงรูปภาพสินค้า</p>
                        </div>
                        <div className="flex mb-2">
                          <Switch
                            inputProps={{ 'aria-label': 'controlled' }}
                            checked={detailCheck}
                            onChange={handleChange}
                            name="detailCheck"
                          />
                          <p className="my-auto">แสดงรายละเอียดสินค้า</p>
                        </div>
                        <div className="flex mb-2">
                          <Switch
                            inputProps={{ 'aria-label': 'controlled' }}
                            checked={addCardCheck}
                            onChange={handleChange}
                            name="addCardCheck"
                            disabled
                          />
                          <p className="my-auto">
                            แสดงปุ่มเพิ่มสินค้าลงตระกร้า
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-row justify-center gap-1 py-4">
                        <Button
                          variant="contained"
                          startIcon={<SaveIcon />}
                          onClick={() => onSubmitSettingLayout()}
                        >
                          บันทึก
                        </Button>
                      </div>
                    </div>
                    <div className="w-full lg:w-1/2">
                      <div className="my-2 text-xl text-center">
                        ตัวอย่าง (Preview)
                      </div>
                      <div className="flex justify-center">
                        <ProductCard
                          imageCheck={imageCheck}
                          nameCheck={nameCheck}
                          typeCheck={typeCheck}
                          brandCheck={brandCheck}
                          typeCodeCheck={typeCodeCheck}
                          tagCheck={tagCheck}
                          detailCheck={detailCheck}
                          priceCheck={priceCheck}
                          scoreCheck={scoreCheck}
                          statusCheck={statusCheck}
                          inventoryCheck={inventoryCheck}
                          addCardCheck={addCardCheck}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className=" block lg:flex">
                    <div className="w-full lg:w-1/2">
                      <div className="w-full pl-2">
                        <div className="flex mb-2">
                          <Switch
                            inputProps={{ 'aria-label': 'controlled' }}
                            checked={typeCodeCheckList}
                            onChange={handleChangeList}
                            name="typeCodeCheckList"
                          />
                          <p className="my-auto">แสดงรหัสสินค้า</p>
                        </div>
                        <div className="flex mb-2">
                          <Switch
                            inputProps={{ 'aria-label': 'controlled' }}
                            checked={tagCheckList}
                            onChange={handleChangeList}
                            name="tagCheckList"
                            disabled
                          />
                          <p className="my-auto">แสดงแท็กสินค้า</p>
                        </div>
                        <div className="flex mb-2">
                          <Switch
                            inputProps={{ 'aria-label': 'controlled' }}
                            checked={typeCheckList}
                            onChange={handleChangeList}
                            name="typeCheckList"
                          />
                          <p className="my-auto">แสดงหมวดหมู่สินค้า</p>
                        </div>
                        <div className="flex mb-2">
                          <Switch
                            inputProps={{ 'aria-label': 'controlled' }}
                            checked={brandCheckList}
                            onChange={handleChangeList}
                            name="brandCheckList"
                          />
                          <p className="my-auto">แสดงแบรนด์</p>
                        </div>
                        <div className="flex mb-2">
                          <Switch
                            inputProps={{ 'aria-label': 'controlled' }}
                            checked={statusCheckList}
                            onChange={handleChangeList}
                            name="statusCheckList"
                            disabled
                          />
                          <p className="my-auto">
                            แสดงสถานะสินค้า เช่น สินค้าใหม่
                          </p>
                        </div>
                        <div className="flex mb-2">
                          <Switch
                            inputProps={{ 'aria-label': 'controlled' }}
                            checked={scoreCheckList}
                            onChange={handleChangeList}
                            name="scoreCheckList"
                          />
                          <p className="my-auto">แสดงคะแนนสินค้า</p>
                        </div>
                        <div className="flex mb-2">
                          <Switch
                            inputProps={{ 'aria-label': 'controlled' }}
                            checked={priceCheckList}
                            onChange={handleChangeList}
                            name="priceCheckList"
                          />
                          <p className="my-auto">แสดงราคาสินค้า</p>
                        </div>
                        <div className="flex mb-2">
                          <Switch
                            inputProps={{ 'aria-label': 'controlled' }}
                            checked={nameCheckList}
                            onChange={handleChangeList}
                            name="nameCheckList"
                          />
                          <p className="my-auto">แสดงชื่อสินค้า</p>
                        </div>
                        <div className="flex mb-2">
                          <Switch
                            inputProps={{ 'aria-label': 'controlled' }}
                            checked={inventoryCheckList}
                            onChange={handleChangeList}
                            name="inventoryCheckList"
                          />
                          <p className="my-auto">แสดงจำนวนคงเหลือสินค้า</p>
                        </div>
                        <div className="flex mb-2">
                          <Switch
                            inputProps={{ 'aria-label': 'controlled' }}
                            checked={imageCheckList}
                            onChange={handleChangeList}
                            name="imageCheckList"
                          />
                          <p className="my-auto">แสดงรูปภาพสินค้า</p>
                        </div>
                        <div className="flex mb-2">
                          <Switch
                            inputProps={{ 'aria-label': 'controlled' }}
                            checked={detailCheckList}
                            onChange={handleChangeList}
                            name="detailCheckList"
                          />
                          <p className="my-auto">แสดงรายละเอียดสินค้า</p>
                        </div>
                        <div className="flex mb-2">
                          <Switch
                            inputProps={{ 'aria-label': 'controlled' }}
                            checked={addCardCheckList}
                            onChange={handleChangeList}
                            name="addCardCheckList"
                            disabled
                          />
                          <p className="my-auto">
                            แสดงปุ่มเพิ่มสินค้าลงตระกร้า
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-row justify-center gap-1 py-4">
                        <Button
                          variant="contained"
                          startIcon={<SaveIcon />}
                          onClick={() => onSubmitSettingLayoutList()}
                        >
                          บันทึก
                        </Button>
                      </div>
                    </div>
                    <div className="w-full lg:w-1/2">
                      <div className="my-2 text-xl text-center">
                        ตัวอย่าง (Preview)
                      </div>
                      <div className="flex justify-center">
                        <ProductCardList
                          imageCheck={imageCheckList}
                          nameCheck={nameCheckList}
                          typeCodeCheck={typeCodeCheckList}
                          tagCheck={tagCheckList}
                          detailCheck={detailCheckList}
                          priceCheck={priceCheckList}
                          scoreCheck={scoreCheckList}
                          statusCheck={statusCheckList}
                          inventoryCheck={inventoryCheckList}
                          addCardCheck={addCardCheckList}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </TabPanel>
              <TabPanel value="2">
                <div className="flex justify-between w-full mb-2">
                  <div className="text-xl">
                    {layout === 'grid' ? 'รูปแบบตาราง' : 'รูปแบบรายการ'}
                  </div>
                  <ToggleButtonGroup
                    value={layout}
                    exclusive
                    size="small"
                    onChange={handleLayout}
                  >
                    <ToggleButton value="list" aria-label="left aligned">
                      <ViewListIcon />
                    </ToggleButton>
                    <ToggleButton value="grid" aria-label="right aligned">
                      <GridOnIcon />
                    </ToggleButton>
                  </ToggleButtonGroup>
                </div>
                {layout === 'grid' ? (
                  <div className=" block lg:flex">
                    <div className="w-full lg:w-1/2">
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={selectDiscountLayout}
                        onChange={handleChangeDiscountLayout}
                        defaultValue={selectDiscountLayout}
                      >
                        <FormControlLabel
                          value="type01"
                          control={<Radio />}
                          label={
                            <div>
                              <div>รูปแบบที่ 1</div>
                              <div>
                                <small>
                                  ( แสดงราคาก่อนหักส่วนลด + แสดงเปอร์เซ็นต์ที่ลด
                                  )
                                </small>
                              </div>
                            </div>
                          }
                        />
                        <FormControlLabel
                          value="type02"
                          control={<Radio />}
                          label={
                            <div>
                              <div>รูปแบบที่ 2</div>
                              <div>
                                <small>( แสดงเฉพาะราคาหลังหักส่วนลด )</small>
                              </div>
                            </div>
                          }
                        />
                      </RadioGroup>
                      <div className="flex flex-row justify-center gap-1 py-4">
                        <Button
                          variant="contained"
                          startIcon={<SaveIcon />}
                          onClick={() => onSubmitDiscountLayout()}
                        >
                          บันทึก
                        </Button>
                      </div>
                    </div>
                    <div className="w-full lg:w-1/2">
                      <div className="my-2 text-xl text-center">
                        ตัวอย่าง (Preview)
                      </div>
                      <div className="flex justify-center">
                        <ProductCard
                          imageCheck={imageCheckList}
                          nameCheck={nameCheckList}
                          typeCodeCheck={typeCodeCheckList}
                          tagCheck={tagCheckList}
                          detailCheck={detailCheckList}
                          priceCheck={priceCheckList}
                          scoreCheck={scoreCheckList}
                          statusCheck={statusCheckList}
                          inventoryCheck={inventoryCheckList}
                          addCardCheck={addCardCheckList}
                          selectDiscountLayout={selectDiscountLayout}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className=" block lg:flex">
                    <div className="w-full lg:w-1/2">
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={selectDiscountLayoutList}
                        onChange={handleChangeDiscountLayoutList}
                        defaultValue={selectDiscountLayoutList}
                      >
                        <FormControlLabel
                          value="type01"
                          control={<Radio />}
                          label={
                            <div>
                              <div>รูปแบบที่ 1</div>
                              <div>
                                <small>
                                  ( แสดงราคาก่อนหักส่วนลด + แสดงเปอร์เซ็นต์ที่ลด
                                  )
                                </small>
                              </div>
                            </div>
                          }
                        />
                        <FormControlLabel
                          value="type02"
                          control={<Radio />}
                          label={
                            <div>
                              <div>รูปแบบที่ 2</div>
                              <div>
                                <small>( แสดงเฉพาะราคาหลังหักส่วนลด )</small>
                              </div>
                            </div>
                          }
                        />
                      </RadioGroup>
                      <div className="flex flex-row justify-center gap-1 py-4">
                        <Button
                          variant="contained"
                          startIcon={<SaveIcon />}
                          onClick={() => onSubmitDiscountLayoutList()}
                        >
                          บันทึก
                        </Button>
                      </div>
                    </div>
                    <div className="w-full lg:w-1/2">
                      <div className="my-2 text-xl text-center">
                        ตัวอย่าง (Preview)
                      </div>
                      <div className="flex justify-center">
                        <ProductCardList
                          imageCheck={imageCheckList}
                          nameCheck={nameCheckList}
                          typeCodeCheck={typeCodeCheckList}
                          tagCheck={tagCheckList}
                          detailCheck={detailCheckList}
                          priceCheck={priceCheckList}
                          scoreCheck={scoreCheckList}
                          statusCheck={statusCheckList}
                          inventoryCheck={inventoryCheckList}
                          addCardCheck={addCardCheckList}
                          selectDiscountLayoutList={selectDiscountLayoutList}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </TabPanel>
            </TabContext>
          </Box>
        </Card>
      </div>
    );
  }
  return <Error message={settingLayout?.message} />;
};

SettingLayout.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

SettingLayout.defaultProps = {
  title: '',
  subtitle: '',
};

export default SettingLayout;
