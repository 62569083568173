import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Button, Card } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';

import { useForm, Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import * as actions from '../../redux/actions';
import { MaterialStockForm } from '../../components/Forms';
import Loading from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';
import { BackButton } from '../../components/Button';

const defaultValues = {
  material_transaction_type: '',
  place: '',
  customer: '',
  quantity: '',
  price: '',
  material_stock_lot: '',
  material: '',
};

const AddMaterialStockLot = ({ title, subtitle }) => {
  const dispatch = useDispatch();
  const material = useSelector((state) => state.material);
  const materialType = useSelector((state) => state.materialType);
  const place = useSelector((state) => state.place);
  const materialTransactionType = useSelector(
    (state) => state.materialTransactionType,
  );
  const materialStockLot = useSelector((state) => state.materialStockLot);
  const customer = useSelector((state) => state.customer);
  const department = useSelector((state) => state.department);
  const me = useSelector((state) => state.user);

  const history = useHistory();
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();
  const [name] = useState('');
  const [page] = useState(1);
  const [size] = useState(5);
  const [type, setType] = useState(null);
  const [selectMaterial, setSelectMaterial] = useState(null);
  const [filterMaterial, setFilterMaterial] = useState(null);
  const [filterPlace, setFilterPlace] = useState(null);

  useEffect(() => {
    dispatch(actions.materialStockLotAll(''));
    dispatch(actions.materialAll(''));
    dispatch(actions.materialTypeAll(''));
    dispatch(actions.materialTransactionTypeAll(''));
    dispatch(actions.placeAll(''));
    dispatch(actions.customerAll(''));
    dispatch(actions.departmentAll(''));
    dispatch(actions.userGet(''));
    return () => {};
  }, [name, page, size]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const onSubmit = async (data, event) => {
    try {
      console.log(data);
      event.preventDefault();
      // await dispatch(actions.materialStockLotCreate(data));
      reset(defaultValues);
      alert('สำเร็จ');

      // await dispatch(actions.materialStockLotAll({ name, page, size }));
      history.goBack();
    } catch (error) {
      console.error(error);
    }
  };

  const renderForm = () => (
    <Card className="p-6">
      <div className="py-2">เพิ่มข้อมูล</div>
      <div className="py-2">
        <form onSubmit={handleSubmit(onSubmit)}>
          <MaterialStockForm
            Controller={Controller}
            control={control}
            errors={errors}
            material={material.rows}
            materialType={materialType.rows}
            place={place.rows}
            materialTransactionType={materialTransactionType.rows}
            materialStockLot={materialStockLot.rows}
            customer={customer.rows}
            department={department.rows}
            me={me.rows}
            type={type}
            setType={setType}
            selectMaterial={selectMaterial}
            setSelectMaterial={setSelectMaterial}
            filterMaterial={filterMaterial}
            setFilterMaterial={setFilterMaterial}
            filterPlace={filterPlace}
            setFilterPlace={setFilterPlace}
          />
          <div className="flex flex-row justify-end gap-1 py-4">
            <Button variant="contained" type="submit" startIcon={<SaveIcon />}>
              บันทึก
            </Button>
          </div>
        </form>
      </div>
    </Card>
  );

  if (material.isLoading) {
    return <Loading />;
  }
  if (!material.isLoading && material.isCompleted) {
    return (
      <div>
        <div className="flex justify-between">
          <div>{renderTitle()}</div>
        </div>
        <div className="flex flex-row justify-start pb-4">
          <div>
            <BackButton />
          </div>
          <div className="text-lg font-semibold ml-6">
            เพิ่ม ลดสต๊อกวัตถุดิบ
          </div>
        </div>
        {renderForm()}
      </div>
    );
  }
  return <Error message={material?.message} />;
};

AddMaterialStockLot.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

AddMaterialStockLot.defaultProps = {
  title: '',
  subtitle: '',
};

export default AddMaterialStockLot;
