export const MATERIAL_STOCK_STATUS = {
  IN_STOCK: {
    status_code: 'IN_STOCK',
    description: 'มีสินค้า',
  },
  OUT_OF_STOCK: {
    status_code: 'OUT_OF_STOCK',
    description: 'สินค้าหมด',
  },
};
export default MATERIAL_STOCK_STATUS;
