/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import {
  useParams,
  useHistory,
} from 'react-router-dom/cjs/react-router-dom.min';
import { Button, Card, Fade, Modal } from '@mui/material';
import CourseForm from '../../components/Forms/BMS/CourseForm';
import * as actions from '../../redux/actions';
import { ViewTitle } from '../../components/ViewTitle';
import { BackButton } from 'components/Button';

export default function EditCourse({ title, subtitle }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const course = useSelector((state) => state.course);
  const { handleSubmit, control, setValue } = useForm({
    defaultValues: {
      name: course?.name,
      price: course?.price,
      detail: course?.detail,
    },
  });

  useEffect(() => {
    dispatch(actions.courseGet(params.id));
    return () => {};
  }, []);
  useEffect(() => {
    setValue('name', course.name);
    setValue('price', course.price);
    setValue('detail', course.detail);
    return () => {};
  }, [course]);

  const onSubmit = async (data) => {
    const confirm = window.confirm('ยืนยันบันทึกข้อมูล');
    if (confirm) {
      await dispatch(actions.coursePut(params?.id, data));
      await dispatch(actions.courseAll({}));
      history.goBack();
    }
  };

  const renderModal = () => (
    <Card>
      <div className="w-full overflow-y-auto max-h-screen">
        <div className="py-2">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="">
              <CourseForm
                Controller={Controller}
                control={control}
                course={course}
              />
              <Button variant="contained" type="submit">
                บันทึก
              </Button>
            </div>
          </form>
        </div>
      </div>
    </Card>
  );

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  return (
    <div>
      {renderTitle()}
      <BackButton />
      <div className="text-lg font-semibold py-4">{renderModal()}</div>
    </div>
  );
}
EditCourse.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

EditCourse.defaultProps = {
  title: '',
  subtitle: '',
};
