/* eslint-disable no-nested-ternary */
import pdfMake from 'addthaifont-pdfmake';
import 'addthaifont-pdfmake/build/vfs_fonts';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import dayjs from 'dayjs';

import THBText from 'thai-baht-text';

import _ from 'lodash';
import { ReportHeader } from './common';

pdfMake.fonts = {
  Sarabun: {
    normal: 'Sarabun-Light.ttf',
    bold: 'Sarabun-Regular.ttf',
    italics: 'Sarabun-LightItalic.ttf',
    bolditalics: 'Sarabun-Italic.ttf',
  },
};
dayjs.locale('th');
dayjs.extend(buddhistEra);
dayjs.extend(LocalizedFormat);
const genBody = (orders) => {
  const NewProductList = _.map(orders, (order, index) => [
    { text: index + 1, alignment: 'center' },
    {
      text: [
        {
          text: `${order?.product?.name}\nรหัส : ${
            order?.product?.type_code
          }\nแบรนด์ : ${order?.product?.brand?.name || '-'}`,
        },
        {
          text: order?.option && `( ${order?.option?.name || ''} )`,
          fontSize: 8,
        },
      ],
      alignment: 'left',
    },
    {
      text: `${order?.amount || order?.quantity} ${order?.product?.unit}`,
      alignment: 'center',
    },
    {
      text: `${(order?.price - (order?.discount || 0))
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,
      alignment: 'right',
    },
    // {
    //   text: `${(order?.discount_percent || 0)
    //     .toFixed(2)
    //     .replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,
    //   alignment: 'right',
    // },
    {
      text: `${(
        order?.price * (order?.amount || order?.quantity) -
        (order?.discount || 0) * (order?.amount || order?.quantity)
      )
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,
      alignment: 'right',
    },
  ]);

  return NewProductList;
};

const QuotationPrint = async (data, setting, productList) => {
  console.log('productList in pdf', productList);
  console.log('data in pdf', data?.remark);
  const sumPrice = _.sumBy(
    productList,
    (each) => each?.price * (each?.amount || each?.quantity),
  );
  const sumDiscount = _.sumBy(
    productList,
    (each) => (each?.discount || 0) * (each?.amount || each?.quantity),
  );
  const sumAllPrice =
    sumPrice - sumDiscount + (data?.order_id?.delivery_price || 0);
  const priceEnd = sumAllPrice;
  const taxTotal = (priceEnd * 7) / 100;
  const priceEndWithTax = priceEnd + taxTotal;
  const genItemBody = genBody(productList);

  const documentRef = {
    pageSize: 'A4',
    pageOrientation: 'portait',
    pageMargins: [20, 270, 20, 200],
    defaultStyle: {
      font: 'Sarabun',
      fontSize: '10',
    },
    header: [
      ReportHeader(setting),
      {
        alignment: 'center',
        margin: [20, -2, 20, 4],
        table: {
          alignment: 'center',

          widths: ['49.9%', '12.8%', '13%', '*'],
          body: [
            [
              {
                fillColor: '#4297ff',
                rowSpan: 2,
                text: 'ใบเสนอราคา/Quotation',
                margin: 10,
                style: 'header1',
                border: [false, false, false, false],
              },
              { text: '', border: [false, false, false, false] },
              { text: 'เลขที่' },
              {
                text: `${data.bill_no || data?.order_id?.prefix}`,
                alignment: 'left',
              },
            ],
            [
              '',
              {
                text: '',
                border: [false, false, false, false],
              },
              { text: 'วันที่', margin: [0, 4, 0, 0] },
              {
                text: `${dayjs(data.date || data.createdAt).format(
                  'DD MMMM BBBB',
                )}`,
                alignment: 'left',

                margin: [0, 4, 0, 0],
              },
            ],
          ],
        },
      },
      {
        margin: [20, -2, 20, 0],
        table: {
          alignment: '',

          widths: ['15%', '34%', '13%', '13%', '*'],
          body: [
            [
              {
                text: 'ชื่อลูกค้า',
                style: 'body',
                border: [true, true, false, false],
              },
              {
                text: `${data?.customer?.name || '-'}`,
                border: [false, true, true, false],
                style: 'body',
              },
              { text: '', border: [false, false, false, false] },
              {
                text: 'การชำระเงิน',
                style: 'body',
                border: [true, true, false, false],
              },
              {
                text: `${
                  data?.payment_type === 'transfer'
                    ? 'โอน'
                    : data?.payment_type === 'creditCard'
                    ? 'บัตรเครดิต'
                    : data?.payment_type === 'paymentSlip'
                    ? 'โอน'
                    : 'เงินสด'
                }`,
                border: [false, true, true, false],
              },
            ],
            [
              {
                text: 'เลขที่ผู้เสียภาษี ',
                style: 'body',
                border: [true, false, false, false],
              },
              {
                text: `${data?.customer?.taxes || '-'}`,
                style: 'body',
                border: [false, false, true, false],
              }, // texid
              { text: '', border: [false, false, false, false] },
              { text: '', border: [true, false, false, false] },
              { text: '', border: [false, false, true, false] },
            ],
            [
              {
                text: 'ชื่อผู้ติดต่อ',
                style: 'body',
                border: [true, false, false, false],
              },
              {
                text: `${'-'}`,
                style: 'body',
                border: [false, false, true, false],
              },
              { text: '', border: [false, false, false, false] },
              {
                text: '',
                style: 'body',
                border: [true, false, false, false],
              },
              {
                text: '',
                style: 'body',
                border: [false, false, true, false],
              },
            ],
            [
              {
                text: 'ที่อยู่',
                style: 'body',
                border: [true, false, false, false],
              },
              {
                text: `${data?.customer?.address || '-'}`,
                style: 'body',
                border: [false, false, true, false],
              },
              { text: '', border: [false, false, false, false] },
              {
                text: '',
                style: 'body',
                border: [true, false, false, false],
              },
              {
                text: '',
                style: 'body',
                border: [false, false, true, false],
              },
            ],
            [
              {
                text: 'โทร',
                style: 'body',
                border: [true, false, false, true],
              },
              {
                text: `${data?.customer?.phone_number || '-'}`,
                style: 'body',
                border: [false, false, true, true],
              },
              { text: '', border: [false, false, false, false] },
              { text: '', border: [true, false, false, true] },
              { text: '', border: [false, false, true, true] },
            ],
            // ...order
          ],
        },
      },
    ],
    content: [
      {
        margin: [0, 12],
        table: {
          alignment: '',
          headerRows: 1,
          widths: ['5%', '55.34%', '13%', '13.33%', '13.33%'],
          body: [
            [
              {
                text: 'ลำดับ',
                fillColor: '#d2d4d2',
                alignment: 'center',
                border: [true, true, true, true],
              },
              {
                text: 'รายการสินค้า',
                fillColor: '#d2d4d2',
                alignment: 'center',
                border: [true, true, true, true],
              },
              {
                text: 'จำนวน',
                fillColor: '#d2d4d2',
                alignment: 'center',
                border: [true, true, true, true],
              },
              {
                text: 'ราคาต่อหน่วย',
                fillColor: '#d2d4d2',
                alignment: 'center',
                border: [true, true, true, true],
              },
              // {
              //   text: 'ส่วนลด (%)',
              //   fillColor: '#d2d4d2',
              //   alignment: 'center',
              //   border: [true, true, true, true],
              // },
              {
                text: 'จำนวนเงิน',
                fillColor: '#d2d4d2',
                alignment: 'center',
                border: [true, true, true, true],
              },
            ],
            ...genItemBody,
            data?.order_id?.delivery_price !== 0 &&
            data?.order_id?.delivery_price
              ? [
                  {
                    text: _.size(productList) + 1,
                    alignment: 'center',
                  },
                  {
                    text: 'ค่าจัดส่ง/ค่าดำเนินการ ',
                  },
                  {
                    text: '1',
                    alignment: 'center',
                  },
                  {
                    text: '-',
                    alignment: 'right',
                  },
                  // {
                  //   text: '-',
                  //   alignment: 'right',
                  // },
                  {
                    text: `${(data?.order_id?.delivery_price || 0)
                      .toFixed(2)
                      .replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,
                    alignment: 'right',
                  },
                ]
              : [
                  {
                    text: '',
                    border: [false, false, false, false],
                  },
                  {
                    text: ' ',
                    border: [false, false, false, false],
                  },
                  {
                    text: '',
                    border: [false, false, false, false],
                  },
                  {
                    text: '',
                    border: [false, false, false, false],
                  },
                  // {
                  //   text: '',
                  //   alignment: 'right',
                  //   border: [false, false, false, false],
                  // },
                  {
                    text: '',
                    border: [false, false, false, false],
                    alignment: 'right',
                  },
                ],
          ],
        },
      },

      {},
      {
        // margin: [0, 12],
      },
    ],
    pageBreakBefore(
      currentNode,
      followingNodesOnPage,
      // nodesOnNextPage,
      // previousNodesOnPage,
    ) {
      return (
        currentNode.headlineLevel === 1 && followingNodesOnPage.length === 0
      );
    },

    footer: [
      {
        margin: [20, -2, 20, 0],
        table: {
          alignment: '',
          widths: ['75.33%', '*', '12.33%'],
          body: [
            [
              {
                text: [
                  { text: 'หมายเหตุ\n', bold: true },
                  `${data?.remark || '-'}`,
                ],
                rowSpan: 3,

                border: [false, false, false, false],
              },
              {
                text: 'รวมเงิน',
                border: [false, false, false, false],
              },
              {
                text: `${sumAllPrice
                  .toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,
                border: [false, false, false, false],
                style: 'number',
              },
            ],

            [
              {
                text: '',
                border: [false, false, false, false],
              },
              {
                text: '',
                border: [false, false, false, false],
                bold: true,
              },
              {
                text: '',
                style: 'number',
                border: [false, false, false, false],
              },
            ],

            [
              {
                text: '',
                border: [false, false, false, false],
              },
              {
                text: `VAT ${setting?.tax_number} %`,
                border: [false, false, false, false],
              },
              {
                text: `${taxTotal.toFixed(2)}`,
                border: [false, false, false, false],
                style: 'number',
              },
            ],
            [
              {
                fillColor: '#4297ff',
                text: `${THBText(
                  setting?.tax_exclude
                    ? priceEndWithTax.toFixed(2)
                    : priceEnd.toFixed(2),
                )}`,
                border: [false, false, false, false],
                alignment: 'center',
                bold: true,
              },
              {
                text: 'จำนวนเงินทั้งสิ้น',
                border: [false, false, false, false],
              },
              {
                fillColor: '#4297ff',
                text: `${(setting?.tax_exclude
                  ? priceEndWithTax.toFixed(2)
                  : priceEnd.toFixed(2)
                ).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,
                border: [false, false, false, false],
                bold: true,
                style: 'number',
              },
            ],
          ],
        },
      },
      {
        margin: [20, 2, 20, 0],
        alignment: 'center',
        table: {
          alignment: '',
          heights: 70,
          widths: ['33.33%', '*', '33.33%'],
          body: [
            [
              {
                text: [
                  `${data?.bidder || '...................................'}\n`,
                  { text: 'ผู้เสนอราคา\n' },
                  { text: 'วันที่/date............................\n' },
                ],
                margin: [0, 35, 0, 0],
                alignment: 'center',

                border: [true, true, true, true],
              },
              {
                text: 'ตราบริษัท',
                alignment: 'center',
                margin: [0, 55, 0, 0],
                border: [true, true, true, true],
              },
              {
                text: [
                  { text: '....................................\n' },
                  { text: 'ผู้มีอำนาจลงนาม/Authorized Signature \n' },
                  { text: 'วันที่/date............................\n' },
                ],
                margin: [0, 35, 0, 0],
                alignment: 'center',
                border: [true, true, true, true],
              },
            ],
          ],
        },
      },
    ],

    styles: {
      header: {
        fontSize: 11,
        bold: true,
        alignment: 'right',
      },
      header1: {
        fontSize: 14,
        bold: true,
      },
      body: {
        fontSize: 9,
        alignment: 'left',
        bold: true,
      },
      body1: {
        alignment: 'right',
        fontSize: 10,
        bold: true,
      },
      body2: {
        alignment: 'right',
        fontSize: 8,
      },
      number: {
        alignment: 'right',
        fontSize: 10,
        bold: true,
      },
    },
    // images: {
    //   logo: setting?.logo_quotation?.url || setting?.logo?.url,
    // },
    images: {
      logo:
        'https://storage.googleapis.com/mydev_eonlineshop/others/thumbnails/1679843719324_1024x1024.png',
    },
  };

  pdfMake.createPdf(documentRef).open();
};
export default QuotationPrint;
