import {
  ORDER_MESSAGE_ALL,
  ORDER_MESSAGE_GET,
  ORDER_MESSAGE_POST,
  ORDER_MESSAGE_PUT,
  ORDER_MESSAGE_DEL,
  ORDER_MESSAGE_LOADING,
  ORDER_MESSAGE_ERROR,
} from '../types';

import api from '../../../utils/functions/api';

export const orderMessageCreate = (payload) => async (dispatch) => {
  try {
    dispatch({ type: ORDER_MESSAGE_LOADING });
    if (payload?.file) {
      // Upload File First
      const formData = new FormData();
      formData.append('file', payload?.file);
      const { data: uploadData } = await api.post(
        `${process.env.REACT_APP_API_URL}/uploads/file`,
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        },
      );
      console.log('Upload Data', uploadData);
      // Then Create Comment
      const { data } = await api.post(
        `${process.env.REACT_APP_API_URL}/order-message`,
        {
          ...payload,
          file: uploadData?.id,
        },
      );

      dispatch({ type: ORDER_MESSAGE_POST, payload: data });
    } else {
      const { data } = await api.post(
        `${process.env.REACT_APP_API_URL}/order-message`,
        {
          ...payload,
        },
      );
      dispatch({ type: ORDER_MESSAGE_POST, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: ORDER_MESSAGE_ERROR });
    throw new Error(error);
  }
};

export const orderMessageAll = (params) => async (dispatch) => {
  try {
    const { name = '', size = '', page = 1, order = '' } = params;
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/order-message?name=${name}&size=${size}&page=${page}&order=${order}`,
    );
    if (status === 200) {
      dispatch({ type: ORDER_MESSAGE_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: ORDER_MESSAGE_ERROR });
    throw new Error(error);
  }
};

export const orderMessageGet = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/order-message/${id}`,
    );
    if (status === 200) {
      dispatch({ type: ORDER_MESSAGE_GET, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: ORDER_MESSAGE_ERROR });
    throw new Error(error);
  }
};

export const orderMessagePut = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: ORDER_MESSAGE_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/order-message/${id}`,
      payload,
    );
    dispatch({ type: ORDER_MESSAGE_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: ORDER_MESSAGE_ERROR });
    throw new Error(error);
  }
};
export const orderMessageDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: ORDER_MESSAGE_LOADING });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/order-message/${id}`,
    );
    dispatch({ type: ORDER_MESSAGE_DEL, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: ORDER_MESSAGE_ERROR });
    throw new Error(error);
  }
};
