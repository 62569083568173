import pdfMake from 'addthaifont-pdfmake';
import 'addthaifont-pdfmake/build/vfs_fonts';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import dayjs from 'dayjs';
import 'dayjs/locale/th';
import _ from 'lodash';

const THBText = require('thai-baht-text');

pdfMake.fonts = {
  Sarabun: {
    normal: 'Sarabun-Light.ttf',
    bold: 'Sarabun-Regular.ttf',
    italics: 'Sarabun-LightItalic.ttf',
    bolditalics: 'Sarabun-Italic.ttf',
  },
};
dayjs.locale('th');
dayjs.extend(buddhistEra);
dayjs.extend(LocalizedFormat);
// const checKPack = () => {
//   return 0;
// };

const genPack = (quantityData, optionData) => {
  const packValue = quantityData;
  const diffValue = quantityData;
  const objPack = { packValue: '-', diffValue: '-' };
  if (!optionData) {
    return objPack;
  }
  return { packValue, diffValue };
};

const genBody = (data) => {
  const product = _.map(data, (each, index) => [
    {
      text: index + 1,
    },
    {
      text: `${each?.product?.name} `,
    },
    {
      text: each?.option?.name || '-',
    },
    {
      text: each?.productType || '-',
    },
    {
      text: '-',
      alignment: 'right',
    },
    {
      text: each?.quantity,
      alignment: 'right',
    },
  ]);
  return product;
};

const PurchasePrint = async (paymentTransaction, setting, ArrayProductList) => {
  console.log('PDF ArrayProductList', ArrayProductList);
  console.log('PDF paymentTransaction', paymentTransaction);
  const genItemBody = genBody(ArrayProductList);
  const documentRef = {
    pageSize: 'A5',
    pageOrientation: 'Landscape',
    pageMargins: [20, 100, 20, 20],
    defaultStyle: {
      font: 'Sarabun',
      fontSize: '10',
    },
    header: [
      {
        margin: [20, 20, 20, 10],
        table: {
          widths: ['*', '*'],
          body: [
            [
              {
                text: 'ใบรับจ่ายสินค้า',
                style: 'header',
                alignment: 'center',
                colSpan: 2,
                border: [true, true, true, true],
              },
              { text: '', border: [false, true, false, false] },
            ],
          ],
        },
      },
      {
        margin: [20, 0, 20, 0],
        table: {
          widths: ['50%', '50%'],
          body: [
            [
              {
                text: 'ชื่อผู้สั่งซื้อ/ร้านค้า',
                style: 'bodyHeader',
                border: [true, false, false, false],
              },
              {
                text: 'วันที่',
                border: [false, false, true, false],
                style: 'bodyHeader',
              },
            ],
            [
              {
                text: `${paymentTransaction?.customer?.name}`,
                style: '',
                border: [true, false, false, false],
              },
              {
                text: `${dayjs(paymentTransaction?.createdAt).format(
                  'DD MMM BBBB',
                )}`,
                border: [false, false, true, false],
                style: '',
              },
            ],
          ],
        },
      },
    ],

    content: [
      {
        table: {
          // headers are automatically repeated if the table spans over multiple pages
          // you can declare how many rows should be treated as headers
          headerRows: 2,
          widths: ['10%', '30%', '15%', '15%', '15%', '*'],
          body: [
            [
              {
                text: 'ลำดับ',
                style: 'tableHeader',
                rowSpan: 2,
                border: [true, true, true, true],
              },
              {
                text: 'ชนิดสินค้า',
                rowSpan: 2,
                style: 'tableHeader',

                border: [true, true, true, true],
              },
              {
                text: 'ขนาด',
                rowSpan: 2,
                border: [true, true, true, true],

                style: 'tableHeader',
              },
              {
                text: 'ประเภท',
                border: [true, true, true, true],
                rowSpan: 2,
                style: 'tableHeader',
              },
              {
                text: 'จำนวน',
                border: [true, true, true, true],
                style: 'tableHeader',
                colSpan: 2,
              },
              {
                text: '',
                border: [true, true, true, true],
                style: 'tableHeader',
              },
            ],
            [
              {
                text: '',
                border: [true, true, true, true],
              },
              {
                text: '',

                border: [true, true, true, true],
              },
              {
                text: '',
                border: [true, true, true, true],
              },
              {
                text: '',
                border: [true, true, true, true],
              },
              {
                text: 'มัด/ถุง/ลัง',
                alignment: 'center',
                border: [true, true, true, true],
              },
              {
                text: 'เส้น/ตัว/กก.',
                alignment: 'center',
                border: [true, true, true, true],
              },
            ],
            ...genItemBody,
          ],
        },
      },
    ],

    // footer: [
    //   {
    //     margin: [20, 0, 20, 20],
    //     table: {
    //       // headers are automatically repeated if the table spans over multiple pages
    //       // you can declare how many rows should be treated as headers
    //       widths: ['10%', '60%', '15%', '15%'],

    //       body: [
    //         [
    //           {
    //             text: 'ราคารวม',
    //             style: 'tableHeader',
    //             border: [false, true, false, true],
    //           },
    //           {
    //             text: `${THBText(1900)}`,
    //             border: [false, true, false, true],
    //           },
    //           {
    //             text: '',
    //             border: [false, true, false, true],
    //             style: 'tableHeader',
    //           },
    //           {
    //             text: '1900',
    //             border: [false, true, false, true],
    //             style: 'tableHeader',
    //           },
    //         ],
    //       ],
    //     },
    //   },
    //   {
    //     margin: [20, 20, 20, 20],
    //     table: {
    //       // headers are automatically repeated if the table spans over multiple pages
    //       // you can declare how many rows should be treated as headers
    //       headerRows: 1,
    //       widths: ['10%', '60%', '15%', '15%'],

    //       body: [
    //         [
    //           {
    //             text: '',
    //             style: 'tableHeader',
    //             border: [false, false, false, false],
    //           },
    //           {
    //             text: '',
    //             style: 'tableHeader',
    //             border: [false, false, false, false],
    //           },
    //           {
    //             text: '(ศูนย์วิจัยระบบอัตโนมัติอัจฉริยะ)\nเจ้าของร้าน',
    //             border: [false, true, false, false],
    //             colSpan: 2,
    //             style: 'tableHeader',
    //           },
    //           {
    //             text: 'ราคา',
    //             border: [false, true, false, false],
    //             style: 'tableHeader',
    //           },
    //         ],
    //         // ...genItemBody,
    //       ],
    //     },
    //   },
    // ],

    styles: {
      header: {
        fontSize: 14,
        bold: true,
      },
      bodyHeader: {
        bold: true,
      },
      tableHeader: {
        bold: true,
        alignment: 'center',
        margin: [0, 6],
      },
      name: {
        fontSize: 16,
        bold: true,
      },
      tab: {
        color: 'white',
      },
    },
    images: {},
  };
  pdfMake.createPdf(documentRef).open();
};
export default PurchasePrint;
