/* eslint-disable no-underscore-dangle */
/* eslint-disable consistent-return */
/* eslint-disable max-len */
import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// import { Link } from 'react-router-dom';
// import _ from 'lodash';
import { Helmet } from 'react-helmet';

// import axios from 'axios';

import {
  BarChart as BarChartIcon,
  Users as UsersIcon,
  User as UserIcon,
  FileText as FileTextIcon,
  Sliders as SlidersIcon,
  Archive as ArchiveIcon,
  UserPlus as UserPlusIcon,
  Layout as LayoutIcon,
  FileMinus as FileMinusIcon,
  Percent as PercentIcon,
} from 'react-feather';

// import { io } from 'socket.io-client';
// import background from '../assets/images/BG-2.webp';

import { NavbarHome } from '../components/Navbars';
import { MainFooter } from '../components/Footers';
import { MainSidebar } from '../components/Sidebar';
import { Loading } from '../components/Loading';
import { NotAuthorized } from '../components/Error';
import {
  DefaultMenu,
  LeftMenu,
  RightMenu,
  LowerMenu,
  Diprom1,
  Diprom2,
  Diprom3,
  Diprom4,
} from '../components/Menu';
import accessRight from '../utils/functions/accessRight';
import * as actions from '../redux/actions';

import { FRONT_TEMPLATE } from '../utils/constants';

export default function Home() {
  // const socket = useRef();

  const dispatch = useDispatch();
  const me = useSelector((state) => state.me);
  const availableModule = useSelector((state) => state.availableModule);
  const subAvailableModule = useSelector((state) => state.subAvailableModule);
  const setting = useSelector((state) => state.setting);
  const informationLayout = useSelector((state) => state.informationLayout);
  const department = useSelector((state) => state.department);

  // const [onlineUsers, setOnlineUsers] = useState([]);

  console.log('availableModule', availableModule);
  console.log('subAvailableModule', subAvailableModule);

  console.log('me', me);
  console.log('department in home page', department);

  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  const handleOnMobileNavClose = () => {
    setMobileNavOpen(false);
  };

  useEffect(() => {
    const fetchMe = async () => {
      try {
        dispatch(actions.meGet());
        dispatch(actions.settingAll());
      } catch (error) {
        console.error('Fetch Me Error', error);
      }
    };

    const fetchInformation = async () => {
      try {
        dispatch(actions.informationAll());
      } catch (error) {
        console.error('Fetch Info Error', error);
      }
    };

    const fetchAvailableModule = async () => {
      try {
        dispatch(actions.availableModuleAll());
      } catch (error) {
        console.error('Fetch Available Module Error', error);
      }
    };

    const fetchSubAvailableModule = async () => {
      try {
        dispatch(actions.subAvailableModuleAll());
      } catch (error) {
        console.error('Fetch Available Module Error', error);
      }
    };

    fetchMe();
    fetchAvailableModule();
    fetchSubAvailableModule();
    fetchInformation();
    return () => {};
  }, []);

  // useEffect(() => {
  //   socket.current = io(process.env.REACT_APP_SOCKET_URL);
  //   console.log('add user to socket', me?.userData?.id || me?.userData?._id);
  //   if (me?.userData?.id || me?.userData?._id) {
  //     socket.current.emit(
  //       'new-user-add',
  //       me?.userData?.id || me?.userData?._id,
  //     );
  //   }
  // }, [me]);

  // useEffect(() => {
  //   socket.current.on('get-users', (users) => {
  //     console.log('users online', users);
  //     setOnlineUsers(users);
  //   });
  // }, [me]);

  if (!me?.userData) {
    return <Loading />;
  }

  const menuList = [
    {
      moduleName: 'PROFILE',
      link: '/profile',
      icon: <UserIcon className="text-white" size={48} />,
      title: 'โปรไฟล์',
      extendedStyle: 'bg-gray-400',
    },
    {
      moduleName: 'DASHBOARD',
      link: '/dashboard',
      icon: <BarChartIcon className="text-white" size={48} />,
      title: 'แดชบอร์ด',
      extendedStyle: 'bg-gray-600 ',
    },
    {
      moduleName: 'SPM',
      link: '/spm/orders',
      icon: <FileTextIcon className="text-white" size={48} />,
      title: 'การนำเข้าส่งออก',
      extendedStyle: 'bg-indigo-600 ',
    },
    {
      moduleName: 'POS',
      link: '/pos/main-front',
      icon: <FileTextIcon className="text-white" size={48} />,
      title: 'ขายหน้าร้าน',
      extendedStyle: 'bg-indigo-600 ',
    },
    {
      moduleName: 'RESERVE',
      link: '/bms/booking',
      icon: <FileTextIcon className="text-white" size={48} />,
      title: 'การจอง',
      extendedStyle: 'bg-blue-500 ',
    },
    {
      moduleName: 'WMS',
      link: '/wms/products',
      icon: <ArchiveIcon className="text-white" size={48} />,
      title: 'คลังสินค้า',
      extendedStyle: 'bg-pink-600',
    },
    {
      moduleName: 'HISTORY',
      link: '/history/wms/history',
      icon: <FileMinusIcon className="text-white" size={48} />,
      title: 'จัดการประวัติ',
      extendedStyle: 'bg-yellow-800',
    },
    {
      moduleName: 'CRM',
      link: '/crm/customers',
      icon: <UsersIcon className="text-white" size={48} />,
      title: 'ลูกค้าสัมพันธ์',
      extendedStyle: 'bg-green-600 ',
    },
    {
      moduleName: 'HRMS',
      link: '/hrms/employee',
      icon: <UserPlusIcon className="text-white" size={48} />,
      title: 'ทรัพยากรบุคคล',
      extendedStyle: 'bg-yellow-500 ',
    },
    {
      moduleName: 'CMS',
      link: '/cms/main-site',
      icon: <LayoutIcon className="text-white" size={48} />,
      title: 'จัดการเว็บไซต์',
      extendedStyle: 'bg-red-500',
    },
    {
      moduleName: 'DISCOUNT',
      link: '/discount/promotion',
      icon: <PercentIcon className="text-white" size={48} />,
      title: 'จัดการส่วนลด และคูปอง',
      extendedStyle: 'bg-red-700',
    },
    {
      moduleName: 'SETTING',
      link: '/setting',
      icon: <SlidersIcon className="text-white" size={48} />,
      title: 'การตั้งค่า',
      extendedStyle: 'bg-black',
    },
  ];

  const menuProps = {
    availableModule,
    informationLayout,
    me,
    menuList,
    setting,
  };

  const renderMenu = () => {
    switch (setting?.main_page_type) {
      case FRONT_TEMPLATE.DEFAULT.status_code:
        return <DefaultMenu {...menuProps} />;
      case FRONT_TEMPLATE.LEFT_MENU.status_code:
        return <LeftMenu {...menuProps} />;
      case FRONT_TEMPLATE.RIGHT_MENU.status_code:
        return <RightMenu {...menuProps} />;
      case FRONT_TEMPLATE.LOWER_MENU.status_code:
        return <LowerMenu {...menuProps} />;
      case FRONT_TEMPLATE.DIPROM_1.status_code:
        return <Diprom1 {...menuProps} />;
      case FRONT_TEMPLATE.DIPROM_2.status_code:
        return <Diprom2 {...menuProps} />;
      case FRONT_TEMPLATE.DIPROM_3.status_code:
        return <Diprom3 {...menuProps} />;
      case FRONT_TEMPLATE.DIPROM_4.status_code:
        return <Diprom4 {...menuProps} />;
      default:
        return <DefaultMenu {...menuProps} />;
    }
  };

  if (!accessRight(me)) {
    return (
      <div>
        <div className="min-h-screen bg-white">
          <Helmet>
            <meta charSet="utf-8" />
            <title>หน้าหลัก - {setting?.name || 'ระบบ E-OnlineShop'}</title>
          </Helmet>
          <NavbarHome />
          <div className="min-h-screen ">
            {renderMenu()}
            {/* <div className="absolute top-0 w-full h-full bg-white bg-no-repeat bg-full"></div> */}
          </div>
          <div className="w-full">
            <MainFooter />
          </div>
        </div>
      </div>
    );
  }
  return (
    <div>
      <div className="min-h-screen">
        <MainSidebar
          onMobileClose={handleOnMobileNavClose}
          openMobile={isMobileNavOpen}
          me={me}
        />
        <NavbarHome />
        <NotAuthorized />
        <div className="lg:ml-64">
          <MainFooter />
        </div>
      </div>
    </div>
  );
}
