import pdfMake from 'addthaifont-pdfmake';
import 'addthaifont-pdfmake/build/vfs_fonts';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import dayjs from 'dayjs';
import 'dayjs/locale/th';

pdfMake.fonts = {
  Sarabun: {
    normal: 'Sarabun-Light.ttf',
    bold: 'Sarabun-Regular.ttf',
    italics: 'Sarabun-LightItalic.ttf',
    bolditalics: 'Sarabun-Italic.ttf',
  },
};
dayjs.locale('th');
dayjs.extend(buddhistEra);
dayjs.extend(LocalizedFormat);

const genBody = (data) =>
  _.map(data, (row, index) => [
    {
      text: index + 1,
    },
    { text: row?.course?.name || '' },
    { text: row?.quantity || '' },
    { text: row?.customer || '' },
    { text: row?.remark || '' },
  ]);

const BookingOrder = async (booking, date, setting) => {
  const content = genBody(booking?.rows);
  const color = '#eeeeee';
  const documentRef = {
    pageSize: 'A4',
    pageOrientation: 'Portrait',
    pageMargins: [20, 20, 20, 20],
    defaultStyle: {
      font: 'Sarabun',
      fontSize: '10',
    },

    info: {
      title: 'รายการจอง',
    },

    content: [
      {
        table: {
          // headers are automatically repeated if the table spans over multiple pages
          // you can declare how many rows should be treated as headers
          headerRows: 1,
          widths: ['100%'],

          body: [
            [
              {
                text: 'รายการจองคอร์ส',
                style: 'header',
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: `วันที ${dayjs(date).format('DD MMMM BBBB')}`,
                style: 'header',
                border: [false, false, false, false],
              },
            ],
          ],
        },
      },
      {
        margin: [0, 30],
        table: {
          // headers are automatically repeated if the table spans over multiple pages
          // you can declare how many rows should be treated as headers
          headerRows: 1,
          widths: ['10%', '27.5%', '15%', '27.5%', '20%'],
          body: [
            [
              {
                text: 'ลำดับ',
                style: 'headerTable',
                fillColor: '#eeeeee',
              },
              { text: 'คอร์ส', style: 'headerTable', fillColor: color },
              { text: 'จำนวน', style: 'headerTable', fillColor: color },
              { text: 'ลูกค้า', style: 'headerTable', fillColor: color },
              { text: 'หมายเหตุ', style: 'headerTable', fillColor: color },
            ],
            ...content,
          ],
        },
      },
    ],

    footer: [],

    styles: {
      header: {
        fontSize: 16,
        bold: true,
        alignment: 'center',
      },
      headerTable: {
        fontSize: 12,
        bold: true,
        alignment: 'center',
      },

      name: {
        fontSize: 16,
        bold: true,
      },
      tab: {
        color: 'white',
      },
    },
  };
  pdfMake.createPdf(documentRef).open();
};
export default BookingOrder;
