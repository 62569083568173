import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import {
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Backdrop,
  Button,
  Card,
  Fade,
  Modal,
  FormControlLabel,
  Checkbox,
} from '@mui/material';

import SaveIcon from '@mui/icons-material/Save';

import _ from 'lodash';
import PropTypes from 'prop-types';
import * as actions from '../../redux/actions';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90vw',
  boxShadow: 24,
  p: 4,
};

const defaultValue = {
  name: '',
  unit: '',
  packing_size: '',
  packing_unit: '',
  net: '',
  unit_net: '',
  detail: '',
  type_code: '',
  inventory: '',
  images: '',
  option: '',
};

function Product({ isOpen, handleOnClose }) {
  const [serialType, setSerialType] = useState(false);
  const dispatch = useDispatch();
  const product = useSelector((state) => state.product);
  const productType = useSelector((state) => state.productType);
  const {
    formState: { errors },
    handleSubmit,
    control,
    reset,
  } = useForm();

  // const name = '';

  // useEffect(() => {
  //   dispatch(actions.productAll({ name }));
  //   dispatch(actions.productTypeAll(''));
  //   dispatch(actions.meGet());
  //   return () => {};
  // }, [name]);

  const onSubmit = async (data) => {
    try {
      const payload = {
        ...data,
        images: [],
        inventory: '0',
        price: '0',
      };
      console.log('payload', payload);
      await dispatch(actions.productCreate(payload));
      reset(defaultValue);
      alert('สำเร็จ');
      handleOnClose();
      await dispatch(actions.productAllWithLightWeight(''));
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={isOpen}
        onClose={handleOnClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isOpen}>
          <Card sx={style} className="max-w-xl">
            <div className="py-2">เพิ่มข้อมูลสินค้า</div>
            <div className="py-2">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="flex flex-row flex-wrap">
                  <div className="w-full px-1 py-2">
                    <Controller
                      name={'type_code'}
                      control={control}
                      defaultValue={product ? product.type_code : ''}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="รหัสสินค้า 5 หลัก"
                          required
                          fullWidth
                          size={'small'}
                          helperText={errors.type_code && 'กรุณาใส่ข้อมูล'}
                        />
                      )}
                    />
                  </div>
                  <div className="w-full px-1 py-2">
                    <Controller
                      name={'name'}
                      control={control}
                      defaultValue={product ? product.name : ''}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="ชื่อสินค้า"
                          required
                          fullWidth
                          size={'small'}
                          helperText={errors.name && 'กรุณาใส่ข้อมูล'}
                        />
                      )}
                    />
                  </div>
                  <div className="w-full px-1 py-2">
                    <Controller
                      name={'model_name'}
                      control={control}
                      defaultValue={product ? product.model_name : ''}
                      rules={{ required: false }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="รุ่นสินค้า"
                          fullWidth
                          size={'small'}
                          helperText={errors.model_name && 'กรุณาใส่ข้อมูล'}
                        />
                      )}
                    />
                  </div>
                  <div className="w-full lg:w-1/2 px-1 py-2">
                    <Controller
                      name={'unit'}
                      control={control}
                      defaultValue={product ? product.unit : ''}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="หน่วยสินค้า"
                          required
                          fullWidth
                          size={'small'}
                          helperText={errors.unit && 'กรุณาใส่ข้อมูล'}
                        />
                      )}
                    />
                  </div>
                  <div className="w-full lg:w-1/2 px-1 py-2">
                    <Controller
                      name={'product_type'}
                      control={control}
                      defaultValue={product ? product?.product_type?.id : ''}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <FormControl sx={{ minWidth: 120 }} fullWidth={true}>
                          <InputLabel id="type" size={'small'}>
                            ประเภทสินค้า*
                          </InputLabel>
                          <Select
                            {...field}
                            label="ประเภทสินค้า"
                            size={'small'}
                            fullWidth
                            required
                          >
                            {_.size(productType?.rows) ? (
                              _.map(productType.rows, (row) => (
                                <MenuItem key={row.id} value={row.id}>
                                  {row.name}
                                </MenuItem>
                              ))
                            ) : (
                              <MenuItem>
                                <MenuItem disabled value="">
                                  <em>ไม่มีข้อมูล</em>
                                </MenuItem>
                              </MenuItem>
                            )}
                          </Select>
                        </FormControl>
                      )}
                    />
                  </div>
                </div>
                <div className="flex flex-row justify-end gap-1 py-4">
                  <Button
                    variant="contained"
                    type="submit"
                    startIcon={<SaveIcon />}
                  >
                    บันทึก
                  </Button>
                  <Button variant="outlined" onClick={handleOnClose}>
                    ยกเลิก
                  </Button>
                </div>
              </form>
            </div>
          </Card>
        </Fade>
      </Modal>
    </div>
  );
}

Product.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  isOpen: PropTypes.bool,
  handleOnClose: PropTypes.func,
};

Product.defaultProps = {
  title: '',
  subtitle: '',
};

export default Product;
