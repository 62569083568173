import { TextField } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

export function OrderStatusTypeForm({
  errors,
  orderStatus,
  Controller,
  control,
}) {
  return (
    <div className="flex flex-row flex-wrap">
      <div className="w-full px-1 py-2">
        <Controller
          name={'status'}
          control={control}
          defaultValue={orderStatus ? orderStatus.status : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="รหัส"
              fullWidth
              size={'small'}
              multiline={true}
              helperText={errors.status && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name={'description'}
          control={control}
          defaultValue={orderStatus ? orderStatus.description : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="รายละเอียด"
              fullWidth
              size={'small'}
              multiline={true}
              helperText={errors.description && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
    </div>
  );
}

OrderStatusTypeForm.propTypes = {
  errors: PropTypes.shape({
    name: PropTypes.object,
    description: PropTypes.object,
    status: PropTypes.object,
  }),
  orderStatus: PropTypes.shape({
    id: PropTypes.string,
    status: PropTypes.string,
    description: PropTypes.string,
  }),
  Controller: PropTypes.func,
  control: PropTypes.object,
};

OrderStatusTypeForm.defaultProps = {
  orderStatus: null,
};

export default OrderStatusTypeForm;
