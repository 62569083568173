import {
  DISCOUNT_PAYMENT_ALL,
  DISCOUNT_PAYMENT_CREATE,
  DISCOUNT_PAYMENT_GET,
  DISCOUNT_PAYMENT_UPDATE,
  DISCOUNT_PAYMENT_DELETE,
} from '../../actions/types';

const initialState = {
  serials: null,
  isLoading: true,
  isCompleted: false,
};
export default function (state = initialState, action) {
  switch (action.type) {
    case DISCOUNT_PAYMENT_ALL:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case DISCOUNT_PAYMENT_GET:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case DISCOUNT_PAYMENT_CREATE:
      return { isLoading: true, isCompleted: true };
    case DISCOUNT_PAYMENT_UPDATE:
      return { isLoading: true, isCompleted: true };
    case DISCOUNT_PAYMENT_DELETE:
      return { isLoading: true, isCompleted: true };
    default:
      return state;
  }
}
