import {
  SETTING_ALL,
  SETTING_PUT,
  SETTING_LOADING,
  SETTING_ERROR,
} from '../../actions/types';

const initialState = {
  information: null,
  isLoading: true,
  isCompleted: true,
};
// eslint-disable-next-line func-names
export default function (state = initialState, action) {
  switch (action.type) {
    case SETTING_LOADING:
      return { isLoading: true, isCompleted: true, name: 'ระบบ E-OnlineShop' };
    case SETTING_ERROR:
      return {
        isLoading: false,
        isCompleted: false,
        name: 'ระบบ E-OnlineShop',
        ...action.payload,
      };
    case SETTING_ALL:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case SETTING_PUT:
      return { isLoading: false, isCompleted: true, name: 'ระบบ E-OnlineShop' };
    default:
      return state;
  }
}
