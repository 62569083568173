import React from 'react';
import { Button } from '@mui/material';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';

export default function NotFound({ message }) {
  const history = useHistory();
  return (
    <div className="grid grid-cols-1 lg:grid-cols-3 gap-2 place-content-center min-h-screen">
      <div></div>
      <div className="p-6 bg-white rounded-md">
        <div className="text-center">ขออภัย ไม่พบหน้าที่คุณต้องการ</div>
        <div className="text-red-600 text-center">{message}</div>
        <div className="flex justify-center mt-2 gap-2">
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              history.push('/');
            }}
          >
            กลับสู่หน้าหลัก
          </Button>
        </div>
      </div>
      <div></div>
    </div>
  );
}

NotFound.propTypes = {
  message: PropTypes.string,
};

NotFound.defaultProps = {
  message: '',
};
