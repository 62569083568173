import Proptypes from 'prop-types';

export const Comment = Proptypes.shape({
  title: Proptypes.string,
  uid: Proptypes.oneOfType([Proptypes.string, Proptypes.object]),
  timestamps: Proptypes.string,
});

export const Order = Proptypes.shape({
  customer: Proptypes.object,
  order_status: Proptypes.string,
  date_expected: Proptypes.string,
  remark: Proptypes.string,
  comments: Proptypes.arrayOf(Comment),
});

export const OrderArray = Proptypes.shape({
  rows: Proptypes.arrayOf(Order),
  currPage: Proptypes.number,
  isComplete: Proptypes.bool,
  isLoading: Proptypes.bool,
  lastPage: Proptypes.number,
});

export default { Comment, Order, OrderArray };
