import {
  MATERIALTYPES_ALL,
  MATERIALTYPES_GET,
  MATERIALTYPES_DEL,
  MATERIALTYPES_PUT,
  MATERIALTYPES_POST,
  MATERIALTYPES_LOADING,
  MATERIALTYPES_ERROR,
} from '../../actions/types';

const initialState = {
  materialType: null,
  isLoading: true,
  isCompleted: true,
};
// eslint-disable-next-line func-names
export default function (state = initialState, action) {
  switch (action.type) {
    case MATERIALTYPES_LOADING:
      return { isLoading: true, isCompleted: true };
    case MATERIALTYPES_ERROR:
      return { isLoading: false, isCompleted: false, ...action.payload };
    case MATERIALTYPES_ALL:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case MATERIALTYPES_GET:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case MATERIALTYPES_POST:
      return { isLoading: false, isCompleted: true };
    case MATERIALTYPES_PUT:
      return { isLoading: false, isCompleted: true };
    case MATERIALTYPES_DEL:
      return { isLoading: false, isCompleted: true };
    default:
      return state;
  }
}
