import { POLICY_ALL, POLICY_PUT, POLICY_LOADING, POLICY_ERROR } from '../types';

import api from '../../../utils/functions/api';

export const policyAll = () => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/policy`,
    );
    if (status === 200) {
      dispatch({ type: POLICY_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: POLICY_ERROR });
    throw new Error(error);
  }
};

export const policyPut = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: POLICY_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/policy/${id}`,
      payload,
    );
    dispatch({ type: POLICY_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: POLICY_ERROR });
    throw new Error(error);
  }
};
