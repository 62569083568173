import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import _ from 'lodash';
import PropTypes from 'prop-types';
import * as actions from '../../redux/actions';
import Loading from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';

function MaterialStock({ title, subtitle }) {
  const dispatch = useDispatch();
  const place = useSelector((state) => state.place);
  const history = useHistory();

  useEffect(() => {
    dispatch(actions.placeAll(''));
    return () => {};
  }, []);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const renderPlace = () => (
    <div className="flex flex-wrap py-4">
      {!_.isEmpty(place.rows) ? (
        place.rows.map((row, index) => (
          <div
            key={index}
            className="w-full lg:4/12 xl:w-3/12 p-2"
            onClick={() => {
              history.push(`/ims/material-stock/place/${row.id}`);
            }}
          >
            <div className="items-center text-center  p-4 bg-white rounded-lg  shadow-xl dark:bg-gray-800">
              <i className="fas fa-home fa-3x "></i>
              <p style={{ fontSize: '20px' }}>{row.name}</p>
            </div>
          </div>
        ))
      ) : (
        <div className="flex justify-center p-4">
          <div>ไม่มีข้อมูล</div>
        </div>
      )}
    </div>
  );

  if (place.isLoading) {
    return <Loading />;
  }
  if (!place.isLoading && place.isCompleted) {
    return (
      <div>
        <div className="flex justify-between">
          <div>{renderTitle()}</div>
        </div>
        {renderPlace()}
      </div>
    );
  }
  return <Error message={place?.message} />;
}

MaterialStock.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

MaterialStock.defaultProps = {
  title: '',
  subtitle: '',
};

export default MaterialStock;
