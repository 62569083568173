export const MATERIAL_TRANSACTION_TYPE = [
  {
    name: 'เข้า',
    direction: 'add',
  },
  {
    name: 'ออก',
    direction: 'desc',
  },
];
export default MATERIAL_TRANSACTION_TYPE;
