import { TextField } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

export function CourseForm({ Controller, control, course }) {
  console.log('course', course);
  return (
    <div>
      <div className="w-full  px-1 py-2">
        <Controller
          name={'name'}
          control={control}
          defaultValue={course ? course?.name : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="ชื่อคอร์ส"
              fullWidth
              size={'small'}
              required
            />
          )}
        />
      </div>
      <div className="w-full  px-1 py-2">
        <Controller
          name={'price'}
          control={control}
          defaultValue={course ? course?.price : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="ราคา"
              fullWidth
              size={'small'}
              required
            />
          )}
        />
      </div>
      <div className="w-full  px-1 py-2">
        <Controller
          name={'detail'}
          control={control}
          defaultValue={course ? course?.detail : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="รายละเอียด"
              fullWidth
              multiline
              rows={4}
              size={'small'}
              required
            />
          )}
        />
      </div>
    </div>
  );
}

CourseForm.propTypes = {
  Controller: PropTypes.func,
  control: PropTypes.object,
};

CourseForm.defaultProps = {};

export default CourseForm;
