import {
  SERIAL_ALL,
  SERIAL_CREATE,
  SERIAL_GET,
  SERIAL_UPDATE,
  SERIAL_DELETE,
} from '../../actions/types';

const initialState = {
  serials: null,
  isLoading: false,
  isCompleted: true,
};
export default function (state = initialState, action) {
  switch (action.type) {
    case SERIAL_ALL:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case SERIAL_GET:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case SERIAL_CREATE:
      return { isLoading: false, isCompleted: true };
    case SERIAL_UPDATE:
      return { isLoading: false, isCompleted: true };
    case SERIAL_DELETE:
      return { isLoading: false, isCompleted: true };
    default:
      return state;
  }
}
