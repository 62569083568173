import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import _ from 'lodash';

const resetValues = {
  name: '',
};

export function EditOptionForm({ selectOption, setSelectOption, option }) {
  const [reload, setReload] = useState(false);
  const {
    control,
    formState: { errors },
    reset,
    getValues,
  } = useForm();
  useEffect(() => () => {}, [reload]);
  console.log('selectOption', selectOption);
  setSelectOption(option);
  // const checkDefaultMaterial = (materialData) => {
  //   if (!_.isEmpty(materialData?.material)) {
  //     console.log('materialData', materialData?.material);
  //     materialData?.material.map(
  //       (_material, index) => {
  //         selectMaterial[index] = _material;
  //         setSelectMaterial(selectMaterial);
  //       },
  //     );
  //   }
  // };

  const handleAddOption = () => {
    if (getValues('name') === '') {
      alert('กรุณาใส่ข้อมูลให้ครบถ้วน');
    } else {
      const data = {
        name: getValues('name'),
      };
      // eslint-disable-next-line react/prop-types
      selectOption.push(data);
      setSelectOption(selectOption);
      reset(resetValues);
      setReload(!reload);
    }
  };

  const handleRemoveOption = (index) => {
    // eslint-disable-next-line react/prop-types
    selectOption.splice(index, 1);
    setSelectOption(selectOption);
    setReload(!reload);
  };

  const addOptions = () => (
    <>
      <TableCell></TableCell>
      <TableCell>
        {' '}
        <div className="w-full">
          <Controller
            name={'name'}
            control={control}
            defaultValue={''}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                label="ชื่อออฟชั่น"
                fullWidth
                size={'small'}
                helperText={errors.unit && 'กรุณาใส่ข้อมูล'}
              />
            )}
          />
        </div>
      </TableCell>
      <TableCell>
        <div className="px-2">
          <Button variant="contained" onClick={() => handleAddOption()}>
            เพิ่ม
          </Button>
        </div>
      </TableCell>
    </>
  );

  const displayOption = () => (
    <TableContainer>
      <Table size="small" className="border-lg">
        <TableHead className="bg-yellow-100">
          <TableRow>
            <TableCell>
              <div className="font-bold">ลำดับที่</div>
            </TableCell>
            <TableCell>
              <div className="font-bold"> ชื่อออฟชั่น</div>
            </TableCell>
            <TableCell>
              <div className="font-bold"> ดำเนินการ</div>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!_.isEmpty(selectOption) ? (
            selectOption.map((_option, index) => (
              <TableRow key={index}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>
                  {_option?.name}
                  {/* <div className="w-full px-1 py-2">
                    <Controller
                      name={'type_code'}
                      control={control}
                      defaultValue={selectOption ? _option?.option?.name : ''}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="ชื่อออฟชั่น"
                          fullWidth
                          size={'small'}
                          helperText={errors.type_code && 'กรุณาใส่ข้อมูล'}
                        />
                      )}
                    />
                  </div> */}
                </TableCell>
                <TableCell>
                  <Button
                    color={'error'}
                    variant="contained"
                    size={'small'}
                    onClick={() => handleRemoveOption(index)}
                  >
                    ลบ
                  </Button>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow key={''}>
              <TableCell colSpan={6}>
                <div className="text-center">ไม่มีออฟชั่น</div>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
        <TableBody>{addOptions()}</TableBody>
      </Table>
    </TableContainer>
  );

  // eslint-disable-next-line no-confusing-arrow
  const showSelectOption = () =>
    // eslint-disable-next-line implicit-arrow-linebreak
    displayOption();

  return (
    <div className="w-full">
      {/* {checkDefaultMaterial(materialRequest)} */}
      <div className="">
        {/* <div className="md:w-1/2">{addOptions()}</div> */}
        <div className="text-center py-2">รายการออฟชั่น</div>
        <div className="w-full">{showSelectOption()}</div>
      </div>
    </div>
  );
}

EditOptionForm.propTypes = {
  option: PropTypes.array,
  selectOption: PropTypes.array,
  setSelectOption: PropTypes.func,
};

EditOptionForm.defaultProps = {
  option: null,
};

export default EditOptionForm;
