import React from 'react';
import {
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

function CustomersPOS({ control, Controller, customerType, selectCustomer }) {
  return (
    <>
      <div className="pt-2 text-xl text-center">สร้างลูกค้าใหม่</div>
      <div className="py-2">
        <div className="flex flex-row flex-wrap">
          <div className="w-full lg:w-1/2  py-2">
            <Controller
              name={'type'}
              control={control}
              defaultValue={''}
              render={({ field }) => (
                <FormControl sx={{ minWidth: 120 }} fullWidth={true}>
                  <InputLabel id="type" size={'small'}>
                    หมวดหมู่*
                  </InputLabel>
                  <Select
                    {...field}
                    label="หมวดหมู่"
                    disabled={selectCustomer}
                    size={'small'}
                    fullWidth
                  >
                    {_.size(customerType?.rows) ? (
                      _.map(customerType.rows, (row) => (
                        <MenuItem key={row.id} value={row.id}>
                          {row.name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem>
                        <MenuItem disabled value="">
                          <em>ไม่มีข้อมูล</em>
                        </MenuItem>
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>
              )}
            />
          </div>
          <div className="w-full lg:w-1/2  py-2">
            <Controller
              name={'organization_type'}
              control={control}
              defaultValue={''}
              render={({ field }) => (
                <FormControl sx={{ minWidth: 120 }} fullWidth>
                  <InputLabel id="type" size={'small'}>
                    ประเภทองค์กร*
                  </InputLabel>
                  <Select
                    {...field}
                    label="ประเภทองค์กร"
                    size={'small'}
                    disabled={selectCustomer}
                    fullWidth
                  >
                    <MenuItem value="บริษัท">บุคคลทั่วไป</MenuItem>
                    <MenuItem value="นิติบุคคล">นิติบุคคล</MenuItem>
                  </Select>
                </FormControl>
              )}
            />
          </div>
          <div className="w-full   py-2">
            <Controller
              name={'name'}
              control={control}
              defaultValue={''}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="ชื่อ*"
                  disabled={selectCustomer}
                  fullWidth
                  size={'small'}
                />
              )}
            />
          </div>
          <div className="w-full   py-2">
            <Controller
              name={'address'}
              control={control}
              defaultValue={''}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="ที่อยู่"
                  disabled={selectCustomer}
                  fullWidth
                  size={'small'}
                />
              )}
            />
          </div>
          <div className="w-full  py-2">
            <Controller
              name={'taxes'}
              control={control}
              defaultValue={''}
              render={({ field }) => (
                <TextField
                  {...field}
                  disabled={selectCustomer}
                  label="เลขที่ผู้เสียภาษี"
                  fullWidth
                  size={'small'}
                />
              )}
            />
          </div>
          <div className="w-full  py-2">
            <Controller
              name={'phone_number'}
              control={control}
              defaultValue={''}
              render={({ field }) => (
                <TextField
                  {...field}
                  disabled={selectCustomer}
                  label="เบอร์โทรศัพท์"
                  fullWidth
                  size={'small'}
                />
              )}
            />
          </div>
        </div>
      </div>
    </>
  );
}

CustomersPOS.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  isOpen: PropTypes.bool,
  handleOnClose: PropTypes.func,
  onSubmitAddCustomer: PropTypes.func,
  Controller: PropTypes.func,
  control: PropTypes.object,
  customerType: PropTypes.object,
  selectCustomer: PropTypes.object,
};

CustomersPOS.defaultProps = {
  title: '',
  subtitle: '',
};

export default CustomersPOS;
