import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import {
  CreateProcessTemplate,
  EditProcess,
  EditProcessTemplate,
  EditWorks,
  ProcessTemplates,
  Processes,
  ViewProcess,
  ViewProcessTemplate,
  ViewWorks,
  Works,
} from '../views/MMS';
import { HomeNavbar } from '../components/Navbars';
import { MainFooter } from '../components/Footers';
import { MainSidebar } from '../components/Sidebar';
import { Loading } from '../components/Loading';
import { NotFound, NotAuthorized } from '../components/Error';
import accessRight from '../utils/functions/accessRight';
import * as actions from '../redux/actions';

export function MMS() {
  const module = 'MMS';
  const prefix = '/mms';
  const name = 'การผลิต';

  const dispatch = useDispatch();
  const me = useSelector((state) => state.me);
  const availableModule = useSelector((state) => state.availableModule);

  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const handleOnMobileNavOpen = () => {
    setMobileNavOpen(true);
  };
  const handleOnMobileNavClose = () => {
    setMobileNavOpen(false);
  };

  useEffect(() => {
    console.log('Layout: MMS');
    dispatch(actions.meGet());
    dispatch(actions.availableModuleAll());
    return () => {};
  }, []);

  if (!me?.userData) {
    return <Loading />;
  }

  if (accessRight(me, module, 0, availableModule)) {
    return (
      <div className="min-h-screen">
        <MainSidebar
          onMobileClose={handleOnMobileNavClose}
          openMobile={isMobileNavOpen}
          me={me}
        />
        <HomeNavbar onMobileNavOpen={handleOnMobileNavOpen} />
        <div className="lg:ml-64  min-h-screen  pt-16 px-4 ">
          <div className="py-4">
            <Switch>
              <Redirect exact from={`${prefix}`} to={`${prefix}/works`} />
              <Route exact path={`${prefix}/works`}>
                <Works title="รายการงาน" subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/works/view/:id`}>
                <ViewWorks title="รายละเอียดงาน" subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/works/edit/:id`}>
                <EditWorks title="แก้ไขงานงาน" subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/processes`}>
                <Processes title="จัดการไลน์การผลิต" subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/processes/view/:id`}>
                <ViewProcess title="รายละเอียดไลน์การผลิต" subtitle={name} />
              </Route>

              <Route exact path={`${prefix}/processes/edit/:id`}>
                <EditProcess title="แก้ไขไลน์การผลิต" subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/process-templates`}>
                <ProcessTemplates
                  title="จัดการรูปแบบไลน์การผลิต"
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/process-templates/create`}>
                <CreateProcessTemplate
                  title="สร้างไลน์การผลิต"
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/process-templates/view/:id`}>
                <ViewProcessTemplate
                  title="รายละเอียดรูปแบบไลน์การผลิต"
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/process-templates/edit/:id`}>
                <EditProcessTemplate
                  title="แก้ไขรูปแบบไลน์การผลิต"
                  subtitle={name}
                />
              </Route>
              <Route path="*">
                <NotFound />
              </Route>
            </Switch>
          </div>
        </div>
        <div className="lg:ml-64">
          <MainFooter />
        </div>
      </div>
    );
  }
  return (
    <div>
      <div className="min-h-screen">
        <MainSidebar
          onMobileClose={handleOnMobileNavClose}
          openMobile={isMobileNavOpen}
          me={me}
        />
        <HomeNavbar onMobileNavOpen={handleOnMobileNavOpen} />
        <NotAuthorized />
        <div className="lg:ml-64">
          <MainFooter />
        </div>
      </div>
    </div>
  );
}

export default MMS;
