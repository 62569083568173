/* eslint-disable import/no-unresolved */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Card, Button, TextField } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import PropTypes from 'prop-types';

import SaveIcon from '@mui/icons-material/Save';

import dayjs from 'dayjs';
import _ from 'lodash';
// eslint-disable-next-line import/extensions
import RichtextEditor from '../../components/Richtext/RichtextEditor';
import ImageUpload from '../../components/ImageUpload/ImageUpload';

// eslint-disable-next-line no-unused-vars
import * as actions from '../../redux/actions';
import Loading from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';
import { BackButton } from '../../components/Button';

const CreatePortfolio = ({ title, subtitle }) => {
  const dispatch = useDispatch();
  const information = useSelector((state) => state.information);
  const portfolio = useSelector((state) => state.portfolio);

  const history = useHistory();
  const [portfolioDescribe, setPortfolioDescribe] = useState('');
  const [uploadedImage, setUploadedImage] = useState('');

  // console.log(productType);

  // Setting React hook form
  const {
    formState: { errors },
    handleSubmit,
    control,
  } = useForm();

  //   console.log('portfolio', portfolio);

  useEffect(() => {
    dispatch(actions.getInformation());
    dispatch(actions.portfolioAll({}));

    return () => {};
  }, []);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const onSubmit = async (data, event) => {
    try {
      const payload = {
        ...data,
        description: portfolioDescribe,
        images: _.map(uploadedImage, (image) => ({
          image: image.data_url,
        })),
      };
      console.log('data in submit', payload);
      await dispatch(actions.portfolioCreate(payload));
      alert('สำเร็จ');
      history.goBack();
    } catch (error) {
      console.log(error);
    }
  };

  const renderForm = () => (
    <Card className="p-6">
      <div className="py-2">เพิ่มข้อมูล</div>
      <div className="w-full px-1 py-2">
        <Controller
          name={'header'}
          control={control}
          defaultValue={portfolio ? portfolio.header : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="ชื่อผลงาน"
              required
              fullWidth
              size={'small'}
              helperText={errors.name && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full px-1 pb-20">
        <div className="pb-2">รายละเอียดผลงาน</div>
        <RichtextEditor
          value={portfolioDescribe}
          setValue={setPortfolioDescribe}
        />
      </div>
      <div className="w-full px-1 py-1">
        <div className="py-1">วันที่สร้างผลงาน</div>
        <Controller
          name={'date'}
          control={control}
          defaultValue={dayjs(new Date())
            .locale('th')
            .format('YYYY-MM-DD HH:mm')}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              type="date"
              fullWidth
              size={'small'}
              helperText={errors.date && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="px-1 mt-4 border-4 rounded-sm">
        <ImageUpload
          images={uploadedImage}
          setImages={setUploadedImage}
          preview_size="250"
          maxNumber={10}
        />
      </div>
    </Card>
  );

  if (portfolio.isLoading || information.isLoading) {
    return <Loading />;
  }
  if (
    !portfolio.isLoading &&
    portfolio.isCompleted &&
    !information.isLoading &&
    information.isCompleted
  ) {
    return (
      <div>
        <div className="flex justify-between">
          <div>{renderTitle()}</div>
        </div>
        <div className="flex flex-row justify-start pb-4">
          <div>
            <BackButton />
          </div>
          <div className="text-lg font-semibold ml-6">สร้างผลงาน</div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="md:flex">
            <div className="w-full">{renderForm()}</div>
          </div>
          <div className="flex flex-row justify-end gap-1 py-4">
            <Button variant="contained" type="submit" startIcon={<SaveIcon />}>
              บันทึก
            </Button>
          </div>
        </form>
      </div>
    );
  }
  return <Error message={information?.message} />;
};

CreatePortfolio.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

CreatePortfolio.defaultProps = {
  title: '',
  subtitle: '',
};

export default CreatePortfolio;
