export const defaultStyle = {
  font: 'Sarabun',
  fontSize: 10,
};

export const slipDefaultStyle = {
  font: 'Sarabun',
  fontSize: 10,
};
export default defaultStyle;
