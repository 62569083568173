/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-underscore-dangle */
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import { useParams } from 'react-router';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import * as actions from '../../redux/actions';
import { BackButton } from '../../components/Button';
import { Loading } from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';

export default function DetailExportLog({ title, subtitle }) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const productTransactionExportLog = useSelector(
    (state) => state.productTransactionExportLog,
  );

  useEffect(() => {
    const fetchProductTransactionExportLog = async () => {
      try {
        await dispatch(actions.productTransactionExportLogGet(id));
      } catch (error) {
        console.error(error);
      }
    };
    fetchProductTransactionExportLog();

    return () => {};
  }, []);

  const { orders } = productTransactionExportLog;
  console.log('orders in detail export', orders);
  // eslint-disable-next-line consistent-return

  const displayProduct = () => (
    <div className="">
      <Paper>
        <TableContainer component={Paper}>
          <Table size="small" className="border-lg">
            <TableHead className="bg-yellow-100">
              <TableRow>
                <TableCell>
                  <div className="font-bold">ลำดับที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ชื่อสินค้า</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> รุ่นสินค้า</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> คลัง</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> จำนวน</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(productTransactionExportLog.orders) ? (
                orders.map((_order, index) => (
                  <TableRow
                    key={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    className={index % 2 === 0 ? 'bg-gray-100' : ''}
                  >
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>
                      <div className="my-auto">{_order.product.name}</div>
                    </TableCell>
                    <TableCell>
                      <div className="my-auto">{_order.product.model_name}</div>
                    </TableCell>
                    <TableCell>
                      <div className="my-auto">{_order.warehouse.name}</div>
                    </TableCell>
                    <TableCell>
                      {_order?.amount} {_order?.product?.unit}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow key={''}>
                  <TableCell colSpan={6}>
                    <div className="text-center">ไม่มีรายการ</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );

  // const displayOrderHistory = () => (
  //   <div className="">
  //     <Paper>
  //       <TableContainer component={Paper}>
  //         <Table size="small" className="border-lg">
  //           <TableHead className="bg-yellow-100">
  //             <TableRow>
  //               <TableCell>
  //                 <div className="font-bold">ลำดับที่</div>
  //               </TableCell>
  //               <TableCell>
  //                 <div className="font-bold"> หมายเลขเอกสาร</div>
  //               </TableCell>
  //               <TableCell>
  //                 <div className="font-bold"> ประเภทการแก้ไข</div>
  //               </TableCell>
  //               <TableCell>
  //                 <div className="font-bold"> แก้ไขวันที่</div>
  //               </TableCell>
  //               <TableCell>
  //                 <div className="font-bold"> แก้ไขโดย</div>
  //               </TableCell>
  //             </TableRow>
  //           </TableHead>
  //           <TableBody>
  //             {!_.isEmpty(
  //               productTransactionExportLog.producttransactionlogexports,
  //             ) ? (
  //               productTransactionExportLog?.producttransactionlogexports.map(
  //                 (_order2, index) => (
  //                   <TableRow
  //                     key={index}
  //                     sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
  //                     className={index % 2 === 0 ? 'bg-gray-100' : ''}
  //                   >
  //                     <TableCell>{index + 1}</TableCell>
  //                     <TableCell>
  //                       <div className="my-auto">
  //                         <div className="my-auto">
  //                           <a
  //                             className="hover:underline cursor-pointer"
  //                             onClick={() => alert('กำลังพัฒนา')}
  //                           >
  //                             {productTransactionExportLog?.running_number}
  //                           </a>
  //                         </div>
  //                       </div>
  //                     </TableCell>
  //                     <TableCell>
  //                       <div className="my-auto">{_order2?.type_edit}</div>
  //                     </TableCell>
  //                     <TableCell>
  //                       <div className="my-auto">{`${dayjs(
  //                         _order2?.modify_date,
  //                       ).format('D MMM BBBB ')} : ${dayjs(
  //                         _order2?.modify_date,
  //                       ).format('HH:mm')}`}</div>
  //                     </TableCell>
  //                     <TableCell>
  //                       {_order2?.modify_employee?.firstname}
  //                     </TableCell>
  //                   </TableRow>
  //                 ),
  //               )
  //             ) : (
  //               <TableRow key={''}>
  //                 <TableCell colSpan={6}>
  //                   <div className="text-center">ไม่มีรายการ</div>
  //                 </TableCell>
  //               </TableRow>
  //             )}
  //           </TableBody>
  //         </Table>
  //       </TableContainer>
  //     </Paper>
  //   </div>
  // );

  const renderShowProductList = () => (
    <Card className="p-6 ">{displayProduct()}</Card>
  );

  // const renderShowOrderHistory = () => (
  //   <Card className="p-6 ">{displayOrderHistory()}</Card>
  // );

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (
    productTransactionExportLog.isLoading ||
    productTransactionExportLog.rows
  ) {
    return <Loading />;
  }
  if (
    !productTransactionExportLog.isLoading &&
    productTransactionExportLog.isCompleted
  ) {
    return (
      <div>
        {renderTitle()}
        <div className="flex flex-row justify-start pb-4">
          <div>
            <BackButton />
          </div>
          <div className="text-lg font-semibold ml-6">
            {productTransactionExportLog.reserve_order
              ? 'รายละเอียดการจอง'
              : 'รายละเอียดการส่งออก'}
          </div>
        </div>
        <div>
          <Card className="p-4 ">
            <div className="flex flex-wrap divide-y">
              <div className="w-full py-4 text-lg font-semibold ">
                {'รายละเอียด'}
              </div>
              <div className="w-1/2 py-4 px-2 font-semibold ">
                {'หมายเลขเอกสาร'}
              </div>
              <div className="w-1/2 py-4 ">{`${
                productTransactionExportLog?.order_id?.prefix +
                  productTransactionExportLog?.order_id?.running_number || '-'
              }`}</div>
              <div className="w-1/2 py-4 px-2 font-semibold ">{'คู่ค้า'}</div>
              <div className="w-1/2 py-4 ">{`${
                productTransactionExportLog?.customer?.name || '-'
              }`}</div>
              <div className="w-1/2 py-4 px-2 font-semibold ">
                {'ประเภทคู่ค้า'}
              </div>
              <div className="w-1/2 py-4 ">
                {`${productTransactionExportLog?.customer?.type?.name || '-'}`}
              </div>
              <div className="w-1/2 py-4 px-2 font-semibold ">
                {'ผู้จองสินค้า'}
              </div>
              <div className="w-1/2 py-4 ">
                {productTransactionExportLog?.reserve_employee || ''}
              </div>
              <div className="w-1/2 py-4 px-2 font-semibold ">
                {'ผู้จัดสินค้า'}
              </div>
              <div className="w-1/2 py-4 ">{`${productTransactionExportLog?.export_employee?.firstname} ${productTransactionExportLog?.export_employee?.lastname}`}</div>
              <div className="w-1/2 py-4 px-2 font-semibold ">
                {'ผู้ส่งออกสินค้า'}
              </div>
              <div className="w-1/2 py-4 ">{`${
                productTransactionExportLog?.export_employee_real?.firstname ||
                ''
              } ${
                productTransactionExportLog?.export_employee_real?.lastname ||
                ''
              }`}</div>
              <div className="w-1/2 py-4 px-2 font-semibold ">
                {'ผู้รับสินค้า'}
              </div>
              <div className="w-1/2 py-4 ">{`${
                productTransactionExportLog?.export_employee_real_string || ''
              }`}</div>
              <div className="w-1/2 py-4 px-2 font-semibold ">
                {'หมายเลขบิล'}
              </div>
              <div className="w-1/2 py-4 ">
                {`${productTransactionExportLog?.bill_number}` || '-'}
              </div>
              <div className="w-1/2 py-4 px-2 font-semibold ">
                {'หมายเลขโครงการ'}
              </div>
              <div className="w-1/2 py-4 ">
                {`${productTransactionExportLog?.project_number}` || '-'}
              </div>
              <div className="w-1/2 py-4 px-2 font-semibold ">
                {'วันที่จัดสินค้า'}
              </div>
              <div className="w-1/2 py-4 ">{`${dayjs(
                productTransactionExportLog?.createdAt,
              ).format('D MMM BBBB ')}`}</div>
              {productTransactionExportLog.reserve_order ? (
                <>
                  <div className="w-1/2 py-4 px-2 font-semibold ">
                    {'วันที่จอง'}
                  </div>
                  <div className="w-1/2 py-4 ">{`${dayjs(
                    productTransactionExportLog?.reserve_date,
                  ).format('D MMM BBBB ')}`}</div>
                  <div className="w-1/2 py-4 px-2 font-semibold ">
                    {'วันที่หมดอายุการจอง'}
                  </div>
                  <div className="w-1/2 py-4 ">{`${dayjs(
                    productTransactionExportLog?.reserve_expire_date,
                  ).format('D MMM BBBB ')}`}</div>
                </>
              ) : (
                <>
                  <div className="w-1/2 py-4 px-2 font-semibold ">
                    {'วันที่ส่งออก'}
                  </div>
                  <div className="w-1/2 py-4 ">{`${
                    productTransactionExportLog?.status_export === 'SUCCESS'
                      ? dayjs(
                          productTransactionExportLog?.real_export_date,
                        ).format('D MMM BBBB ')
                      : '-'
                  }`}</div>
                </>
              )}

              <div className="w-1/2 py-4 px-2 font-semibold ">
                {'วันที่สร้างรายการ'}
              </div>
              <div className="w-1/2 py-4 ">{`${dayjs(
                productTransactionExportLog?.createAt,
              ).format('D MMM BBBB ')}`}</div>
              {productTransactionExportLog?.remark ? (
                <>
                  <div className="w-1/2 py-4 px-2 font-semibold ">
                    {'หมายเหตุ'}
                  </div>
                  <div className="w-1/2 py-4 ">{`${productTransactionExportLog?.remark}`}</div>
                </>
              ) : (
                <></>
              )}
              {productTransactionExportLog?.images ? (
                <>
                  <div className="w-full py-4 px-2 font-semibold ">รูปภาพ</div>
                  <div className="w-full flex">
                    {_.map(
                      productTransactionExportLog.images,
                      (eachImage, index) => (
                        <div key={index} className="">
                          <img
                            key={index}
                            src={eachImage?.url}
                            className="w-64 my-auto "
                          />
                          <div className="my-4 flex justify-center  "></div>
                        </div>
                      ),
                    )}
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          </Card>
          {productTransactionExportLog?.orders ? (
            <>
              <div className="w-full py-4 px-2 font-semibold ">
                {'รายการสินค้า'}
              </div>
              {renderShowProductList()}
            </>
          ) : (
            <></>
          )}

          {/* {productTransactionExportLog?.producttransactionlogexports ? (
            <>
              <div className="w-full py-4 px-2 font-semibold ">
                {'ประวัติการแก้ไข'}
              </div>
              {renderShowOrderHistory()}
            </>
          ) : (
            <></>
          )} */}
        </div>
      </div>
    );
  }
  return <Error />;
}

DetailExportLog.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

DetailExportLog.defaultProps = {
  title: '',
  subtitle: '',
};
