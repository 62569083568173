import {
  BOOKING_ALL,
  BOOKING_GET,
  BOOKING_DEL,
  BOOKING_PUT,
  BOOKING_POST,
  BOOKING_LOADING,
  BOOKING_ERROR,
} from '../../actions/types';

const initialState = {
  booking: null,
  isLoading: true,
  isCompleted: true,
};
// eslint-disable-next-line func-names
export default function (state = initialState, action) {
  switch (action.type) {
    case BOOKING_LOADING:
      return { isLoading: true, isCompleted: true };
    case BOOKING_ERROR:
      return { isLoading: false, isCompleted: false, ...action.payload };
    case BOOKING_ALL:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case BOOKING_GET:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case BOOKING_POST:
      return { isLoading: false, isCompleted: true };
    case BOOKING_PUT:
      return { isLoading: false, isCompleted: true };
    case BOOKING_DEL:
      return { isLoading: false, isCompleted: true };
    default:
      return state;
  }
}
