import React from 'react';
import { useFieldArray } from 'react-hook-form';

import _ from 'lodash';

import {
  Button,
  TextField,
  InputAdornment,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Checkbox,
} from '@mui/material';

import { Trash2 as Delete } from 'react-feather';

export default function nestedDiscount({ nestIndex, control, Controller }) {
  const { fields, remove, append } = useFieldArray({
    control,
    name: `wholesaleList[${nestIndex}].discount_array`,
  });
  return (
    <div className="my-1">
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size={'small'}>
            <colgroup>
              <col width="11%" />
              <col width="24%" />
              <col width="11%" />
              <col width="12%" />
              <col width="12%" />
              <col width="12%" />
              <col width="14%" />
            </colgroup>
            <TableHead>
              <TableRow className="bg-yellow-100">
                <TableCell>
                  <div className="font-bold py-1">ลำดับที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold py-1">จำนวนการซื้อ</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold py-1 text-center">ลัง/กล่อง</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold py-1">ลดเพิ่ม</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold py-1">ลดตาม</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold py-1">ลดตาม</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold py-1">ดำเนินการ</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(fields) ? (
                _.map(fields, (item, index) => (
                  <TableRow
                    key={item.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <div className="py-1">{`${index + 1}`}</div>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <div className="py-1">
                        <Controller
                          name={`wholesaleList[${nestIndex}].discount_array[${index}].quantity`}
                          control={control}
                          render={({ field }) => (
                            <TextField
                              size="small"
                              fullWidth
                              type="number"
                              label="จำนวนการซื้อ"
                              {...field}
                            />
                          )}
                        />
                      </div>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <div className="py-1">
                        <Controller
                          name={`wholesaleList[${nestIndex}].discount_array[${index}].whole_sale`}
                          control={control}
                          render={({ field }) => (
                            <Checkbox
                              {...field}
                              size="small"
                              defaultChecked={item?.whole_sale}
                            />
                          )}
                        />
                      </div>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <div className="py-1">
                        <Controller
                          name={`wholesaleList[${nestIndex}].discount_array[${index}].percent`}
                          control={control}
                          render={({ field }) => (
                            <TextField
                              size="small"
                              fullWidth
                              type="number"
                              label="ส่วนลด"
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    %
                                  </InputAdornment>
                                ),
                              }}
                              {...field}
                            />
                          )}
                        />
                      </div>
                    </TableCell>

                    <TableCell component="th" scope="row">
                      <div className="py-1">
                        <Controller
                          name={`wholesaleList[${nestIndex}].discount_array[${index}].follow_percent1`}
                          control={control}
                          render={({ field }) => (
                            <TextField
                              size="small"
                              fullWidth
                              type="number"
                              label="ลดตาม"
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    %
                                  </InputAdornment>
                                ),
                              }}
                              {...field}
                            />
                          )}
                        />
                      </div>
                    </TableCell>

                    <TableCell component="th" scope="row">
                      <div className="py-1">
                        <Controller
                          name={`wholesaleList[${nestIndex}].discount_array[${index}].follow_percent2`}
                          control={control}
                          render={({ field }) => (
                            <TextField
                              size="small"
                              fullWidth
                              type="number"
                              label="ลดตาม"
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    %
                                  </InputAdornment>
                                ),
                              }}
                              {...field}
                            />
                          )}
                        />
                      </div>
                    </TableCell>

                    <TableCell component="th" scope="row">
                      <Button
                        size="sm"
                        variant="outlined"
                        color="error"
                        onClick={() => {
                          remove(index);
                        }}
                      >
                        <Delete size={16} color="red" />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <div className="text-center">ไม่มีข้อมูล</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <div className="flex justify-between gap-1 py-2">
        <Button
          size="small"
          variant="outlined"
          onClick={() => {
            append({
              quantity: 0,
              percent: 0,
              follow_percent1: 0,
              follow_percent2: 0,
              whole_sale: false,
            });
          }}
        >
          เพิ่ม
        </Button>
      </div>
    </div>
  );
}
