/* eslint-disable function-paren-newline */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  List,
  ListItem,
  ListItemText,
  TextField,
  ButtonGroup,
} from '@mui/material';
import PropTypes from 'prop-types';
import _ from 'lodash';

import * as actions from '../../redux/actions';
import { WMS } from '../../utils/Proptypes';

const AddProductDialog = ({
  open,
  handleClose,
  selectedProduct,
  handleAddProduct,
  handleRemoveProduct,
  filterProduct,
}) => {
  const dispatch = useDispatch();
  const products = useSelector((state) => state.product);
  const [searchTerm, setSearchTerm] = useState('');
  const [name, setName] = useState('');
  const [page, setPage] = useState(1);
  const [rerender, setRerender] = useState(false);
  const [size] = useState(10);
  const [pageTotal, setPageTotal] = useState(products?.lastPage);
  // Fetching Product
  useEffect(() => {
    const fetchProduct = async () => {
      try {
        await dispatch(actions.productAll({ name, size, page }));
      } catch (error) {
        console.error('Cannot Fetch Product');
      }
    };

    if (open) {
      fetchProduct();
    }
    return () => {};
  }, [open, name, size, page]);

  // Set Total Page
  useEffect(() => {
    setPageTotal(products?.lastPage);
    return () => {};
  }, [products]);

  // Finding Product
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchTerm);
      setPage(1);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  // Show Product list to search and add
  const renderProductList = () => {
    if (_.isEmpty(products?.rows)) {
      return (
        <div>
          <p className="text-center"> ไม่พบสินค้าที่ต้องการ</p>
        </div>
      );
    }
    console.log('products in dialog ', products?.rows);

    // eslint-disable-next-line no-confusing-arrow
    return _.map(products?.rows, (eachProduct, index) =>
      // eslint-disable-next-line implicit-arrow-linebreak
      eachProduct.product_type?.name === filterProduct?.name ? (
        <ListItem key={index}>
          <ListItemText primary={eachProduct?.name} />

          <Button
            key={`BTI${index}`}
            onClick={() => {
              handleAddProduct(eachProduct);
              setRerender(!rerender);
            }}
            variant="contained"
          >
            เพิ่ม
          </Button>
        </ListItem>
      ) : (
        ''
      ),
    );
  };

  // Show Product that already added
  const renderAddedProductShow = () => (
    <List>
      {Array.isArray(selectedProduct) &&
        selectedProduct.map((eachProduct, index) => (
          <ListItem key={`SEP${index}`}>
            <ListItemText primary={eachProduct?.productOrder?.name} />

            <Button
              onClick={() => {
                handleAddProduct(eachProduct?.productOrder);
                setRerender(!rerender);
              }}
            >
              เพิ่ม
            </Button>
            <div className="mx-2">{eachProduct?.amount}</div>
            <Button
              onClick={() => {
                handleRemoveProduct(eachProduct?.productOrder);
                setRerender(!rerender);
              }}
            >
              ลบ
            </Button>
          </ListItem>
        ))}
    </List>
  );

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="form-dialog-title">
        <div className="flex justify-between">
          <div>ค้นหาสินค้า</div>
          <Button onClick={handleClose}>ปิด</Button>
        </div>
      </DialogTitle>

      <DialogContent>
        {renderAddedProductShow()}
        <TextField
          label="ชื่อสินค้า"
          defaultValue={searchTerm}
          onChange={(event) => setSearchTerm(event.target.value)}
          variant="standard"
          fullWidth
        />
        <List>{renderProductList()}</List>
        <div className="flex justify-end">
          <ButtonGroup variant="contained">
            <Button
              disabled={page === 1}
              variant="text"
              color="inherit"
              onClick={() => {
                handleChangePage({}, page - 1);
              }}
            >
              <i className="fas fa-chevron-left text-sm" />
            </Button>
            <Button
              disabled={page === pageTotal}
              color="inherit"
              variant="text"
              onClick={() => {
                handleChangePage({}, page + 1);
              }}
            >
              <i className="fas fa-chevron-right text-sm" />
            </Button>
          </ButtonGroup>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default AddProductDialog;

AddProductDialog.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  selectedProduct: PropTypes.arrayOf(
    PropTypes.shape({
      product: PropTypes.oneOfType([WMS.Product, PropTypes.string]),
      amount: PropTypes.number,
    }),
  ),
  handleAddProduct: PropTypes.func.isRequired,
  handleRemoveProduct: PropTypes.func.isRequired,
  filterProduct: PropTypes.object,
};

AddProductDialog.defaultProps = {
  open: false,
};
