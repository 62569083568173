/* eslint-disable arrow-body-style */
/* eslint-disable operator-linebreak */
import React, { useEffect, useState } from 'react';
import { TextField, Button, Tooltip, Autocomplete } from '@mui/material';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { useForm, useFieldArray } from 'react-hook-form';
import CreateProduct from '../../Modal/CreateProduct';

import { AddSerialNumberDialog, AddOptionDialog } from '../../Dialogs';

import { SPM } from '../../../utils/Proptypes';

const resetValues = {
  // product: null,
  // price: '',
  amount: '',
  project_number: '',
  // cost_price1: '',
  // cost_price: '',
};

export const AddProductForm = ({
  errors,
  Controller,
  product,
  filterProduct,
  setFilterProduct,
  productList,
  setProductList,
  setReload,
  reload,
  selectWarehouse,
}) => {
  const [isModalCreateProductOpen, setIsModalCreateProductOpen] = useState(
    false,
  );
  const handleOpen = () => setIsModalCreateProductOpen(true);
  const handleClose = () => {
    setIsModalCreateProductOpen(false);
  };
  const { getValues, control, reset } = useForm({
    mode: 'onBlur',
  });

  const NewProduct = _.map(product, (_product, index) => ({
    index: index + 1,
    ..._product,
  }));

  const checkProduct = (data) => {
    // eslint-disable-next-line no-underscore-dangle
    const findproduct = _.find(product, { id: data._id });
    setFilterProduct(findproduct);
  };
  useEffect(() => () => {}, [reload]);

  const handleAddProduct = () => {
    console.log('product', getValues('product'));
    if (!selectWarehouse) {
      alert('กรุณาเลือกคลังสินค้า');
    } else if (getValues('amount') === '' || !getValues('product')) {
      alert('กรุณาใส่ข้อมูลให้ครบถ้วน');
    } else {
      const data = {
        product: filterProduct,
        amount: getValues('amount'),
        warehouse: selectWarehouse,
        project_number: getValues('project_number'),
      };
      console.log(data);
      productList.push(data);
      setProductList(productList);
      reset(resetValues);
      setReload(!reload);
    }
  };

  const filterOptions = (options, { inputValue }) => {
    return options.filter((option) => {
      const text = `${option.name}${option.type_code}${
        option?.model_name || ''
      }`.toLowerCase();
      return text.includes(inputValue.toLowerCase());
    });
  };

  return (
    <div>
      <CreateProduct
        isOpen={isModalCreateProductOpen}
        handleOnClose={handleClose}
      />
      <div className="flex flex-row flex-wrap">
        <div className="w-full px-1 py-2 flex ">
          <div className="flex-grow">
            <Controller
              name={'product'}
              control={control}
              rules={{ required: false }}
              render={({ field }) => (
                <Autocomplete
                  size={'small'}
                  disableClearable
                  {...field}
                  onChange={(e, newValue) => {
                    field.onChange(newValue);
                    checkProduct(newValue);
                    setReload(!reload);
                  }}
                  options={NewProduct}
                  filterOptions={filterOptions} // ใช้ฟังก์ชันการกรองที่กำหนดเอง
                  getOptionLabel={(option) =>
                    // eslint-disable-next-line implicit-arrow-linebreak
                    `${option?.index} : ${option?.name} ( ${
                      option?.model_name || ''
                    } )`
                  }
                  disabled={!selectWarehouse}
                  autoHighlight
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="สินค้า"
                      onKeyDown={(event) => {
                        // ใช้งานเพื่อตรวจสอบปัญหาที่อาจเกิดขึ้นกับการจัดการ onKeyDown อื่นๆ
                        if (
                          event.key === 'Enter' &&
                          params.InputProps.onKeyDown
                        ) {
                          params.InputProps.onKeyDown(event);
                        }
                      }}
                    />
                  )}
                />
              )}
            />
          </div>
          <div className="my-auto pl-1  flex-none">
            <Tooltip
              title="เพิ่มสินค้ากรณีที่ยังไม่มีข้อมูลในระบบ"
              placement="bottom"
            >
              <Button variant="outlined" onClick={handleOpen}>
                เพิ่มสินค้า
              </Button>
            </Tooltip>
          </div>
        </div>
        <div className="w-full px-1 py-2">
          <Controller
            name={'amount'}
            control={control}
            rules={{ required: false }}
            defaultValue={''}
            render={({ field }) => (
              <TextField
                {...field}
                label="จำนวน"
                fullWidth
                type="number"
                size={'small'}
                disabled={!selectWarehouse}
                // eslint-disable-next-line react/prop-types
                helperText={errors.inventory && 'กรุณาใส่ข้อมูล'}
              />
            )}
          />
        </div>
        <div className="w-full px-1 py-1">
          <Controller
            name={'project_number'}
            control={control}
            defaultValue={''}
            rules={{ required: false }}
            render={({ field }) => (
              <TextField
                {...field}
                label="รหัสโครงการ"
                fullWidth
                size={'small'}
                disabled={!selectWarehouse}
                helperText={errors.remark && 'กรุณาใส่ข้อมูล'}
              />
            )}
          />
        </div>
        <div className="w-full px-1 py-2">
          <Button
            variant="outlined"
            className="w-full"
            onClick={() => handleAddProduct()}
          >
            เพิ่ม
          </Button>
        </div>
      </div>
    </div>
  );
};

AddProductForm.propTypes = {
  errors: PropTypes.shape({
    product_transaction_type: PropTypes.object,
    orderNumber: PropTypes.billNumber,
    customer: PropTypes.array,
    source: PropTypes.object,
    quantity: PropTypes.object,
    price: PropTypes.object,
    product_stock_lots: PropTypes.object,
    warehouse: PropTypes.object,
    product_type: PropTypes.array,
    productionDate: PropTypes.object,
    expirationDate: PropTypes.object,
    receiptDate: PropTypes.object,
    sellDate: PropTypes.object,
    remark: PropTypes.object,
    recipientName: PropTypes.object,
    sellName: PropTypes.object,
    trackingNumber: PropTypes.object,
    sellPrice: PropTypes.object,
    shippingCost: PropTypes.object,
    discount: PropTypes.object,
    payment: PropTypes.payment,
  }),
  product: PropTypes.array,
  productType: PropTypes.array,
  productTransactionType: PropTypes.array,
  order: PropTypes.oneOf([SPM.Order, SPM.OrderArray]),
  Controller: PropTypes.func,
  control: PropTypes.object,
  type: PropTypes.object,
  setType: PropTypes.func,
  selectProduct: PropTypes.array,
  setSelectProduct: PropTypes.func,
  filterProduct: PropTypes.object,
  setFilterProduct: PropTypes.func,
  customers: PropTypes.array,
  departments: PropTypes.object,
  watch: PropTypes.func.isRequired,
  products: PropTypes.object,
  selectedProducts: PropTypes.array,
  setSelectedProducts: PropTypes.func,
  employees: PropTypes.array,
  productTypes: PropTypes.object,
  productList: PropTypes.array,
  setProductList: PropTypes.func,
  reload: PropTypes.bool,
  setReload: PropTypes.func,
  selectSerial: PropTypes.array,
  setSelectSerial: PropTypes.func,
  selectOption: PropTypes.array,
  setSelectOption: PropTypes.func,
  selectWarehouse: PropTypes.object,
};

AddProductForm.defaultProps = {
  product: null,
  productType: null,
  productTransactionType: null,
  customer: null,
  department: null,
  me: null,
  type: null,
  selectProduct: null,
  filterProduct: null,
};

export default AddProductForm;
