/* eslint-disable import/extensions */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { useHistory } from 'react-router-dom';
// eslint-disable-next-line object-curly-newline
import { useForm } from 'react-hook-form';
import { Button, Card, FormControlLabel, Switch } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';

import PropTypes from 'prop-types';
import _ from 'lodash';

import * as actions from '../../redux/actions';
import Loading from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';
// eslint-disable-next-line import/no-unresolved
// eslint-disable-next-line import/extensions
// eslint-disable-next-line import/no-unresolved
// import RichtextEditor from '../../components/Richtext/RichtextEditor';
import ImageUpload from '../../components/ImageUpload/ImageUpload';

const MainSite = ({ title, subtitle }) => {
  const dispatch = useDispatch();
  const setting = useSelector((state) => state.setting);
  const [uploadedImage1, setUploadedImage1] = useState(setting?.banner);
  // const history = useHistory();

  console.log('setting', setting);

  const [createLoading, setCreateLoading] = useState(false);
  const [checkBanner, setCheckBanner] = useState(setting?.banner_status);
  const [checkBrand, setCheckBrand] = useState(setting?.brand_status);
  const [checkProductType, setCheckProductType] = useState(
    setting?.product_type_status,
  );

  const handleChangeCheckBanner = async (event) => {
    setCheckBanner(event.target.checked);
    try {
      await dispatch(
        actions.settingPut(setting?.id, {
          ...setting,
          promptpay: {
            accountName: setting?.promptpay?.accountName,
            accountNumber: setting?.promptpay?.accountNumber,
            available: setting?.promptpay?.available,
            QrCode: setting?.promptpay?.QrCode?.id,
            ShowQrCode: setting?.promptpay?.ShowQrCode,
          },
          banner_status: event.target.checked,
          images: _.map(uploadedImage1, (image) => ({
            // eslint-disable-next-line no-underscore-dangle
            id: image.image_id,
            image: image.data_url || image.image.url,
          })),
        }),
      );
      await dispatch(actions.settingAll());
    } catch (error) {
      console.error(error);
    }
  };

  const handleChangeCheckProductType = async (event) => {
    setCheckProductType(event.target.checked);
    try {
      await dispatch(
        actions.settingPut(setting?.id, {
          ...setting,
          promptpay: {
            accountName: setting?.promptpay?.accountName,
            accountNumber: setting?.promptpay?.accountNumber,
            available: setting?.promptpay?.available,
            QrCode: setting?.promptpay?.QrCode?.id,
            ShowQrCode: setting?.promptpay?.ShowQrCode,
          },
          product_type_status: event.target.checked,
          images: _.map(uploadedImage1, (image) => ({
            // eslint-disable-next-line no-underscore-dangle
            id: image.image_id,
            image: image.data_url || image.image.url,
          })),
        }),
      );
      await dispatch(actions.settingAll());
    } catch (error) {
      console.error(error);
    }
  };

  const handleChangeCheckBrand = async (event) => {
    setCheckBrand(event.target.checked);
    try {
      await dispatch(
        actions.settingPut(setting?.id, {
          ...setting,
          promptpay: {
            accountName: setting?.promptpay?.accountName,
            accountNumber: setting?.promptpay?.accountNumber,
            available: setting?.promptpay?.available,
            QrCode: setting?.promptpay?.QrCode?.id,
            ShowQrCode: setting?.promptpay?.ShowQrCode,
          },
          brand_status: event.target.checked,
          images: _.map(uploadedImage1, (image) => ({
            // eslint-disable-next-line no-underscore-dangle
            id: image.image_id,
            image: image.data_url || image.image.url,
          })),
        }),
      );
      await dispatch(actions.settingAll());
    } catch (error) {
      console.error(error);
    }
  };

  console.log('setting', setting);

  useEffect(() => {
    dispatch(actions.settingAll());
    return () => {};
  }, []);

  useEffect(() => {
    setUploadedImage1(setting?.banner);
    setCheckBanner(setting?.banner_status);
    setCheckBrand(setting?.brand_status);
    return () => {};
  }, [setting]);

  const { handleSubmit } = useForm();

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const onSubmit = async (data, event) => {
    setCreateLoading(true);
    try {
      const preprocessedData = {
        ...setting,
        promptpay: {
          accountName: setting?.promptpay?.accountName,
          accountNumber: setting?.promptpay?.accountNumber,
          available: setting?.promptpay?.available,
          QrCode: setting?.promptpay?.QrCode?.id,
          ShowQrCode: setting?.promptpay?.ShowQrCode,
        },
        images: _.map(uploadedImage1, (image) => ({
          // eslint-disable-next-line no-underscore-dangle
          id: image.image_id,
          image: image.data_url || image.image.url,
        })),
      };
      console.log('preprocessedData', preprocessedData);
      event.preventDefault();

      await dispatch(actions.settingPut(setting?.id, preprocessedData));

      setCreateLoading(false);
      alert('สำเร็จ');
      await dispatch(actions.settingAll());
    } catch (error) {
      console.error(error);
    }
  };
  if (setting.isLoading || createLoading) {
    return <Loading />;
  }
  if (!setting.isLoading && setting.isCompleted) {
    return (
      <div>
        <div className="flex justify-between">
          <div>{renderTitle()}</div>
          {/* <div className="mt-6">{renderAddButton()}</div> */}
        </div>
        <Card className="p-6">
          {/* <div className="py-2 font-bold">ชื่อบริษัท</div> */}

          <form onSubmit={handleSubmit(onSubmit)}>
            {/* <MainSiteForm /> */}
            <div className="">
              <div className="mb-2">
                <FormControlLabel
                  control={
                    <Switch
                      checked={checkBanner}
                      onChange={handleChangeCheckBanner}
                    />
                  }
                  label="ปิด-เปิด ( สไลค์แบนเนอร์ )"
                />
              </div>

              <div className="py-2 font-bold">
                สไลค์รูปภาพ 1 <small>( ใส่รูปภาพได้สูงสุด 10 รูป )</small>
              </div>
              <div className="w-full  px-1 border-4 rounded-sm">
                <ImageUpload
                  images={uploadedImage1}
                  setImages={setUploadedImage1}
                  preview_size="250"
                  maxNumber={10}
                />
              </div>
            </div>
            <div>
              <div className="my-2">
                <FormControlLabel
                  control={
                    <Switch
                      checked={checkBrand}
                      onChange={handleChangeCheckBrand}
                    />
                  }
                  label="ปิด-เปิด ( การแสดงพาร์ทเนอร์ )"
                />
              </div>
            </div>
            <div>
              <div className="my-2">
                <FormControlLabel
                  control={
                    <Switch
                      checked={checkProductType}
                      onChange={handleChangeCheckProductType}
                    />
                  }
                  label="ปิด-เปิด ( การแสดงประเภทสินค้า )"
                />
              </div>
            </div>
            <div className="flex flex-row justify-end gap-1 py-4">
              <Button
                variant="contained"
                type="submit"
                startIcon={<SaveIcon />}
              >
                บันทึก
              </Button>
            </div>
          </form>
        </Card>
      </div>
    );
  }
  return <Error message={setting?.message} />;
};

MainSite.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

MainSite.defaultProps = {
  title: '',
  subtitle: '',
};

export default MainSite;
