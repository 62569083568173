import React from 'react';
import PropTypes from 'prop-types';

import _ from 'lodash';
import { Link } from 'react-router-dom';
import dipromLogo from '../../assets/images/diprom.png';
import accessRight from '../../utils/functions/accessRight';

function Diprom4({ me, availableModule, setting, menuList }) {
  return (
    <div className="min-h-screen bg-gradient-to-t from-green-400 to-white">
      <div className="lg:px-32 mt-24 text-center lg:text-left">
        <div className=" flex-wrap items-center my-10 hidden md:flex">
          <div className="w-full lg:w-1/2 pr-10">
            <div className="flex flex-wrap w-full justify-end items-center">
              <div className="w-full lg:w-1/2 px-2">
                <div className="text-xl">{setting?.description}</div>
                <div className="text-xl font-semibold">
                  {setting?.owner?.name}
                </div>
              </div>
              <div className="w-full lg:w-1/3 items-center flex justify-center lg:justify-start">
                <img
                  src={setting?.logo?.url}
                  alt="company-logo"
                  className="h-32"
                />
              </div>
            </div>
          </div>
          <div className="w-full lg:w-1/2">
            <div className="flex flex-wrap items-center">
              <div className="w-full lg:w-1/4  flex justify-center lg:justify-start">
                <div>
                  <img src={dipromLogo} alt="diprom" className="h-32" />
                </div>
              </div>
              <div className="w-full lg:w-3/4">
                <div className="text-lg my-2">
                  <b>สนับสนุนการพัฒนาระบบโดย</b> <br /> {setting?.sponsor?.name}
                </div>{' '}
                <div className="">ดีพร้อมโต โต้ได้โตไว โตไกล โตให้ยั่งยืน</div>
                <div>
                  <a
                    href="https://www.diprom.go.th"
                    target="_blank"
                    rel="noreferrer"
                  >
                    www.diprom.go.th
                  </a>
                </div>
                <div className="my-2 text-xs">
                  พัฒนาระบบโดย ศูนย์วิจัยระบบอัตโนมัติอัจฉริยะ คณะวิศวกรรมศาสตร์
                  มหาวิทยาลัยสงขลานครินทร์
                </div>
              </div>
            </div>{' '}
          </div>
        </div>

        <div className="container mr-auto ml-auto">
          {_.map(menuList, (_menu, index) => {
            if (accessRight(me, _menu.moduleName, 0, availableModule)) {
              return (
                <div className="py-4  px-4" key={index}>
                  <Link to={_menu.link}>
                    <div
                      className={`  shadow rounded text-center  py-8 px-4 lg:h-40  transition-transform transform hover:scale-105 ${_menu.extendedStyle}`}
                    >
                      <div className="flex justify-center">{_menu.icon}</div>
                      <p className=" text-white mt-4 ">{_menu.title}</p>
                    </div>
                  </Link>
                </div>
              );
            }
          })}
          {/* <ButtonMenuList me={me} availableModule={availableModule} /> */}
        </div>
      </div>
    </div>
  );
}

export default Diprom4;

Diprom4.propTypes = {
  me: PropTypes.object,
  availableModule: PropTypes.arrayOf(PropTypes.object),
  setting: PropTypes.object,
  menuList: PropTypes.arrayOf(PropTypes.object),
};
