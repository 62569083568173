import {
  MATERIALTYPES_ALL,
  MATERIALTYPES_GET,
  MATERIALTYPES_DEL,
  MATERIALTYPES_PUT,
  MATERIALTYPES_POST,
  MATERIALTYPES_LOADING,
  MATERIALTYPES_ERROR,
} from '../types';

import api from '../../../utils/functions/api';

export const materialTypeCreate = (payload) => async (dispatch) => {
  try {
    dispatch({ type: MATERIALTYPES_LOADING });
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/material-type`,
      {
        ...payload,
      },
    );
    dispatch({ type: MATERIALTYPES_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: MATERIALTYPES_ERROR });
    throw new Error(error);
  }
};

export const materialTypeAll = (params) => async (dispatch) => {
  try {
    const { name = '', size = 5, page = 1 } = params;
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/material-type?name=${name}&size=${size}&page=${page}`,
    );
    if (status === 200) {
      dispatch({ type: MATERIALTYPES_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: MATERIALTYPES_ERROR });
    throw new Error(error);
  }
};

export const materialTypeGet = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/material-type/${id}`,
    );
    if (status === 200) {
      dispatch({ type: MATERIALTYPES_GET, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: MATERIALTYPES_ERROR });
    throw new Error(error);
  }
};

export const materialTypePut = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: MATERIALTYPES_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/material-type/${id}`,
      payload,
    );
    dispatch({ type: MATERIALTYPES_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: MATERIALTYPES_ERROR });
    throw new Error(error);
  }
};
export const materialTypeDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: MATERIALTYPES_LOADING });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/material-type/${id}`,
    );
    dispatch({ type: MATERIALTYPES_DEL, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: MATERIALTYPES_ERROR });
    throw new Error(error);
  }
};
