/* eslint-disable implicit-arrow-linebreak */
import pdfMake from 'addthaifont-pdfmake/build/pdfmake';
import 'addthaifont-pdfmake/build/vfs_fonts';
import _ from 'lodash';
import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import { BarcodeGenerator } from '../Others';

// import currencyFormatterTH from '../../utils/functions/currencyFormatterTH';

dayjs.locale('th');
dayjs.extend(buddhistEra);
dayjs.extend(LocalizedFormat);

pdfMake.fonts = {
  Sarabun: {
    normal: 'Sarabun-Light.ttf',
    bold: 'Sarabun-Regular.ttf',
    italics: 'Sarabun-LightItalic.ttf',
    bolditalics: 'Sarabun-Italic.ttf',
  },
};
// eslint-disable-next-line import/prefer-default-export
export const BarcodeSticker = (data, info) => {
  console.log('data in print', data);

  const barcodeImage = (word) => {
    const barcodeSVGWird = BarcodeGenerator(word);
    return {
      svg: barcodeSVGWird,
      width: 340,
      height: 120,
    };
  };

  const palletData = [
    {
      stack: [
        {
          alignment: 'center',
          margin: [0, 0, 0, 0],
          text: data?.model_name || 'error',
          bold: true,
          fontSize: 16,
        },
        {
          alignment: 'center',
          ...barcodeImage(data?.type_code || '0111111111'),
        },
      ],
    },
    {
      stack: [
        {
          alignment: 'center',
          margin: [0, 0, 0, 0],
          text: data?.model_name || 'error',
          bold: true,
          fontSize: 16,
        },
        {
          alignment: 'center',
          ...barcodeImage(data?.type_code || '0111111111'),
        },
      ],
    },
  ];

  const docDefinition = {
    pageSize: {
      width: 680,
      height: 'auto',
    },
    pageOrientation: 'portrait',
    pageMargins: [0, 0, 0, 0],
    defaultStyle: {
      font: 'Sarabun',
    },
    info: {
      title: 'ใบสติ๊กเกอร์',
    },
    content: {
      alignment: 'justify',
      columns: palletData,
    },
  };
  pdfMake.createPdf(docDefinition).print();
};
export default BarcodeSticker;
