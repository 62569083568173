import { Button } from '@mui/material';
import React from 'react';
import ImageUploading from 'react-images-uploading';
import _ from 'lodash';
import PropTypes from 'prop-types';

import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';

export default function ImageUpload(props) {
  const {
    images,
    setImages,
    previewSize,
    maxNumber = 1,
    title = 'อัพโหลดรูปภาพ',
    singleImage,
    // eslint-disable-next-line react/prop-types
    profile,
  } = props;
  const onChange = (imageList) => {
    setImages(imageList);
  };
  console.log('images', images);
  return (
    <ImageUploading
      multiple
      value={images}
      onChange={onChange}
      maxNumber={maxNumber}
      dataURLKey="data_url"
    >
      {({
        imageList,
        onImageUpload,
        // onImageRemoveAll,
        onImageUpdate,
        onImageRemove,
        // isDragging,
        dragProps,
      }) => (
        // write your building UI
        <div className="m-2">
          <div className="flex justify-center">
            {imageList.map((image, index) => (
              <div key={index} className="my-2 pt-2">
                <div
                  className="text-center bg-gray-500 text-white cursor-pointer py-1"
                  onClick={() => onImageRemove(index)}
                >
                  <i className="fas fa-window-close" aria-hidden="true"></i>
                </div>
                <img
                  src={image?.data_url || image?.url || image?.image?.url}
                  alt=""
                  width={previewSize}
                />
                <div
                  className="text-center bg-gray-500 text-white cursor-pointer py-1"
                  onClick={() => onImageUpdate(index)}
                >
                  แก้ไข
                </div>
              </div>
            ))}
          </div>
          <div className="flex justify-center">
            {_.size(imageList) ? (
              <>
                {profile === 1 ? (
                  ''
                ) : (
                  <>
                    {_.size(imageList) !== 1 && (
                      <div
                        className="text-center bg-gray-500 text-white p-5  cursor-pointer rounded-xl my-auto"
                        onClick={() => onImageUpload(0)}
                        {...dragProps}
                      >
                        <i className="fas fa-plus h-5 w-5"></i>
                      </div>
                    )}
                  </>
                )}
              </>
            ) : (
              <div className="my-auto">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={onImageUpload}
                  size="sm"
                  {...dragProps}
                  startIcon={<AddPhotoAlternateIcon />}
                >
                  {title}
                </Button>
              </div>
            )}
          </div>

          {/* <button onClick={onImageRemoveAll}>Remove all images</button> */}
        </div>
      )}
    </ImageUploading>
  );
}

ImageUpload.defaultProps = {
  previewSize: '250',
  maxNumber: 1,
};

ImageUpload.propTypes = {
  previewSize: PropTypes.string,
  images: PropTypes.array,
  setImages: PropTypes.func.isRequired,
  maxNumber: PropTypes.number,
  title: PropTypes.string,
  singleImage: PropTypes.bool,
};
