/* eslint-disable arrow-body-style */
/* eslint-disable operator-linebreak */
import React, { useEffect, useState } from 'react';
import { TextField, Button, Tooltip, Autocomplete } from '@mui/material';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { useForm, useFieldArray } from 'react-hook-form';
import CreateProduct from '../../Modal/CreateProduct';

import { AddSerialNumberDialog, AddOptionDialog } from '../../Dialogs';

import { SPM } from '../../../utils/Proptypes';

const resetValues = {
  product: '',
  // price: '',
  amount: '',
  // price1: '',
  // cost_price1: '',
  // cost_price: '',
};

export const AddProductEditForm = ({
  errors,
  Controller,
  product,
  filterProduct,
  setFilterProduct,
  productList,
  setProductList,
  setReload,
  reload,
  selectSerial,
  setSelectSerial,
  setSelectOption,
  selectOption,
}) => {
  const [isModalCreateProductOpen, setIsModalCreateProductOpen] = useState(
    false,
  );
  const handleOpen = () => setIsModalCreateProductOpen(true);
  const handleClose = () => {
    setIsModalCreateProductOpen(false);
  };
  const [isProductDialogOpen, setIsProductDialogOpen] = useState(false);
  const { getValues, control, reset, setValue } = useForm({
    mode: 'onBlur',
  });

  const { fields } = useFieldArray({
    control,
    name: 'optionList',
  });

  const NewProduct = _.map(product, (_product, index) => ({
    index: index + 1,
    ..._product,
  }));

  const checkProduct = (data) => {
    // eslint-disable-next-line no-underscore-dangle
    const findproduct = _.find(product, { id: data._id });
    setFilterProduct(findproduct);
  };
  useEffect(() => () => {}, [reload]);

  const handleCloseDialog = () => {
    try {
      setIsProductDialogOpen(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleAddProduct = () => {
    if (getValues('amount') === '' || !getValues('product')) {
      alert('กรุณาใส่ข้อมูลให้ครบถ้วน');
    } else {
      const data = {
        product: filterProduct,
        amount: getValues('amount'),
      };
      console.log(data);
      productList.push(data);
      setProductList(productList);
      reset(resetValues);
      setReload(!reload);
    }
  };

  return (
    <div>
      <CreateProduct
        isOpen={isModalCreateProductOpen}
        handleOnClose={handleClose}
      />
      <div className="flex flex-row flex-wrap">
        <div className="w-full px-1 py-2 flex ">
          <div className="flex-grow">
            <Controller
              name={'product'}
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Autocomplete
                  size={'small'}
                  disableClearable
                  {...field}
                  onChange={(e, newValue) => {
                    field.onChange(newValue);
                    checkProduct(newValue);
                    setReload(!reload);
                  }}
                  options={NewProduct}
                  getOptionLabel={(option) =>
                    // eslint-disable-next-line implicit-arrow-linebreak
                    `${option?.index} : ${option?.name} ${
                      option?.model_name && `( ${option?.model_name} )`
                    }`
                  }
                  required
                  renderInput={(params) => (
                    <TextField {...params} label="สินค้า" required />
                  )}
                />
              )}
            />
          </div>
          <div className="my-auto pl-1  flex-none">
            <Tooltip
              title="เพิ่มสินค้ากรณีที่ยังไม่มีข้อมูลในระบบ"
              placement="bottom"
            >
              <Button variant="outlined" onClick={handleOpen}>
                เพิ่มสินค้า
              </Button>
            </Tooltip>
          </div>
        </div>
        <div className="w-full px-1 py-2">
          <Controller
            name={'amount'}
            control={control}
            rules={{ required: false }}
            defaultValue={''}
            render={({ field }) => (
              <TextField
                {...field}
                label="จำนวน"
                fullWidth
                type="number"
                size={'small'}
                // eslint-disable-next-line react/prop-types
                helperText={errors.inventory && 'กรุณาใส่ข้อมูล'}
              />
            )}
          />
        </div>
        <div className="w-full px-1 py-2">
          <Button
            variant="outlined"
            className="w-full"
            onClick={() => handleAddProduct()}
          >
            เพิ่ม
          </Button>
        </div>
      </div>
    </div>
  );
};

AddProductEditForm.propTypes = {
  errors: PropTypes.shape({
    product_transaction_type: PropTypes.object,
    orderNumber: PropTypes.billNumber,
    customer: PropTypes.array,
    source: PropTypes.object,
    quantity: PropTypes.object,
    price: PropTypes.object,
    product_stock_lots: PropTypes.object,
    warehouse: PropTypes.object,
    product_type: PropTypes.array,
    productionDate: PropTypes.object,
    expirationDate: PropTypes.object,
    receiptDate: PropTypes.object,
    sellDate: PropTypes.object,
    remark: PropTypes.object,
    recipientName: PropTypes.object,
    sellName: PropTypes.object,
    trackingNumber: PropTypes.object,
    sellPrice: PropTypes.object,
    shippingCost: PropTypes.object,
    discount: PropTypes.object,
    payment: PropTypes.payment,
  }),
  product: PropTypes.array,
  productType: PropTypes.array,
  productTransactionType: PropTypes.array,
  order: PropTypes.oneOf([SPM.Order, SPM.OrderArray]),
  Controller: PropTypes.func,
  control: PropTypes.object,
  type: PropTypes.object,
  setType: PropTypes.func,
  selectProduct: PropTypes.array,
  setSelectProduct: PropTypes.func,
  filterProduct: PropTypes.object,
  setFilterProduct: PropTypes.func,
  customers: PropTypes.array,
  departments: PropTypes.object,
  watch: PropTypes.func.isRequired,
  products: PropTypes.object,
  selectedProducts: PropTypes.array,
  setSelectedProducts: PropTypes.func,
  employees: PropTypes.array,
  productTypes: PropTypes.object,
  productList: PropTypes.array,
  setProductList: PropTypes.func,
  reload: PropTypes.bool,
  setReload: PropTypes.func,
  selectSerial: PropTypes.array,
  setSelectSerial: PropTypes.func,
  selectOption: PropTypes.array,
  setSelectOption: PropTypes.func,
};

AddProductEditForm.defaultProps = {
  product: null,
  productType: null,
  productTransactionType: null,
  customer: null,
  department: null,
  me: null,
  type: null,
  selectProduct: null,
  filterProduct: null,
};

export default AddProductEditForm;
