import {
  COURSE_ALL,
  COURSE_GET,
  COURSE_DEL,
  COURSE_PUT,
  COURSE_POST,
  COURSE_LOADING,
  COURSE_ERROR,
} from '../types';

import api from '../../../utils/functions/api';

export const courseCreate = (payload) => async (dispatch) => {
  try {
    dispatch({ type: COURSE_LOADING });
    const { data } = await api.post(`${process.env.REACT_APP_API_URL}/course`, {
      ...payload,
    });
    dispatch({ type: COURSE_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: COURSE_ERROR });
    throw new Error(error);
  }
};

export const courseAll = (params) => async (dispatch) => {
  try {
    const { name = '', size = '', page = 1 } = params;
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/course?name=${name}&size=${size}&page=${page}`,
    );
    if (status === 200) {
      dispatch({ type: COURSE_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: COURSE_ERROR });
    throw new Error(error);
  }
};

export const courseGet = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/course/${id}`,
    );
    if (status === 200) {
      dispatch({ type: COURSE_GET, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: COURSE_ERROR });
    throw new Error(error);
  }
};

export const coursePut = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: COURSE_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/course/${id}`,
      payload,
    );
    dispatch({ type: COURSE_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: COURSE_ERROR });
    throw new Error(error);
  }
};
export const courseDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: COURSE_LOADING });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/course/${id}`,
    );
    dispatch({ type: COURSE_DEL, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: COURSE_ERROR });
    throw new Error(error);
  }
};
