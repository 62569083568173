import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';

export function BookingForm({
  Controller,
  control,
  course,
  dateBooking,
  setDateBooking,
}) {
  return (
    <div>
      <div className="w-full  px-1 py-2">
        <Controller
          name={'course'}
          control={control}
          //   defaultValue={customer ? customer?.type?._id : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <FormControl sx={{ minWidth: 120 }} fullWidth={true}>
              <InputLabel id="type" size={'small'}>
                เลือกคอร์ส*
              </InputLabel>
              <Select
                {...field}
                label="เลือกคอร์ส"
                size={'small'}
                fullWidth
                required
              >
                {_.size(course) ? (
                  _.map(course, (row) => (
                    <MenuItem key={row.id} value={row.id}>
                      {row.name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem>
                    <MenuItem disabled value="">
                      <em>ไม่มีข้อมูล</em>
                    </MenuItem>
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          )}
        />
      </div>
      <div className="bg-white  px-1 py-2">
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDatePicker
            label="วันเริ่มต้น"
            value={dateBooking}
            minDate={new Date()}
            onChange={(newValue) => {
              setDateBooking(newValue);
            }}
            renderInput={(params) => (
              <TextField fullWidth {...params} size="small" />
            )}
          />
        </LocalizationProvider>
      </div>
      <div className="w-full  px-1 py-2">
        <Controller
          name={'customer'}
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="ลูกค้า"
              fullWidth
              size={'small'}
              required
            />
          )}
        />
      </div>
      <div className="w-full  px-1 py-2">
        <Controller
          name={'quantity'}
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="จำนวน"
              fullWidth
              size={'small'}
              required
            />
          )}
        />
      </div>
      <div className="w-full  px-1 py-2">
        <Controller
          name={'remark'}
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="หมายเหตุ"
              fullWidth
              multiline
              rows={4}
              size={'small'}
              required
            />
          )}
        />
      </div>
    </div>
  );
}

BookingForm.propTypes = {
  Controller: PropTypes.func,
  control: PropTypes.object,
  course: PropTypes.array,
};

BookingForm.defaultProps = {};

export default BookingForm;
