import {
  ORDER_ALL,
  ORDER_GET,
  ORDER_POST,
  ORDER_PUT,
  ORDER_DEL,
  ORDER_ERROR,
  ORDER_LOADING,
} from '../../actions/types';

const initialState = {
  order: null,
  isLoading: true,
  isCompleted: true,
};

export default function OrderReducers(state = initialState, action) {
  switch (action.type) {
    case ORDER_LOADING:
      return { isLoading: true, isCompleted: true };
    case ORDER_ERROR:
      return { isLoading: false, isCompleted: false, ...action.payload };
    case ORDER_ALL:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case ORDER_GET:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case ORDER_POST:
      return { isLoading: false, isCompleted: true };
    case ORDER_PUT:
      return { isLoading: false, isCompleted: true };
    case ORDER_DEL:
      return { isLoading: false, isCompleted: true };
    default:
      return state;
  }
}
