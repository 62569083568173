import {
  SEOMANAGEMENT_ALL,
  SEOMANAGEMENT_GET,
  SEOMANAGEMENT_DELETE,
  SEOMANAGEMENT_UPDATE,
  SEOMANAGEMENT_CREATE,
  SEOMANAGEMENT_LOADING,
  SEOMANAGEMENT_ERROR,
  SEOMANAGEMENT_RESET,
} from '../types';

import api from '../../../utils/functions/api';

export const SEOCreate = (payload) => async (dispatch) => {
  try {
    dispatch({ type: SEOMANAGEMENT_LOADING });
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/SEO-management`,
      payload,
    );
    dispatch({ type: SEOMANAGEMENT_CREATE, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: SEOMANAGEMENT_ERROR });
    throw new Error(error);
  }
};

export const SEOAll = (params) => async (dispatch) => {
  try {
    const { name = '', size = '', page = 1 } = params;
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/SEO-management?name=${name}&size=${size}&page=${page}`,
    );
    if (status === 200) {
      dispatch({ type: SEOMANAGEMENT_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: SEOMANAGEMENT_ERROR });
    throw new Error(error);
  }
};

export const SEOGet = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/SEO-management/${id}`,
    );
    if (status === 200) {
      dispatch({ type: SEOMANAGEMENT_GET, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: SEOMANAGEMENT_ERROR });
    throw new Error(error);
  }
};

export const SEOPut = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: SEOMANAGEMENT_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/SEO-management/${id}`,
      payload,
    );
    dispatch({ type: SEOMANAGEMENT_UPDATE, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: SEOMANAGEMENT_ERROR });
    throw new Error(error);
  }
};
export const SEODelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: SEOMANAGEMENT_LOADING });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/SEO-management/${id}`,
    );
    dispatch({ type: SEOMANAGEMENT_DELETE, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: SEOMANAGEMENT_ERROR });
    throw new Error(error);
  }
};

export const SEOReset = () => async (dispatch) => {
  try {
    dispatch({ type: SEOMANAGEMENT_RESET });
  } catch (error) {
    console.error(error);
    dispatch({ type: SEOMANAGEMENT_ERROR });
    throw new Error(error);
  }
};
