import PropTypes from 'prop-types';

export const Customer = PropTypes.shape({
  type: PropTypes.oneOf([PropTypes.string, PropTypes.object]),
  name: PropTypes.string,
  phone_number: PropTypes.string,
});

export const CustomerState = PropTypes.shape({
  rows: PropTypes.arrayOf(Customer),
  total: PropTypes.number,
  lastPage: PropTypes.number,
  currPage: PropTypes.number,
});

export default { Customer, CustomerState };
