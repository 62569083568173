import {
  INFORMATION_ALL,
  INFORMATION_PUT,
  INFORMATION_LOADING,
  INFORMATION_ERROR,
} from '../../actions/types';

const initialState = {
  information: null,
  isLoading: true,
  isCompleted: true,
};
// eslint-disable-next-line func-names
export default function (state = initialState, action) {
  switch (action.type) {
    case INFORMATION_LOADING:
      return { isLoading: true, isCompleted: true, name: 'ระบบ ERP' };
    case INFORMATION_ERROR:
      return {
        isLoading: false,
        isCompleted: false,
        name: 'ระบบ ERP',
        ...action.payload,
      };
    case INFORMATION_ALL:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case INFORMATION_PUT:
      return { isLoading: false, isCompleted: true, name: 'ระบบ ERP' };
    default:
      return state;
  }
}
