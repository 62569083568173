import PropTypes from 'prop-types';

export const Department = PropTypes.shape({
  id: PropTypes.string,
  department_code: PropTypes.string,
  description: PropTypes.string,
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
});

export const departmentArray = PropTypes.arrayOf(Department);

export const departmentState = PropTypes.shape({
  rows: Department,
  total: PropTypes.number,
  lastPage: PropTypes.number,
  currPage: PropTypes.number,
});

export default { Department, departmentArray, departmentState };
