import dayjs from 'dayjs';
// import _ from 'lodash';
import 'dayjs/locale/th';
import buddhistEra from 'dayjs/plugin/buddhistEra';

dayjs.locale('th');
dayjs.extend(buddhistEra);

export const header = (information) => [
  {
    columns: [
      { height: 70, width: 70, image: 'company_logo', margin: [40, 0, 0, 0] },
      { width: '10%', text: '' },
      {
        stack: [
          {
            text: information?.name || '-',
            fontSize: 12,
            bold: true,
          },
          {
            text: `โทร ${information?.owner?.telephone || '-'}`,
            fontSize: 10,
          },
          {
            text: `เลขประจำตัวผู้เสียภาษี ${information?.owner?.tax || '-'}`,
            fontSize: 10,
          },
        ],
      },
    ],
    margin: [0, 20, 40, 10],
    color: '#2F4F4F',
    font: 'Sarabun',
  },
];

export const slipHeader = (
  information,
  customer,
  employee,
  frompage,
  productTransaction,
) => [
  {
    stack: [
      // {
      //   height: 50,
      //   width: 100,
      //   image: 'company_logo',
      //   margin: [20, 10, 20, 10],
      // },
      {
        text: information?.name || '',
        fontSize: 10,
        bold: true,
        font: 'Sarabun',
        margin: [0, 2],
        alignment: 'center',
      },
      {
        text: `TAX ID : ${information?.owner?.tax || '-'}`,
        fontSize: 10,
        bold: true,
        font: 'Sarabun',
        margin: [0, 2],
        alignment: 'center',
      },
      {
        text: 'ใบเสร็จรับเงิน/ใบกำกับภาษีอย่างย่อ',
        fontSize: 10,
        bold: true,
        font: 'Sarabun',
        margin: [0, 2],
        alignment: 'center',
      },
      {
        text: '--------------------------------------------------',
        fontSize: 8,
        bold: true,
        font: 'Sarabun',
        margin: [0, 2],
        alignment: 'center',
      },
      {
        text: `วันที่ ${dayjs().format('D MMM BBBB')} เวลา ${dayjs().format(
          'HH:mm',
        )}`,
        fontSize: 10,
        bold: true,
        font: 'Sarabun',
        margin: [16, 2],
        alignment: '',
      },
      {
        text: `เลขที่ใบเสร็จ : ${
          frompage === 'export'
            ? productTransaction?.bill_no
            : `POS${new Date().valueOf()}`
        }`,
        fontSize: 10,
        bold: true,
        font: 'Sarabun',
        margin: [16, 2],
        alignment: '',
      },
      {
        text: `ลูกค้า : ${customer?.name || '-'}`,
        fontSize: 10,
        bold: true,
        font: 'Sarabun',
        margin: [16, 2],
        alignment: '',
      },
      {
        text: `พนักงาน : ${employee?.firstname || '-'}`,
        fontSize: 10,
        bold: true,
        font: 'Sarabun',
        margin: [16, 2],
        alignment: '',
      },
      {
        text: '--------------------------------------------------',
        fontSize: 8,
        bold: true,
        font: 'Sarabun',
        margin: [0, 2],
        alignment: 'center',
      },
    ],
  },
];

export const footer = () => [
  {
    columns: [
      '',
      '',
      { text: '© 2021 E-Accom ', fontSize: 8, alignment: 'right' },
    ],
    margin: [24, 15],
  },
];
export const slipFooter = () => [
  {
    stack: [
      {
        text: '--------------------------------------------------',
        fontSize: 8,
        bold: true,
        alignment: 'center',
      },
      {
        text: 'ขอบคุณที่มาอุดหนุน',
        fontSize: 12,
        bold: true,
        alignment: 'center',
      },
    ],
  },
];
