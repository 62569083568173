import {
  BRAND_ALL,
  BRAND_GET,
  BRAND_DEL,
  BRAND_PUT,
  BRAND_POST,
  BRAND_LOADING,
  BRAND_ERROR,
} from '../../actions/types';

const initialState = {
  productType: null,
  isLoading: true,
  isCompleted: true,
};
// eslint-disable-next-line func-names
export default function (state = initialState, action) {
  switch (action.type) {
    case BRAND_LOADING:
      return { isLoading: true, isCompleted: true };
    case BRAND_ERROR:
      return { isLoading: false, isCompleted: false, ...action.payload };
    case BRAND_ALL:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case BRAND_GET:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case BRAND_POST:
      return { isLoading: false, isCompleted: true };
    case BRAND_PUT:
      return { isLoading: false, isCompleted: true };
    case BRAND_DEL:
      return { isLoading: false, isCompleted: true };
    default:
      return state;
  }
}
