import React, { useEffect, useState } from 'react';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import {
  Backdrop,
  Button,
  Card,
  Fade,
  Modal,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import PropTypes from 'prop-types';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import BookingForm from '../../components/Forms/BMS/BookingForm';
import * as actions from '../../redux/actions';
import { Loading } from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';
import { BookingTable } from '../../components/Tables';
import BookingOrder from 'components/PdfFile/BookingOrder';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90vw',
  boxShadow: 24,
  p: 4,
};

export default function Booking({ title, subtitle }) {
  const dispatch = useDispatch();
  const { handleSubmit, control } = useForm();
  const course = useSelector((state) => state.course);
  const booking = useSelector((state) => state.booking);
  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;
  const [open, setOpen] = useState(false);
  const [openDetail, setOpenDetail] = useState(false);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(25);
  const [dateBooking, setDateBooking] = useState(new Date());
  const [date, setDate] = useState(new Date());
  const [selectDetail, setSelectDetail] = useState(false);
  const [selectCourse, setSelectCourse] = useState();
  const [total, setTotal] = useState(undefined);

  useEffect(() => {
    dispatch(actions.courseAll({}));

    return () => {};
  }, [date]);
  useEffect(() => {
    dispatch(actions.bookingAll({ date, selectCourse }));

    return () => {};
  }, [date, selectCourse]);
  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDetailOpen = (data) => {
    setSelectDetail(data);
    setOpenDetail(true);
  };

  const handleDetailClose = () => {
    setOpenDetail(false);
  };

  const onSubmit = async (data) => {
    // console.log(data);
    const confirm = window.confirm('ยืนยันบันทึกข้อมูล');
    if (confirm) {
      const dataSubmit = { ...data, date: dateBooking };
      await dispatch(actions.bookingCreate(dataSubmit));
      await dispatch(actions.bookingAll({ date, selectCourse }));
      handleClose();
    }
  };
  const handleDeleteButton = async (id) => {
    const confirm = window.confirm('ยืนยันลบข้อมูล');
    if (confirm) {
      await dispatch(actions.bookingDelete(id));
      await dispatch(actions.bookingAll({ date, selectCourse }));
      handleClose();
    }
  };

  const handlePrint = () => BookingOrder(booking, date);
  const renderSearch = () => (
    <Card>
      <div className="p-4 flex flex-row">
        <div className="w-full md:w-1/2">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              label="วันเริ่มต้น"
              value={date}
              onChange={(newValue) => {
                setDate(newValue);
              }}
              renderInput={(params) => (
                <TextField fullWidth {...params} size="small" />
              )}
            />
          </LocalizationProvider>
        </div>
        {console.log(selectCourse)}
        <div className="w-full md:w-1/2">
          <FormControl sx={{ minWidth: 120 }} fullWidth={true}>
            <InputLabel id="type" size={'small'}>
              เลือกคอร์ส*
            </InputLabel>
            <Select
              label="เลือกคอร์ส"
              size={'small'}
              fullWidth
              required
              onChange={(newValue) => {
                setSelectCourse(newValue.target.value);
              }}
            >
              <MenuItem value={''}>ทั้งหมด</MenuItem>
              {_.size(course?.rows) ? (
                _.map(course?.rows, (row) => (
                  <MenuItem key={row.id} value={row.id}>
                    {row.name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem>
                  <MenuItem disabled value="">
                    <em>ไม่มีข้อมูล</em>
                  </MenuItem>
                </MenuItem>
              )}
            </Select>
          </FormControl>
        </div>
      </div>
    </Card>
  );
  const renderDetailModal = () => (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={openDetail}
      onClose={handleDetailClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openDetail}>
        <Card sx={style} className="max-w-4xl">
          <div className="w-full overflow-y-auto max-h-screen">
            <div className="py-2">รายละเอียด</div>
            <div>
              <div className="text-xl">
                ลูกค้า : {selectDetail?.customer || '-'}
              </div>
              <div className="text-l">
                คอร์สที่จอง : {selectDetail?.course?.name || '-'}
              </div>
              <div className="text-l">
                จำนวน : {selectDetail?.quantity || '-'}
              </div>
              <div className="text-l">
                หมายเหตุ : {selectDetail?.remark || '-'}
              </div>
            </div>
            <div className="py-2"></div>
          </div>
        </Card>
      </Fade>
    </Modal>
  );

  const renderModal = () => (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Card sx={style} className="max-w-4xl">
          <div className="w-full overflow-y-auto max-h-screen">
            <div className="py-2">เพิ่มการจอง</div>
            <div className="py-2">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="">
                  <BookingForm
                    Controller={Controller}
                    control={control}
                    course={course?.rows}
                    dateBooking={dateBooking}
                    setDateBooking={setDateBooking}
                  />
                  <Button variant="contained" type="submit">
                    บันทึก
                  </Button>
                  <Button variant="outlined">ยกเลิก</Button>
                </div>
              </form>
            </div>
          </div>
        </Card>
      </Fade>
    </Modal>
  );

  const renderTableBooking = () => (
    <BookingTable
      booking={booking?.rows}
      page={page}
      size={size}
      total={total}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      handleChangePage={handleChangePage}
      handleDetailOpen={handleDetailOpen}
      handleDeleteButton={handleDeleteButton}
    />
  );

  const renderAddButton = () => (
    <div className="flex flex-row flex-wrap gap-1">
      <Button
        startIcon={<AddBoxIcon />}
        variant="contained"
        onClick={handleOpen}
      >
        เพิ่ม
      </Button>
      <Button
        variant="contained"
        color={'success'}
        onClick={() => handlePrint()}
      >
        พิมพ์
      </Button>
    </div>
  );

  if (course.isLoading || booking.isLoading) {
    return <Loading />;
  }

  if (!course.isLoading && course.isCompleted && !booking.isLoading) {
    return (
      <div>
        {renderModal()}
        {renderTitle()}
        {renderDetailModal()}
        <div>
          <div>
            <div className="flex flex-row justify-end pb-4">
              <div>{renderAddButton()}</div>
            </div>
            {renderSearch()}
            {renderTableBooking()}
          </div>
        </div>
      </div>
    );
  }
  return <Error />;
}

Booking.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

Booking.defaultProps = {
  title: '',
  subtitle: '',
};
