import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Card,
  ListItemButton,
  ListItemAvatar,
  Avatar,
  ListItemText,
  ListItem,
  Chip,
} from '@mui/material';

import { BackButton } from '../../components/Button';
import { ViewTitle } from '../../components/ViewTitle';
import * as actions from '../../redux/actions';

// eslint-disable-next-line arrow-body-style
const ViewProcessTemplate = ({ title, subtitle }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const processTemplate = useSelector((state) => state.processTemplate);
  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;
  useEffect(() => {
    const fetchProcessTemplate = async () => {
      try {
        await dispatch(actions.processTemplateGet(params.id));
      } catch (error) {
        console.error(error);
      }
    };

    fetchProcessTemplate();
    return () => {};
  }, [params]);

  return (
    <div>
      {renderTitle()}
      <div className="flex flex-row justify-start pb-4">
        <div>
          <BackButton />
        </div>
        <div className="text-lg font-semibold ml-6">
          รายละเอียดรูปแบบไลน์การผลิต
        </div>
      </div>
      <div>
        <Card className="p-4 ">
          <div className="flex flex-wrap divide-y">
            <div className="w-full py-4 text-lg font-semibold ">รายละเอียด</div>
            <div className="w-1/2 py-4 px-2 font-semibold ">{'ชื่อ'}</div>

            <div className="w-1/2 py-4 px-2 "> {processTemplate?.name}</div>
            <div className="w-1/2 py-4 px-2 font-semibold ">{'ผลิตภัณฑ์'}</div>

            <div className="w-1/2 py-4 px-2 ">
              {processTemplate?.product_id?.name}
            </div>

            <div className="w-1/2 py-4 px-2 font-semibold ">{'รายละเอียด'}</div>
            <div className="w-1/2 py-4 px-2  ">{processTemplate?.detail}</div>
            <div className="w-full py-4 text-lg font-semibold ">
              ขั้นตอนการทำ
            </div>
            <ListItem className="flex flex-wrap">
              {Array.isArray(processTemplate?.steps) &&
                processTemplate?.steps?.map((eachStep, index) => (
                  <ListItemButton key={index} className="w-full ">
                    <ListItemAvatar>
                      <Avatar className="bg-purple-600">{index + 1}</Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <div>
                          {' '}
                          {eachStep?.name}{' '}
                          <Chip
                            size="small"
                            label={eachStep?.department?.name}
                          />{' '}
                        </div>
                      }
                      secondary={eachStep?.detail}
                    />
                  </ListItemButton>
                ))}
            </ListItem>
          </div>
        </Card>
      </div>
    </div>
  );
};

ViewProcessTemplate.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

ViewProcessTemplate.defaultProps = {
  title: '',
  subtitle: '',
};
export default ViewProcessTemplate;
