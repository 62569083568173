import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { Card, Button } from '@mui/material';
import { useParams } from 'react-router';
import PropTypes from 'prop-types';
import * as actions from '../../redux/actions';
import { BackButton } from '../../components/Button';
import { PlaceForm } from '../../components/Forms';
import { Loading } from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';

export default function EditPlace({ title, subtitle }) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const place = useSelector((state) => state.place);
  const {
    formState: { errors },
    handleSubmit,
    control,
  } = useForm({
    defaultValues: {
      name: place.name,
      address: place.address,
    },
  });

  useEffect(() => {
    dispatch(actions.placeGet(id));
    return () => {};
  }, []);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const onSubmit = async (data) => {
    try {
      console.log(data);
      await dispatch(actions.placePut(id, data));
      await dispatch(actions.placeGet(id));
      alert('สำเร็จ');
    } catch (error) {
      console.log(error);
    }
  };
  if (place.isLoading || place.rows) {
    return <Loading />;
  }
  if (!place.isLoading && place.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div className="flex flex-row justify-start pb-4">
          <div>
            <BackButton />
          </div>
          <div className="text-lg font-semibold ml-6">แก้ไขข้อมูลสถานที่</div>
        </div>
        <div>
          <Card className="p-4">
            <form onSubmit={handleSubmit(onSubmit)}>
              <PlaceForm
                errors={errors}
                place={place}
                control={control}
                Controller={Controller}
              />
              <div className="flex flex-row justify-end gap-1 py-4">
                <Button variant="contained" type="submit">
                  บันทึก
                </Button>
              </div>
            </form>
          </Card>
        </div>
      </div>
    );
  }
  return <Error />;
}

EditPlace.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

EditPlace.defaultProps = {
  title: '',
  subtitle: '',
};
