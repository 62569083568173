import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import {
  Information,
  ModuleSelectors,
  DeliverySetting,
  EditDeliverySetting,
  PromotionSetting,
  AreaDeliverySetting,
  SEOManagement,
  // SEOManagementDetail,
} from '../views/SettingSystem';

import { HomeNavbar } from '../components/Navbars';
import { MainFooter } from '../components/Footers';
import { MainSidebar } from '../components/Sidebar';
import { Loading } from '../components/Loading';
import { NotFound, NotAuthorized } from '../components/Error';
import accessRight from '../utils/functions/accessRight';
import * as actions from '../redux/actions';

export function SETTING() {
  const module = 'SETTING';
  const prefix = '/setting';
  const name = 'ตั้งค่าระบบ';
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const me = useSelector((state) => state.me);
  const availableModule = useSelector((state) => state.availableModule);
  const setting = useSelector((state) => state.setting);

  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const handleOnMobileNavOpen = () => {
    setMobileNavOpen(true);
  };

  const handleOnMobileNavClose = () => {
    setMobileNavOpen(false);
  };

  useEffect(() => {
    console.log('Layout: SETTING');
    dispatch(actions.meGet());
    dispatch(actions.settingAll());
    dispatch(actions.availableModuleAll());
    return () => {};
  }, []);

  if (!me?.userData) {
    return <Loading />;
  }

  if (accessRight(me, module, 0, availableModule)) {
    return (
      <div className="min-h-screen">
        <Helmet>
          <meta charSet="utf-8" />
          <title>ตั้งค่าระบบ - {setting?.name || ''}</title>
        </Helmet>

        <HomeNavbar onMobileNavOpen={handleOnMobileNavOpen} />
        <div className="min-h-screen  pt-16 px-4 flex w-full">
          <MainSidebar
            onMobileClose={handleOnMobileNavClose}
            openMobile={isMobileNavOpen}
            me={me}
          />
          <div className="py-4 w-full">
            <Switch>
              <Redirect exact from={`${prefix}`} to={`${prefix}/system`} />
              <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
              <Route exact path={`${prefix}/system`}>
                <Information title="ข้อมูลระบบ" subtitle={name} />
              </Route>

              <Route exact path={`${prefix}/SEOManagement`}>
                <SEOManagement title="ตั้งค่า SEO" subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/modules`}>
                <ModuleSelectors title="โมดูลที่เปิดใช้งาน" subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/delivery`}>
                <DeliverySetting title="ตั้งค่าราคาการจัดส่ง" subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/delivery/edit/:id`}>
                <EditDeliverySetting title="แก้ไขการจัดส่ง" subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/promotion`}>
                <PromotionSetting title="ตั้งค่าส่วนลด" subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/area-delivery`}>
                <AreaDeliverySetting
                  title="ตั้งค่าพื้นที่ให้การจัดส่ง"
                  subtitle={name}
                />
              </Route>
              <Route path="*">
                <NotFound />
              </Route>
            </Switch>
          </div>
        </div>
        <div className="lg:ml-64">
          <MainFooter />
        </div>
      </div>
    );
  }
  return (
    <div>
      <div className="min-h-screen">
        <MainSidebar
          onMobileClose={handleOnMobileNavClose}
          openMobile={isMobileNavOpen}
          me={me}
        />
        <HomeNavbar onMobileNavOpen={handleOnMobileNavOpen} />
        <div className="lg:ml-64  min-h-screen  pt-16 px-4 ">
          <div className="py-4">
            <Switch>
              <Route exact path={`${prefix}/modules`}>
                <ModuleSelectors title="โมดูลที่เปิดใช้งาน" subtitle={name} />
              </Route>
            </Switch>
          </div>
        </div>
        <NotAuthorized />
        <div className="lg:ml-64">
          <MainFooter />
        </div>
      </div>
    </div>
  );
}

export default SETTING;
