import React from 'react';
import PropTypes from 'prop-types';

import _ from 'lodash';
import { Link } from 'react-router-dom';
import dipromLogo from '../../assets/images/diprom.png';
import accessRight from '../../utils/functions/accessRight';
// import ButtonMenuList from '../Button/ButtonMenuList';

function Diprom1({ me, availableModule, setting, menuList }) {
  return (
    <div className="min-h-screen bg-gradient-to-t from-purple-400 to-white">
      <div className="hidden md:block h-96 pt-28">
        <div className="hidden md:flex justify-center items-center px-10">
          <div>
            <img src={dipromLogo} alt="diprom" className="h-32" />
          </div>
          <div className="text-4xl px-8">x</div>
          <div>
            <img src={setting?.logo?.url} alt="company-logo" className="h-32" />
          </div>
        </div>
        <div className=" text-center my-2 text-xl mt-10">
          <div className="font-semibold">{setting?.description}</div>
          <div className="my-1">{setting?.owner?.name}</div>
          <div className="my-1 text-base">
            สนับสนุนโดย {setting?.sponsor?.name}
          </div>
        </div>
      </div>
      <div className="container mr-auto ml-auto pt-28 md:pt-0">
        {_.map(menuList, (_menu, index) => {
          if (accessRight(me, _menu.moduleName, 0, availableModule)) {
            return (
              <div className="py-4  px-4" key={index}>
                <Link to={_menu.link}>
                  <div
                    className={`  shadow rounded text-center  py-8 px-4 lg:h-40  transition-transform transform hover:scale-105 ${_menu.extendedStyle}`}
                  >
                    <div className="flex justify-center">{_menu.icon}</div>
                    <p className=" text-white mt-4 ">{_menu.title}</p>
                  </div>
                </Link>
              </div>
            );
          }
        })}
        {/* <ButtonMenuList me={me} availableModule={availableModule} /> */}
      </div>
    </div>
  );
}

export default Diprom1;

Diprom1.propTypes = {
  me: PropTypes.object,
  availableModule: PropTypes.arrayOf(PropTypes.object),
  setting: PropTypes.object,
  menuList: PropTypes.arrayOf(PropTypes.object),
};
