import { TextField } from '@mui/material';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

export function ProductForm({
  errors,
  product,
  fields,
  setValue,
  Controller,
  control,
  watch,
  checkExportAll,
}) {
  // useEffect(() => {
  //   if (!_.isEmpty(product?.orders)) {
  //     const optionArray = _.map(product?.orders, (eachOption) => eachOption);
  //     setValue('optionList', optionArray);
  //   }
  //   return () => {};
  // }, []);

  return (
    <div className="">
      {!_.isEmpty(fields) ? (
        _.map(fields, (item, index) => (
          <div className="flex border-b-2 mb-1" key={index}>
            <div className="w-1/2 my-auto">
              {index + 1} : {item?.product?.model_name}
            </div>
            <div className="w-1/2 px-1 py-2 flex gap-1">
              <div className="flex w-1/3">
                <TextField
                  label="จำนวนส่งออก"
                  fullWidth
                  type="number"
                  size={'small'}
                  disabled
                  defaultValue={item?.amount}
                  // eslint-disable-next-line react/prop-types
                  helperText={errors.amount && 'กรุณาใส่ข้อมูล'}
                />
              </div>
              <div className="flex w-1/3">
                <TextField
                  label="จำนวนที่จ่ายแล้ว"
                  fullWidth
                  type="number"
                  size={'small'}
                  disabled
                  defaultValue={item?.amount_export_real || 0}
                  // eslint-disable-next-line react/prop-types
                  helperText={errors.amount && 'กรุณาใส่ข้อมูล'}
                />
              </div>
              <div className="w-1/3">
                <Controller
                  name={`optionList.${index}.amount_export`}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="จำนวนที่จ่าย"
                      fullWidth
                      type="number"
                      size={'small'}
                      defaultValue={0}
                      disabled={checkExportAll}
                      // eslint-disable-next-line react/prop-types
                      helperText={errors.amount_export && 'กรุณาใส่ข้อมูล'}
                    />
                  )}
                />
                <div className="text-red-500 mt-1">
                  {parseFloat(watch(`optionList.${index}.amount_export_real`)) +
                    parseFloat(watch(`optionList.${index}.amount_export`)) >
                    parseFloat(watch(`optionList.${index}.amount`)) &&
                    'จำนวนการจ่ายเกินกว่าจำนวนส่งออก'}
                </div>
              </div>
            </div>
          </div>
        ))
      ) : (
        <></>
      )}
    </div>
  );
}

ProductForm.propTypes = {
  errors: PropTypes.shape({
    inventory: PropTypes.number,
  }),
  product: PropTypes.object,
  productType: PropTypes.object,
  fields: PropTypes.array,
  setValue: PropTypes.func,
  Controller: PropTypes.func,
  control: PropTypes.object,
  watch: PropTypes.func,
  checkExportAll: PropTypes.bool,
};

ProductForm.defaultProps = {
  product: null,
  productType: null,
};

export default ProductForm;
