import api from '../../../utils/functions/api';

import {
  DELIVERY_PROVIDER_ALL,
  DELIVERY_PROVIDER_CREATE,
  DELIVERY_PROVIDER_GET,
  DELIVERY_PROVIDER_UPDATE,
  DELIVERY_PROVIDER_DELETE,
  DELIVERY_PROVIDER_ERROR,
} from '../types';

// faker.locale = "th"

export const getAllDeliveryProvider = (params) => async (dispatch) => {
  try {
    const { name = '', size = '', page = 1 } = params;
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/delivery-provider?name=${name}&size=${size}&page=${page}`,
    );
    if (status === 200) {
      dispatch({ type: DELIVERY_PROVIDER_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: DELIVERY_PROVIDER_ERROR });
    throw new Error(error);
  }
};

export const getOneDeliveryProvider = (id) => async (dispatch) => {
  await api
    .get(`${process.env.REACT_APP_API_URL}/delivery-provider/${id}`)
    .then((res) => {
      console.log('Request Server to Get One option');
      if (res.data) {
        dispatch({ type: DELIVERY_PROVIDER_GET, payload: res.data });
      } else {
        dispatch({ type: DELIVERY_PROVIDER_GET, payload: null });
      }
    });
};
export const createOneDeliveryProvider = (payload) => async (dispatch) => {
  await api
    .post(`${process.env.REACT_APP_API_URL}/delivery-provider/`, payload)
    .then(() => {
      console.log('Request Server to Create New option');
      dispatch({ type: DELIVERY_PROVIDER_CREATE });
    });
};

export const editOneDeliveryProvider = (id, payload) => async (dispatch) => {
  await api
    .put(`${process.env.REACT_APP_API_URL}/delivery-provider/${id}`, payload)
    .then(() => {
      console.log('Request Server to edit option');
      dispatch({ type: DELIVERY_PROVIDER_UPDATE });
    });
};

export const deleteOneDeliveryProvider = (id) => async (dispatch) => {
  await api
    .delete(`${process.env.REACT_APP_API_URL}/delivery-provider/${id}`)
    .then(() => {
      console.log('Request Server to Delete One option');
      dispatch({ type: DELIVERY_PROVIDER_DELETE, payload: null });
    });
};
