import React, { useEffect } from 'react';
import { Button, Dialog, DialogContent, DialogTitle } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import PropTypes from 'prop-types';
import _ from 'lodash';

const ModalUploadFile = ({ isOpen, handleClose, files, setFiles }) => {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
  });

  useEffect(() => {
    setFiles(acceptedFiles);
    return () => {};
  }, [_.size(acceptedFiles)]);

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>อัพโหลดไฟล์</DialogTitle>
      <DialogContent>
        <section className="container">
          <div {...getRootProps({ className: 'dropzone' })}>
            <input {...getInputProps()} />
            <p className="font-system w-72 m-1 p-4 bg-gray-50 border ">
              ลากไฟล์มาที่นี้ หรือ กดที่นี่เพื่อเลือกไฟล์
            </p>
          </div>
          <aside>
            {_.size(files) > 0 && (
              <ul className="list-disc list-inside mx-2 my-3">
                {_.map(acceptedFiles, (_file, index) => (
                  <li className="font-system " key={index}>
                    {_file?.path}
                  </li>
                ))}
              </ul>
            )}
          </aside>
        </section>
        {!_.isEmpty(files) && (
          <div className="flex justify-center my-2">
            <Button
              color="success"
              variant="contained"
              onClick={() => handleClose()}
            >
              ยืนยัน
            </Button>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ModalUploadFile;

ModalUploadFile.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  files: PropTypes.arrayOf(PropTypes.object),
  setFiles: PropTypes.func,
};
