import React from 'react';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Tooltip,
} from '@mui/material';
import Proptypes from 'prop-types';
import _ from 'lodash';
import dayjs from 'dayjs';

export default function BookingTable({
  booking,
  page,
  size,
  total,
  handleChangeRowsPerPage,
  handleChangePage,
  handleDetailOpen,
  handleDeleteButton,
}) {
  // console.log('booking', booking);
  return (
    <div className="my-2">
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 1200 }}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <div className="font-bold">ลำดับที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">วันที่จอง</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">คอร์ส</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">จำนวน</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">ลูกค้า</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">หมายเหตุ</div>
                </TableCell>

                <TableCell>
                  <div className="font-bold">ดำเนินการ</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(booking) ? (
                booking.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    className={index % 2 === 0 ? 'bg-gray-100' : ''}
                  >
                    <TableCell component="th" scope="row">
                      {(page - 1) * size + index + 1}
                    </TableCell>
                    <TableCell>{`${dayjs(row?.date).format(
                      'D MMM BBBB ',
                    )}`}</TableCell>
                    <TableCell>
                      <div className="font-bold flex">{row?.course?.name}</div>
                    </TableCell>
                    <TableCell>
                      <div className="font-bold flex">{row?.quantity}</div>
                    </TableCell>
                    <TableCell>
                      <div className="font-bold flex">{row?.customer}</div>
                    </TableCell>
                    <TableCell>
                      <div className="font-bold flex">{row?.remark}</div>
                    </TableCell>

                    <TableCell>
                      <div className="flex flex-row flex-wrap gap-1">
                        <Button
                          variant="contained"
                          color={'secondary'}
                          disabled={row?.status === 'failed'}
                          size={'small'}
                          onClick={() => handleDetailOpen(row)}
                        >
                          รายละเอียด
                        </Button>
                        <Button
                          variant="contained"
                          color={'error'}
                          disabled={row?.status === 'failed'}
                          size={'small'}
                          onClick={() => handleDeleteButton(row.id)}
                        >
                          ลบ
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <div className="text-center">ไม่มีข้อมูล</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          rowsPerPageOptions={[5, 10, 25, 100]}
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={page - 1}
          count={total || 1}
          rowsPerPage={size}
          onPageChange={handleChangePage}
        />
      </Paper>
    </div>
  );
}

BookingTable.propTypes = {
  page: Proptypes.number,
  size: Proptypes.number,
  total: Proptypes.number,
  handleChangeRowsPerPage: Proptypes.func.isRequired,
  handleChangePage: Proptypes.func.isRequired,
  handleCutStock: Proptypes.func,
  checkProduct: Proptypes.func,
  course: Proptypes.array,
  handleToInfoPage: Proptypes.func,
};
