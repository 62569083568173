/* eslint-disable no-confusing-arrow */
import appConfig from '../../configs/app';

const columns = [
  {
    label: 'ลำดับที่',
    value: 'index',
  },
  {
    label: 'รหัสสินค้า',
    value: 'type_code',
  },
  {
    label: 'ขื่อสินค้า',
    value: 'name',
  },
  {
    label: 'หน่วยสินค้า',
    value: 'unit',
  },
  {
    label: 'จำนวนคงเหลือ',
    value: 'inventory',
  },
  {
    label: 'ราคาต้นทุนต่อหน่วย',
    value: 'cost_price',
  },
  {
    label: 'ราคาขายต่อหน่วย',
    value: 'price',
  },
  {
    label: 'ราคาต้นทุนรวมในคลัง',
    value: 'total_cost_price',
  },
  {
    label: 'ราคาขายรวมในคลัง',
    value: 'total_price',
  },
];

const query = ({ id }) => ({
  place: id,
  fetchStockLot: true,
  fetchProcess: false,
  page: 1,
  size: appConfig?.maxFetchSize,
});

export default { columns, query };
