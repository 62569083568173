import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import accessRight from '../../utils/functions/accessRight';

function DefaultMenu({ me, availableModule, menuList }) {
  return (
    <div className="">
      <div className="container pt-40 mr-auto ml-auto">
        <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 ">
          {_.map(menuList, (_menu, index) => {
            if (accessRight(me, _menu?.moduleName, 0, availableModule)) {
              return (
                <div className="my-4  px-4" key={index}>
                  <Link to={_menu.link}>
                    <div
                      className={`  shadow rounded text-center  py-8 px-4 lg:h-40  transition-transform transform hover:scale-105 ${_menu.extendedStyle}`}
                    >
                      <div className="flex justify-center">{_menu.icon}</div>
                      <p className=" text-white mt-4 ">{_menu.title}</p>
                    </div>
                  </Link>
                </div>
              );
            }
          })}
        </div>
      </div>
    </div>
  );
}

export default DefaultMenu;

DefaultMenu.propTypes = {
  me: PropTypes.object,
  availableModule: PropTypes.arrayOf(PropTypes.object),
  menuList: PropTypes.arrayOf(PropTypes.object),
};
