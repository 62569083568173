/* eslint-disable import/no-unresolved */
import { TextField, Autocomplete } from '@mui/material';

import React, { useState } from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-unresolved
// eslint-disable-next-line import/extensions
import RichtextEditor from '../../Richtext/RichtextEditor';

export function ProductForm({
  product,
  Controller,
  control,
  productType,
  brand,
}) {
  const [reload, setReload] = useState(false);

  return (
    <div className="flex flex-row flex-wrap">
      <div className="p-2 bg-gray-600 text-white my-2 w-full">
        ข้อมูลเบื้องต้นของสินค้า
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name={'type_code'}
          control={control}
          defaultValue={product ? product.type_code : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="รหัสสินค้า 5 หลัก"
              required
              fullWidth
              size={'small'}
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name={'name'}
          control={control}
          defaultValue={product ? product.name : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="ชื่อสินค้า"
              required
              fullWidth
              size={'small'}
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name={'model_name'}
          control={control}
          defaultValue={product ? product.model_name : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField {...field} label="รุ่นสินค้า" fullWidth size={'small'} />
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'unit'}
          control={control}
          defaultValue={product ? product.unit : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="หน่วยนับ"
              required
              fullWidth
              size={'small'}
            />
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'product_type'}
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <Autocomplete
              size={'small'}
              disableClearable
              {...field}
              onChange={(e, newValue) => {
                field.onChange(newValue);
                setReload(!reload);
              }}
              options={productType?.rows}
              getOptionLabel={(type) => `${type?.name}`}
              required
              renderInput={(params) => (
                <TextField {...params} label="ประเภทสินค้า" required />
              )}
            />
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'brand'}
          control={control}
          rules={{ required: false }}
          render={({ field }) => (
            <Autocomplete
              size={'small'}
              {...field}
              onChange={(e, newValue) => {
                field.onChange(newValue);
                setReload(!reload);
              }}
              options={brand}
              getOptionLabel={(type) => `${type?.name}`}
              renderOption={(props, type) => (
                <div className="flex gap-2" {...props}>
                  <div className="my-auto pl-2">{`${type?.name}`}</div>
                </div>
              )}
              renderInput={(params) => <TextField {...params} label="แบรนด์" />}
            />
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'minimum_inventory'}
          control={control}
          defaultValue={product ? product.minimum_inventory : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="จำนวนแจ้งเตือนสินค้าใกล้หมด"
              required
              fullWidth
              type="number"
              size={'small'}
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name={'detail'}
          control={control}
          defaultValue={product ? product.detail : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="รายละเอียด"
              fullWidth
              multiline
              rows={3}
              size={'small'}
            />
          )}
        />
      </div>
    </div>
  );
}

ProductForm.propTypes = {
  errors: PropTypes.shape({
    name: PropTypes.string,
    unit: PropTypes.string,
    packing_size: PropTypes.number,
    packing_unit: PropTypes.string,
    inventory: PropTypes.number,
    price: PropTypes.number,
    unit_net: PropTypes.string,
    detail: PropTypes.string,
    type_code: PropTypes.string,
    option: PropTypes.string,
    model: PropTypes.string,
  }),
  product: PropTypes.object,
  productType: PropTypes.object,
  brand: PropTypes.array,
  Controller: PropTypes.func,
  control: PropTypes.object,
  productDescribe: PropTypes.string,
  model: PropTypes.string,
  setProductDescribe: PropTypes.func,
};

ProductForm.defaultProps = {
  product: null,
  productType: null,
};

export default ProductForm;
