import {
  PRODUCT_TRANSACTION_RETURN_LOG_ALL,
  PRODUCT_TRANSACTION_RETURN_LOG_GET,
  PRODUCT_TRANSACTION_RETURN_LOG_ERROR,
  PRODUCT_TRANSACTION_RETURN_LOG_RESET,
} from '../types';

import api from '../../../utils/functions/api';

export const productTransactionReturnLogAll = (params) => async (dispatch) => {
  try {
    const {
      name = '',
      size = '',
      page = 1,
      transactionType = '',
      startDate = '',
      endDate = '',
      DashBoardDaily = '',
      DashBoardPage = false,
      selectDate = '',
    } = params;
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/product-transaction-return-log?name=${name}&size=${size}&page=${page}&type=${transactionType}&startDate=${startDate}&endDate=${endDate}&DashBoardDaily=${DashBoardDaily}&DashBoardPage=${DashBoardPage}&selectDate=${selectDate}`,
    );
    if (status === 200) {
      dispatch({ type: PRODUCT_TRANSACTION_RETURN_LOG_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: PRODUCT_TRANSACTION_RETURN_LOG_ERROR });
    throw new Error(error);
  }
};

export const productTransactionReturnLogReset = () => async (dispatch) => {
  try {
    const data = [];
    dispatch({ type: PRODUCT_TRANSACTION_RETURN_LOG_RESET, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: PRODUCT_TRANSACTION_RETURN_LOG_ERROR });
    throw new Error(error);
  }
};

export const productTransactionReturnLogGet = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/product-transaction-return-log/${id}`,
    );
    if (status === 200) {
      dispatch({ type: PRODUCT_TRANSACTION_RETURN_LOG_GET, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: PRODUCT_TRANSACTION_RETURN_LOG_ERROR });
    throw new Error(error);
  }
};
