import _ from 'lodash';
import React, { useState, useEffect, useRef } from 'react';
import Chart from 'react-apexcharts';
import PropTypes from 'prop-types';
import {
  Button,
  Modal,
  Fade,
  Card,
  Backdrop,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  TextField,
  InputAdornment,
  Box,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  boxShadow: 24,
  p: 4,
};

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 20,
  borderRadius: 10,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 10,
    backgroundColor: theme.palette.mode === 'light' ? '#ff7d1a' : '#308fe8',
  },
}));

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

export function ProductDonutOnline({
  product,
  productTransaction,
  paymentTransaction,
  loading,
  setLoading,
}) {
  const textInput = useRef(null);
  const [dataStack, setDataStack] = useState([]);
  const [labelStack, setLabelStack] = useState([]);
  const [orderStack, setOderStack] = useState([]);
  const [productName, setProductName] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [checked, setChecked] = useState([]);
  const [left, setLeft] = useState(product);
  const [right, setRight] = useState([]);
  const [renderGraph, setRenderGraph] = useState(true);
  const [name, setName] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    setLeft(product);
    const delayDebounceFn = setTimeout(() => {
      setName(searchTerm);
    }, 100);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm, product]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  // console.log('left', left);
  // console.log('right', right);

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleAllRight = () => {
    setSearchTerm('');
    textInput.current.value = '';
    setRight(right.concat(left));
    setLeft([]);
  };

  const handleCheckedRight = () => {
    setSearchTerm('');
    textInput.current.value = '';
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setSearchTerm('');
    textInput.current.value = '';
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleAllLeft = () => {
    setSearchTerm('');
    textInput.current.value = '';
    setLeft(left.concat(right));
    setRight([]);
  };

  useEffect(() => {
    // find labelStack
    if (_.isArray(left) && renderGraph) {
      const tempLabelStack = _.map(left, (eachProduct) => eachProduct.name);
      setLabelStack(tempLabelStack);
      setRenderGraph(false);

      const nameOfProduct = _.map(left, (eachProduct) => eachProduct.name);
      // console.log('nameOfProduct', nameOfProduct);
      setProductName(nameOfProduct);

      const filterPaymentCompleted = _.filter(
        paymentTransaction?.rows,
        (eachPayment) => eachPayment.status_order === 'PRODUCT_COMPLETE',
      );

      // find order stack
      const stackData = orderStack;
      _.map(filterPaymentCompleted, (log) => {
        _.map(log.order_id.orders, (order) => {
          const Data = {
            order,
            // eslint-disable-next-line no-underscore-dangle
            customer: log.customer._id,
            date: log.updatedAt,
          };
          stackData.push(Data);
        });
      });
      setOderStack(stackData);
      // console.log('stackData', stackData);
      // console.log('orderStack', orderStack);

      // find group of product
      const orderByProduct = _.groupBy(
        stackData,
        // eslint-disable-next-line no-underscore-dangle
        (order) => order.order.product,
      );
      // console.log('orderByProduct', orderByProduct);

      // find sum of amount
      const amountOfProduct = _.map(product, (eachProduct) => {
        // eslint-disable-next-line no-underscore-dangle
        const orderOfProduct = orderByProduct[eachProduct._id];

        const sumAmount = _.sum(
          _.map(orderOfProduct, (order) => order?.order?.quantity) || 0,
        );
        return sumAmount;
      });

      setDataStack(amountOfProduct);
      _.remove(stackData);
    }
    setLoading(true);
    setRenderGraph(false);
    setTimeout(() => {
      setRenderGraph(true);
    }, 100);

    return () => {};
  }, [_.size(left), _.size(right), product, productTransaction]);

  const renderSearch = () => (
    <Card>
      <div className="p-4 w-full">
        <div className="w-full">
          <TextField
            label="ค้นหา"
            fullWidth
            inputRef={textInput}
            size={'small'}
            id="outlined-start-adornment"
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <i className="fas fa-search"></i>
                </InputAdornment>
              ),
            }}
          />
        </div>
      </div>
    </Card>
  );

  const customList = (items) => (
    <div className="h-96 overflow-y-auto w-full">
      <List dense component="div" role="list">
        {items &&
          items
            .filter((namess) => namess.name.includes(name))
            .map((value) => {
              console.log();
              // const labelId = `transfer-list-item-${value}-label`;

              return (
                <ListItem
                  key={value.id}
                  role="listitem"
                  button
                  onClick={handleToggle(value)}
                >
                  <ListItemIcon>
                    <Checkbox
                      checked={checked.indexOf(value) !== -1}
                      tabIndex={-1}
                      disableRipple
                    />
                  </ListItemIcon>
                  <ListItemText id={value.id} primary={value.name} />
                </ListItem>
              );
            })}
        <ListItem />
      </List>
    </div>
  );

  const chartSeries = dataStack;

  const chartOptions = {
    options: {
      theme: {
        monochrome: {
          enabled: false,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: '100%',
            },
            legend: {
              show: false,
            },
          },
        },
      ],
    },
    dataLabels: {
      style: {
        fontSize: '15px',
        fontWeight: 'bold',
      },
    },
    labels: productName,
  };
  // console.log('productName', productName);
  // console.log('product', product);
  // console.log('productTransaction', productTransaction.rows);
  // console.log('orderStack', orderStack);

  const renderModal = () => (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={openModal}
      onClose={handleCloseModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openModal}>
        <Card sx={style} className="w-10/12">
          <div className="w-full text-center text-xl">
            เลือกสินค้าที่จะเเสดงบนกราฟ
          </div>
          <div className="pt-2 w-full">
            <Grid
              container
              spacing={2}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item className="w-full lg:w-1/3">
                <div className="border-2 p-2 text-center rounded-md text-white bg-yellow-600">
                  สินค้าที่เเสดง
                </div>
                {renderSearch()}
                <div className="border-2 rounded-md">
                  {loading && customList(left)}
                </div>
              </Grid>
              <Grid item className="w-full lg:w-1/6 h-full overflow-auto">
                <Grid container direction="column" alignItems="center">
                  <Button
                    sx={{ my: 0.5 }}
                    variant="outlined"
                    size="small"
                    onClick={handleAllRight}
                    disabled={left?.length === 0}
                    aria-label="move all right"
                  >
                    ≫
                  </Button>
                  <Button
                    sx={{ my: 0.5 }}
                    variant="outlined"
                    size="small"
                    onClick={handleCheckedRight}
                    disabled={leftChecked?.length === 0}
                    aria-label="move selected right"
                  >
                    &gt;
                  </Button>
                  <Button
                    sx={{ my: 0.5 }}
                    variant="outlined"
                    size="small"
                    onClick={handleCheckedLeft}
                    disabled={rightChecked?.length === 0}
                    aria-label="move selected left"
                  >
                    &lt;
                  </Button>
                  <Button
                    sx={{ my: 0.5 }}
                    variant="outlined"
                    size="small"
                    onClick={handleAllLeft}
                    disabled={right?.length === 0}
                    aria-label="move all left"
                  >
                    ≪
                  </Button>
                </Grid>
              </Grid>
              <Grid item className="w-full lg:w-1/3 h-full">
                <div className="border-2 p-2 text-center rounded-md text-white bg-yellow-600">
                  สินค้าที่ไม่เเสดง
                </div>
                {renderSearch()}
                <div className="border-2 rounded-md">
                  {loading && customList(right)}
                </div>
              </Grid>
            </Grid>
          </div>
          <div className="flex justify-end pt-2 ">
            <Button variant="contained" onClick={handleCloseModal}>
              ปิด
            </Button>
          </div>
        </Card>
      </Fade>
    </Modal>
  );

  if (loading) {
    return (
      <>
        <div>{loading && renderModal()}</div>
        <div className="xl:grid xl:justify-items-stretch">
          {/* graphic area */}
          {!_.isEmpty(labelStack) && renderGraph ? (
            <div className="w-full xl:w-1/2 xl:justify-self-center">
              <Chart options={chartOptions} series={chartSeries} type="pie" />
            </div>
          ) : (
            <Box sx={{ width: '100%' }}>
              <BorderLinearProgress />
            </Box>
          )}
        </div>
        <div className="text-center pt-2">
          <Button variant="contained" onClick={handleOpenModal}>
            จัดการข้อมูลที่เเสดง
          </Button>
        </div>
      </>
    );
  }
  return <></>;
}

ProductDonutOnline.propTypes = {
  product: PropTypes.array,
  productTransaction: PropTypes.object,
  paymentTransaction: PropTypes.object,
  loading: PropTypes.bool,
  setLoading: PropTypes.func,
};

ProductDonutOnline.defaultProps = {
  product: null,
  productTransaction: null,
  paymentTransaction: null,
};

export default ProductDonutOnline;
