import {
  Card,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';
import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

export function TableStatus({
  product,
  productTransaction,
  customer,
  loading,
  periodText,
  productTransactionExport,
}) {
  const [customerStack, setCustomerStack] = useState([]);
  const [customerStackExport, setCustomerStackExport] = useState([]);
  const [orderType, setOrderType] = useState('IMPORT');
  const [orderProductType, setOrderProductType] = useState('IMPORT');

  // console.log('customerStack in table', customerStack);
  // console.log('customerStackExport in table', customerStackExport);

  const NewProductOut = _.filter(
    product,
    (each) => each?.number_of_sales_offline_out !== 0,
  );

  const NewProductIn = _.filter(
    product,
    (each) => each?.number_of_sales_offline_in !== 0,
  );

  console.log('NewProductOut', NewProductOut);
  console.log('NewProductIn', NewProductIn);

  // console.log('periodText', periodText);

  useEffect(() => {
    // find group of product
    // console.log('orderByProduct', orderByProduct);
    // find group of customer
    const orderByCustomer = _.groupBy(
      productTransaction.rows,
      // eslint-disable-next-line no-underscore-dangle
      (order) => order?.customer?._id,
    );
    // console.log('orderByCustomer', orderByCustomer);

    // find sum price each customer
    const priceOfCustomer = _.map(customer, (eachCustomer) => {
      // eslint-disable-next-line no-underscore-dangle
      const orderOfCustomer = orderByCustomer[eachCustomer._id];
      // console.log('orderOfCustomer', orderOfCustomer);
      const sumAmount = {
        customer: eachCustomer,
        amount: _.sum(
          _.map(orderOfCustomer, (order) => {
            const sumPrice = _.sum(
              _.map(order.orders, (eachOrder) => eachOrder?.amount),
            );
            // console.log('sumPrice', sumPrice);
            return sumPrice;
          }) || 0,
        ),
      };
      // console.log('sumAmount', sumAmount);
      return sumAmount;
    });

    // console.log('priceOfCustomer', priceOfCustomer);

    const newPriceOfCustomer = _.filter(
      priceOfCustomer,
      (each) => each?.amount !== 0,
    );

    setCustomerStack(newPriceOfCustomer);

    return () => {};
  }, [productTransaction.rows.length]);

  useEffect(() => {
    // find group of product
    // console.log('orderByProduct', orderByProduct);
    // find group of customer
    const orderByCustomer = _.groupBy(
      productTransactionExport.rows,
      // eslint-disable-next-line no-underscore-dangle
      (order) => order?.customer?._id,
    );
    // console.log('orderByCustomer', orderByCustomer);

    // find sum price each customer
    const priceOfCustomer = _.map(customer, (eachCustomer) => {
      // eslint-disable-next-line no-underscore-dangle
      const orderOfCustomer = orderByCustomer[eachCustomer._id];
      // console.log('orderOfCustomer', orderOfCustomer);
      const sumAmount = {
        customer: eachCustomer,
        amount: _.sum(
          _.map(orderOfCustomer, (order) => {
            const sumPrice = _.sum(
              _.map(order.orders, (eachOrder) => eachOrder?.amount),
            );
            // console.log('sumPrice', sumPrice);
            return sumPrice;
          }) || 0,
        ),
      };
      // console.log('sumAmount', sumAmount);
      return sumAmount;
    });

    // console.log('priceOfCustomer', priceOfCustomer);

    const newPriceOfCustomer = _.filter(
      priceOfCustomer,
      (each) => each?.amount !== 0,
    );

    setCustomerStackExport(newPriceOfCustomer);

    return () => {};
  }, [productTransactionExport.rows.length]);

  const renderTableCustomer = () => (
    <>
      <Paper>
        <TableContainer component={Paper}>
          <Table size="small" className="border-lg">
            <colgroup>
              <col width="20%" />
              <col width="60%" />
              <col width="20%" />
            </colgroup>
            <TableHead className="bg-yellow-300">
              <TableRow>
                <TableCell>
                  <div className="font-bold py-2">อันดับ</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ชื่อลูกค้า</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ยอดนำเข้า</div>
                </TableCell>
              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>
      </Paper>
      <div className="max-h-96 overflow-y-auto">
        <Paper>
          <TableContainer component={Paper}>
            <Table size="small" className="border-lg">
              <TableBody>
                {_.map(
                  _.sortBy(
                    orderType === 'IMPORT'
                      ? customerStack
                      : customerStackExport,
                    ['customer', 'amount'],
                  )
                    .reverse()
                    .slice(0, 10),
                  (EachCustomer, index) => (
                    <TableRow
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      // className={index % 2 === 0 ? 'bg-gray-100' : ''}
                      className="hover:bg-yellow-100 py-2"
                    >
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>
                        {!loading ? (
                          <div className="font-bold my-auto py-2">
                            {EachCustomer?.customer?.name}
                          </div>
                        ) : (
                          'กำลังคำนวณ...'
                        )}
                      </TableCell>
                      <TableCell>
                        <div className="text-center">
                          {!loading && EachCustomer.amount}
                        </div>
                      </TableCell>
                    </TableRow>
                  ),
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </div>
    </>
  );

  const handleChangeOrderType = (value) => {
    if (value === 'IMPORT') {
      setOrderType('IMPORT');
    } else {
      setOrderType('EXPORT');
    }
  };

  const renderTopCustomers = () => (
    <Card className="p-6">
      <div className="">
        <div className="pb-2 text-xl flex justify-between">
          <div>รายการลูกค้าส่งออกมากที่สุด</div>
          <div>
            <div className="flex justify-start">
              <div className="bg-white">
                <FormControl sx={{ minWidth: 200 }}>
                  <InputLabel id="type" size={'small'}>
                    เลือกรายการ
                  </InputLabel>
                  <Select
                    label="เลือกรายการ"
                    size={'small'}
                    fullWidth
                    defaultValue="IMPORT"
                    onChange={(e) => {
                      handleChangeOrderType(e.target.value);
                    }}
                  >
                    <MenuItem value="IMPORT">การนำเข้า</MenuItem>
                    <MenuItem value="EXPORT">การส่งออก</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>
        </div>
        {renderTableCustomer()}
      </div>
    </Card>
  );

  const handleChangeOrderProductType = (value) => {
    if (value === 'IMPORT') {
      setOrderProductType('IMPORT');
    } else {
      setOrderProductType('EXPORT');
    }
  };

  const renderTableProduct = () => (
    <>
      <Paper>
        <TableContainer component={Paper}>
          <Table size="small" className="border-lg">
            <colgroup>
              <col width="20%" />
              <col width="80%" />
            </colgroup>
            <TableHead className="bg-yellow-300">
              <TableRow>
                <TableCell>
                  <div className="font-bold py-2">อันดับ</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ชื่อสินค้า</div>
                </TableCell>
                {/* <TableCell>
                  <div className="font-bold">
                    {orderType === 'IMPORT' ? <>ยอดนำเข้า</> : <>ยอดส่งออก</>}
                  </div>
                </TableCell> */}
              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>
      </Paper>
      <div className="max-h-96 overflow-y-auto">
        <Paper>
          <TableContainer component={Paper}>
            <Table size="small" className="border-lg">
              {orderProductType === 'IMPORT' ? (
                <TableBody>
                  {_.map(
                    _.sortBy(NewProductOut, ['number_of_product_export'])
                      .reverse()
                      .slice(0, 10),
                    (EachProduct, index) => (
                      <TableRow
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                        // className={index % 2 === 0 ? 'bg-gray-100' : ''}
                        className="hover:bg-yellow-100 py-2 "
                      >
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>
                          {!loading ? (
                            <div className="font-bold my-auto py-2">
                              {EachProduct?.name}
                            </div>
                          ) : (
                            'กำลังคำนวณ...'
                          )}
                        </TableCell>
                        {/* <TableCell>
                          <div className="text-center">
                            {!loading && EachProduct?.number_of_product_export}
                          </div>
                        </TableCell> */}
                      </TableRow>
                    ),
                  )}
                </TableBody>
              ) : (
                <TableBody>
                  {_.map(
                    _.sortBy(NewProductIn, ['number_of_product_import'])
                      .reverse()
                      .slice(0, 10),
                    (EachProduct, index) => (
                      <TableRow
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                        // className={index % 2 === 0 ? 'bg-gray-100' : ''}
                        className="hover:bg-yellow-100 py-2 "
                      >
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>
                          {!loading ? (
                            <div className="font-bold my-auto py-2">
                              {EachProduct?.name}
                            </div>
                          ) : (
                            'กำลังคำนวณ...'
                          )}
                        </TableCell>
                        <TableCell>
                          <div className="text-center">
                            {!loading && EachProduct?.number_of_product_import}
                          </div>
                        </TableCell>
                      </TableRow>
                    ),
                  )}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Paper>
      </div>
    </>
  );

  const renderTopProduct = () => (
    <Card className="p-6">
      <div className="">
        <div className="pb-2 text-xl flex justify-between">
          <div>รายการสินค้าส่งออกมากที่สุด</div>
          <div>
            <div className="flex justify-start">
              <div className="bg-white">
                <FormControl sx={{ minWidth: 200 }}>
                  <InputLabel id="type" size={'small'}>
                    เลือกรายการ
                  </InputLabel>
                  <Select
                    label="เลือกรายการ"
                    size={'small'}
                    fullWidth
                    defaultValue="IMPORT"
                    onChange={(e) => {
                      handleChangeOrderProductType(e.target.value);
                    }}
                  >
                    <MenuItem value="IMPORT">การนำเข้า</MenuItem>
                    <MenuItem value="EXPORT">การส่งออก</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>
        </div>
        {renderTableProduct()}
      </div>
    </Card>
  );

  if (!loading) {
    return (
      <>
        {renderTopCustomers()}
        {renderTopProduct()}
      </>
    );
  }
  return (
    <>
      {renderTopCustomers()}
      {renderTopProduct()}
    </>
  );
}

TableStatus.propTypes = {
  product: PropTypes.array,
  productTransaction: PropTypes.object,
  productTransactionExport: PropTypes.object,
  customer: PropTypes.object,
  startDate: PropTypes.text,
  endDate: PropTypes.text,
  loading: PropTypes.bool,
  periodText: PropTypes.string,
};

TableStatus.defaultProps = {
  product: null,
  productTransaction: null,
  productTransactionExport: null,
  customer: null,
  startDate: null,
  endDate: null,
  loading: null,
};

export default TableStatus;
