import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Card,
  TextField,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
// import * as actions from '../../redux/actions';
import Loading from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';

function MaterialHistory({ title, subtitle }) {
  // const dispatch = useDispatch();
  const materialTransaction = useSelector((state) => state.materialTransaction);
  const [searchTerm, setSearchTerm] = useState('');
  // const [name, setName] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(5);
  const [total, setTotal] = useState(undefined);

  // useEffect(() => {
  //   dispatch(
  //     actions.materialTransactionAll({ materialName: name, page, size }),
  //   );
  //   return () => {};
  // }, [name, page, size]);

  useEffect(() => {
    setTotal(materialTransaction?.total);
    return () => {};
  }, [materialTransaction]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      // setName(searchTerm);
      setPage(1);
    }, 700);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const renderSearch = () => (
    <Card>
      <div className="p-4 flex flex-row">
        <div className="w-full md:w-1/2">
          <TextField
            label="ค้นหา"
            fullWidth
            size={'small'}
            id="outlined-start-adornment"
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <i className="fas fa-search"></i>
                </InputAdornment>
              ),
            }}
          />
        </div>
      </div>
    </Card>
  );

  const renderTable = () => (
    <div className="my-2">
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <div className="font-bold">ลำดับที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> วันที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ประเภทการดำเนินการ</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ชื่อคลัง</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> วัตถุดิบ</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> จำนวน</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(materialTransaction.rows) ? (
                materialTransaction.rows.map((row, index) => (
                  <TableRow
                    key={row.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {(page - 1) * size + index + 1}
                    </TableCell>
                    <TableCell>
                      {new Date(
                        row?.material_stock_lot?.recording_date,
                      ).toLocaleDateString('th', {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric',
                      })}
                    </TableCell>
                    <TableCell>{`${row?.material_transaction_type?.name}`}</TableCell>
                    <TableCell>{`${row?.place?.name}`}</TableCell>
                    <TableCell>{`${row?.material_stock_lot?.material?.name}`}</TableCell>
                    <TableCell>{`${row?.quantity}`}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <div className="text-center">ไม่มีข้อมูล</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          rowsPerPageOptions={[]}
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={page - 1}
          count={total || 1}
          rowsPerPage={size}
          onPageChange={handleChangePage}
        />
      </Paper>
    </div>
  );

  if (materialTransaction.isLoading) {
    return <Loading />;
  }
  if (!materialTransaction.isLoading && materialTransaction.isCompleted) {
    return (
      <div>
        <div className="flex justify-between">
          <div>{renderTitle()}</div>
        </div>{' '}
        {renderSearch()}
        {renderTable()}
      </div>
    );
  }
  return <Error message={materialTransaction?.message} />;
}

MaterialHistory.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

MaterialHistory.defaultProps = {
  title: '',
  subtitle: '',
};

export default MaterialHistory;
