import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  TextField,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import _ from 'lodash';

const IncreaseOptionDialog = ({
  open,
  handleClose,
  filterProduct,
  fields,
  setValue,
  setSelectOption,
  selectOption,
}) => {
  const { control, getValues } = useForm();

  const [reload, setReload] = useState(false);
  useEffect(() => () => {}, [reload]);

  useEffect(() => {
    if (!_.isEmpty(filterProduct?.options)) {
      const optionArray = _.map(
        filterProduct?.options,
        (eachOption) => eachOption,
      );
      setValue('optionList', optionArray);
    }
    return () => {};
  }, [filterProduct]);

  const handleAddOption = () => {
    _.map(fields, (field, index) => {
      if (getValues(`optionList.${index}.inventory`)) {
        const payload = {
          product: filterProduct,
          options: field,
          quantity: parseInt(getValues(`optionList.${index}.inventory`), 10),
        };
        // console.log('filterProduct', filterProduct);
        // console.log('field', field);
        // console.log(getValues(`optionList.${index}.inventory`));
        selectOption.push(payload);
        setSelectOption(selectOption);
        // console.log('selectOption in loop', selectOption);
      }
    });

    setReload(!reload);
    handleClose();
  };

  console.log('fields', fields);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="form-dialog-title">
        <div className="flex justify-between">
          <div>เพิ่ม Option</div>
          <Button onClick={handleClose}>ปิด</Button>
        </div>
      </DialogTitle>

      <DialogContent>
        <div className="">
          <div className="p-2 bg-gray-600 text-white my-1 w-full">
            {filterProduct?.name}
          </div>
          {!_.isEmpty(fields) ? (
            _.map(fields, (item, index) => (
              <>
                <div className="flex" key={index}>
                  <div className="w-3/5 my-auto">
                    {index + 1} : {item?.name}
                  </div>
                  <div className="w-2/5  px-1 py-2">
                    <Controller
                      name={`optionList.${index}.inventory`}
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="จำนวน"
                          fullWidth
                          type="number"
                          size={'small'}
                          defaultValue={''}
                        />
                      )}
                    />
                  </div>
                </div>
              </>
            ))
          ) : (
            <></>
          )}
          <div className="flex justify-end">
            <Button variant="contained" onClick={() => handleAddOption()}>
              เพิ่ม
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default IncreaseOptionDialog;

IncreaseOptionDialog.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  filterProduct: PropTypes.object,
  fields: PropTypes.array,
  setValue: PropTypes.func,
  selectOption: PropTypes.object,
  setSelectOption: PropTypes.func,
};

IncreaseOptionDialog.defaultProps = {
  open: false,
};
