import {
  ONLINE_ORDER_ALL,
  ONLINE_ORDER_CREATE,
  ONLINE_ORDER_GET,
  ONLINE_ORDER_DEL,
  ONLINE_ORDER_PUT,
  ONLINE_ORDER_LOADING,
  ONLINE_ORDER_ERROR,
} from '../types';

import api from '../../../utils/functions/api';

export const onlineOrderCreate = (payload) => async (dispatch) => {
  try {
    console.log('payload in online order create', payload);
    dispatch({ type: ONLINE_ORDER_LOADING });
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/online-order`,
      {
        ...payload,
      },
    );
    dispatch({ type: ONLINE_ORDER_CREATE, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: ONLINE_ORDER_ERROR });
    throw new Error(error);
  }
};

export const onlineOrderAll = (params) => async (dispatch) => {
  try {
    const {
      name = '',
      size = '',
      page = 1,
      startDate = '',
      endDate = '',
      customerId = '',
      checkPaymentOrder = false,
      selectPaymentType = '',
      selectOrderStatus = '',
    } = params;
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/online-order?name=${name}&size=${size}&page=${page}&startDate=${startDate}&endDate=${endDate}&customerId=${customerId}&checkPaymentOrder=${checkPaymentOrder}&selectPaymentType=${selectPaymentType}&selectOrderStatus=${selectOrderStatus}`,
    );
    if (status === 200) {
      dispatch({ type: ONLINE_ORDER_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: ONLINE_ORDER_ERROR });
    throw new Error(error);
  }
};

export const onlineOrderGet = (id, number) => async (dispatch) => {
  try {
    console.log('number in redux payment', number);
    dispatch({ type: ONLINE_ORDER_LOADING });
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/online-order/${id}`,
    );
    if (status === 200) {
      dispatch({ type: ONLINE_ORDER_GET, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: ONLINE_ORDER_ERROR });
    throw new Error(error);
  }
};

export const onlineOrderPut = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: ONLINE_ORDER_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/online-order/${id}`,
      payload,
    );
    dispatch({ type: ONLINE_ORDER_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: ONLINE_ORDER_ERROR });
    throw new Error(error);
  }
};
export const onlineOrderDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: ONLINE_ORDER_LOADING });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/online-order/${id}`,
    );
    dispatch({ type: ONLINE_ORDER_DEL, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: ONLINE_ORDER_ERROR });
    throw new Error(error);
  }
};
