/* eslint-disable react/prop-types */
/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
// eslint-disable-next-line object-curly-newline
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Card,
  TextField,
  InputAdornment,
  IconButton,
  Modal,
  Backdrop,
  Fade,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Checkbox,
} from '@mui/material';

import AddBoxIcon from '@mui/icons-material/AddBox';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CancelIcon from '@mui/icons-material/Cancel';
import LogoutIcon from '@mui/icons-material/Logout';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SaveIcon from '@mui/icons-material/Save';

import { useForm, Controller, useFieldArray } from 'react-hook-form';
import dayjs from 'dayjs';
import _ from 'lodash';
import 'dayjs/locale/th';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import PropTypes from 'prop-types';
import * as actions from '../../redux/actions';
import Loading from '../../components/Loading';
import { Error, NotAuthorized } from '../../components/Error';
import accessSubModule from '../../utils/functions/accessSubModule';
import { ViewTitle } from '../../components/ViewTitle';
import { ProductEditExportAmountForm } from '../../components/Forms';

dayjs.locale('th');
dayjs.extend(buddhistEra);

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90vw',
  boxShadow: 24,
  p: 4,
};

const ExportsManagement = ({ title, subtitle }) => {
  const module = 'SPM';
  const subModule = 'IMPORT';
  const dispatch = useDispatch();
  const productTransactionExport = useSelector(
    (state) => state.productTransactionExport,
  );
  const me = useSelector((state) => state.me);

  const history = useHistory();
  // const product = useSelector((state) => state.product);
  console.log('productTransaction', productTransactionExport.rows);

  // Setting React hook form
  const [searchTerm, setSearchTerm] = useState('');
  const [name, setName] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(5);
  const [total, setTotal] = useState(undefined);
  const [openExportOrder, setOpenExportOrder] = useState(false);
  const [nameExport, setNameExport] = useState('');
  const [exportId, setExportId] = useState(null);
  const [transactionType, setTypeDocText] = useState('ALL');
  const [statusDocText, setStatusDocText] = useState('ALL');
  const [sortExportBill, setSortExportBill] = useState('ASC');

  const [visibleOrders, setVisibleOrders] = useState(5); // แสดงคำสั่งซื้อ 5 รายการแรก
  const [openEditExportAmount, setOpenEditExportAmount] = useState(false);
  const [checkExportAll, setCheckExportAll] = useState(false);

  const [editExportAmount, setEditExportAmount] = useState(null);
  const [dataExportAmount, setDataExportAmount] = useState();
  const [uploadedImage, setUploadedImage] = useState(null);

  console.log('editExportAmount', editExportAmount);

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    watch,
  } = useForm({
    mode: 'onBlur',
  });

  const { fields, remove } = useFieldArray({
    control,
    name: 'optionList',
  });

  const handleOpenEditExportAmount = (data) => {
    setOpenEditExportAmount(true);
    if (!_.isEmpty(data?.orders)) {
      const optionArray = _.map(data?.orders, (eachOption) => ({
        ...eachOption,
        amount_export: 0,
      }));
      setValue('optionList', optionArray);
    }
    return () => {};
  };
  const handleCloseEditExportAmount = () => {
    setOpenEditExportAmount(false);
    setEditExportAmount(null);
    setUploadedImage(null);
    fields.forEach((e, index) => remove(index));
    setNameExport('');
    setCheckExportAll(false);
  };

  const loadMoreOrders = () => {
    setVisibleOrders((prev) => prev + 100); // โหลดเพิ่ม 5 รายการทุกครั้งที่กดปุ่ม
  };

  const loadMoreOrdersNa = () => {
    setVisibleOrders(5); // โหลดเพิ่ม 5 รายการทุกครั้งที่กดปุ่ม
  };
  console.log('sortExportBill', sortExportBill);

  // const transactionType = 'EXPORT';
  console.log('fields', fields);

  useEffect(() => {
    dispatch(actions.meGet());

    const fetchProductTransaction = async () => {
      try {
        // await dispatch(actions.productTransactionExportReset());
        await dispatch(
          actions.productTransactionExportAll({
            name,
            page,
            size,
            transactionType,
            statusDocText,
            sortExportBill,
          }),
        );
      } catch (error) {
        console.error(error);
      }
    };
    fetchProductTransaction();
    return () => {};
  }, [name, page, size, transactionType, statusDocText, sortExportBill]);

  useEffect(() => {
    setTotal(productTransactionExport?.total);
    return () => {};
  }, [productTransactionExport]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchTerm);
      setPage(1);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const handleDelete = async (id) => {
    const confirm = window.confirm('ยืนยันการลบข้อมูลส่งออก');
    if (confirm) {
      try {
        await dispatch(actions.productTransactionExportDelete(id));
        await dispatch(
          actions.productTransactionExportAll({
            name,
            page,
            size,
            transactionType,
            statusDocText,
          }),
        );
      } catch (error) {
        console.error(error);
      }
    }
  };

  console.log('me?.userData?._id', me?.userData?._id);

  // const handleUpdateStatusAll = async (id) => {
  //   const confirm = window.confirm('ยืนยันการจ่ายสินค้า');
  //   if (confirm) {
  //     try {
  //       await dispatch(
  //         actions.productTransactionExportPut(id, {
  //           status_export: 'SUCCESS',
  //           export_employee_real: me?.userData?._id,
  //           export_employee_real_string: nameExport,
  //           real_export_date: dayjs(new Date())
  //             .locale('th')
  //             .format('YYYY-MM-DD'),
  //         }),
  //       );
  //       await dispatch(
  //         actions.productTransactionExportAll({
  //           name,
  //           page,
  //           size,
  //           transactionType,
  //           statusDocText,
  //         }),
  //       );
  //       setNameExport('');
  //       setOpenExportOrder(false);
  //       setOpenEditExportAmount(false);
  //       setEditExportAmount(null);
  //       fields.forEach((e, index) => remove(index));
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   }
  // };

  const handleUpdateStatus = async (id) => {
    const confirm = window.confirm(
      'กรุณาตรวจจสอบการจ่ายสินค้า ก่อนกดจ่ายสำเร็จ',
    );
    if (confirm) {
      try {
        await dispatch(
          actions.productTransactionExportPut(id, {
            status_export: 'SUCCESS',
            type_edit: 'UPDATE_EXPORT_STATUS',
          }),
        );
        await dispatch(
          actions.productTransactionExportAll({
            name,
            page,
            size,
            transactionType,
            statusDocText,
          }),
        );
        setNameExport('');
        setOpenExportOrder(false);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const onSubmitEditExportAmount = async (data) => {
    try {
      console.log('onSubmitEditExportAmount data', data);
      console.log('onSubmitEditExportAmount fields', fields);
      console.log('onSubmitEditExportAmount nameExport', nameExport);
      if (checkExportAll) {
        await dispatch(
          actions.productTransactionExportPut(editExportAmount?.id, {
            status_export: 'SUCCESS',
            export_employee_real: me?.userData?._id,
            export_employee_real_string: nameExport,
            real_export_date: dayjs(new Date())
              .locale('th')
              .format('YYYY-MM-DD'),
          }),
        );
        await dispatch(
          actions.productTransactionExportAll({
            name,
            page,
            size,
            transactionType,
            statusDocText,
          }),
        );
        setNameExport('');
        setOpenExportOrder(false);
        setOpenEditExportAmount(false);
        setEditExportAmount(null);
        setUploadedImage(null);
        setCheckExportAll(false);
        fields.forEach((e, index) => remove(index));
      } else {
        const findError = _.find(
          data?.optionList,
          (each) =>
            // eslint-disable-next-line implicit-arrow-linebreak
            parseFloat(each.amount_export) +
              parseFloat(each.amount_export_real || 0) >
            each.amount,
        );

        if (findError) {
          alert('กรุณาตรวจสอบจำนวนการจ่าย');
        } else {
          const confirm = window.confirm('ยืนยันการจ่ายสินค้า');
          if (confirm) {
            try {
              console.log('editExportAmount', editExportAmount);
              console.log('editExportAmount data', data);
              await dispatch(
                actions.productTransactionExportPut(editExportAmount?.id, {
                  status_export: 'WAIT_FOR_EXPORT',
                  type_edit: 'UPDATE_EXPORT_AMOUNT',
                  orders: _.map(data?.optionList, (each) => ({
                    // eslint-disable-next-line no-underscore-dangle
                    product: each.product._id,
                    amount: each.amount,
                    amount_export:
                      parseFloat(each.amount_export) +
                      parseFloat(each.amount_export_real || 0),
                    amount_export_real: each.amount_export_real || 0,
                    warehouse: each.warehouse._id,
                    price: 0,
                    cost_price: 0,
                  })),
                  export_employee_real: me?.userData?._id,
                  export_employee_real_string: nameExport,
                  real_export_date: dayjs(new Date())
                    .locale('th')
                    .format('YYYY-MM-DD'),
                  images: _.map(uploadedImage, (image) => ({
                    // eslint-disable-next-line no-underscore-dangle
                    id: image._id,
                    image: image.data_url || image.url,
                  })),
                }),
              );
              await dispatch(
                actions.productTransactionExportAll({
                  name,
                  page,
                  size,
                  transactionType,
                  statusDocText,
                }),
              );
              setNameExport('');
              setOpenExportOrder(false);
              setOpenEditExportAmount(false);
              setEditExportAmount(null);
              setUploadedImage(null);
              fields.forEach((e, index) => remove(index));
              setCheckExportAll(false);
            } catch (error) {
              console.error(error);
            }
          }
        }
      }
    } catch (error) {
      alert(`เกิดความผิดพลาดในการทำงาน ${error.message}`);
    }
  };

  const handleChangeCheckExportAll = (event) => {
    setCheckExportAll(event.target.checked);
  };

  const handleOpenExportOrder = () => {
    setOpenExportOrder(true);
  };
  const handleCloseExportOrder = () => {
    setOpenExportOrder(false);
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleToInfoPage = async (id) => {
    history.push(`/spm/exports/${id}`);
  };

  const handleToEditPage = async (id) => {
    history.push(`/spm/export/edit/${id}`);
  };

  const handleChangeTypeDoc = (value) => {
    if (value === 'EXPORT') {
      setTypeDocText('EXPORT');
    } else if (value === 'RESERVE') {
      setTypeDocText('RESERVE');
    } else {
      setTypeDocText('ALL');
    }
  };

  const handleChangeStatusDoc = (value) => {
    if (value === 'SUCCESS') {
      setStatusDocText('SUCCESS');
    } else if (value === 'WAIT_FOR_EXPORT') {
      setStatusDocText('WAIT_FOR_EXPORT');
    } else {
      setStatusDocText('ALL');
    }
  };

  // console.log(
  //   'check export_amount',
  //   !_.find(editExportAmount?.orders, (e) => e?.amount_export_real > 0),
  // );

  const renderAddButton = () => (
    <div className="flex flex-row justify-end pb-4">
      <Link to="/spm/exports/create">
        <Button startIcon={<AddBoxIcon />} variant="contained">
          สร้างใบส่งออก
        </Button>
      </Link>
    </div>
  );

  const renderModalExportOrders = () => (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={openExportOrder}
      onClose={handleCloseExportOrder}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openExportOrder}>
        <Card sx={style} className="max-w-2xl my-4">
          <div className="max-h-screen overflow-y-auto">
            <div className="">
              <div className="text-xl pb-4 underline">ชื่อผู้รับสินค้าจริง</div>
              <div>
                <TextField
                  fullWidth
                  label="ชื่อผู้รับสินค้าจริง"
                  value={nameExport}
                  onChange={(event) => {
                    setNameExport(event.target.value);
                  }}
                />
              </div>
            </div>
            <div className="flex flex-row justify-end gap-1 py-4">
              <Button
                variant="contained"
                startIcon={<SaveIcon />}
                onClick={() => handleUpdateStatus(exportId)}
              >
                บันทึก
              </Button>
              <Button variant="outlined" onClick={handleCloseExportOrder}>
                ปิด
              </Button>
            </div>
          </div>
        </Card>
      </Fade>
    </Modal>
  );

  const renderModalEditExportAmount = () => (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={openEditExportAmount}
      onClose={handleCloseEditExportAmount}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openEditExportAmount}>
        <Card sx={style} className="max-w-6xl my-8">
          <div className="max-h-screen overflow-y-auto">
            <div className="p-2 bg-gray-600 text-white m-1 w-full">
              จำนวนการจ่าย
            </div>
            <div className="py-2">
              <form onSubmit={handleSubmit(onSubmitEditExportAmount)}>
                <ProductEditExportAmountForm
                  errors={errors}
                  product={editExportAmount}
                  control={control}
                  Controller={Controller}
                  setValue={setValue}
                  fields={fields}
                  watch={watch}
                  checkExportAll={checkExportAll}
                />
                <div className="">
                  <div className="text-lg pb-4 underline">
                    ชื่อผู้รับสินค้าจริง
                  </div>
                  <div>
                    <TextField
                      fullWidth
                      label="ชื่อผู้รับสินค้าจริง"
                      value={nameExport}
                      size="small"
                      defaultValue={
                        editExportAmount?.export_employee_real_string
                      }
                      onChange={(event) => {
                        setNameExport(event.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="flex flex-row justify-end gap-1 py-4">
                  {/* <Button
                    variant="contained"
                    startIcon={<SaveIcon />}
                    onClick={() => handleUpdateStatusAll(editExportAmount?.id)}
                  >
                    บันทึก ( กรณีจ่ายทั้งหมด )
                  </Button> */}
                  {!_.find(
                    editExportAmount?.orders,
                    (e) => e?.amount_export_real > 0,
                  ) && (
                    <>
                      <Checkbox
                        checked={checkExportAll}
                        onChange={handleChangeCheckExportAll}
                      />
                      <div className="my-auto mr-4">จ่ายทั้งหมด</div>
                    </>
                  )}

                  <Button
                    variant="contained"
                    startIcon={<SaveIcon />}
                    type="submit"
                    // onClick={() => handleUpdateStatus(exportId)}
                  >
                    บันทึก
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={handleCloseEditExportAmount}
                  >
                    ปิด
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </Card>
      </Fade>
    </Modal>
  );

  const renderSearch = () => (
    <Card>
      <div className="p-4 flex gap-2">
        <div className="w-full md:w-1/2">
          <TextField
            label="ค้นหา"
            fullWidth
            size={'small'}
            id="outlined-start-adornment"
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <i className="fas fa-search"></i>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className="w-full flex gap-2 md:w-1/2">
          <div className="w-1/2">
            <FormControl className="w-full">
              <InputLabel id="type" size={'small'}>
                เลือกรายการแสดง
              </InputLabel>
              <Select
                label="เลือกรายการแสดง"
                size={'small'}
                fullWidth
                defaultValue="ALL"
                onChange={(e) => {
                  handleChangeTypeDoc(e.target.value);
                }}
              >
                <MenuItem value="ALL">แสดงทั้งหมด</MenuItem>
                <MenuItem value="EXPORT">การส่งออก</MenuItem>
                <MenuItem value="RESERVE">การจองสินค้า</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="w-1/2">
            <FormControl className="w-full">
              <InputLabel id="type" size={'small'}>
                เลือกสถานะส่งออก
              </InputLabel>
              <Select
                label="เลือกสถานะส่งออก"
                size={'small'}
                fullWidth
                defaultValue="ALL"
                onChange={(e) => {
                  handleChangeStatusDoc(e.target.value);
                }}
              >
                <MenuItem value="ALL">แสดงทั้งหมด</MenuItem>
                <MenuItem value="SUCCESS">รับสินค้าแล้ว</MenuItem>
                <MenuItem value="WAIT_FOR_EXPORT">รอจ่ายสินค้า</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
        {/* <div>
          <Button onClick={() => setSortExportBill('DESC')}>SORT</Button>
        </div> */}
      </div>
    </Card>
  );

  const OrderComponent = ({ eachOrder, _index }) => (
    <div key={_index} className="w-full py-1">
      <div className="flex justify-between">
        <p className="">
          {_index + 1} : {eachOrder?.product?.model_name}
        </p>{' '}
        <div className="">{eachOrder?.warehouse?.name}</div>{' '}
        <div className="flex gap-1">
          <p className="">{eachOrder?.amount}</p>
          <p className=" ">{eachOrder?.product?.unit}</p>
        </div>
      </div>
    </div>
  );

  const renderTable = () => (
    <div className="my-2">
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <colgroup>
              <col width="5%" />
              <col width="10%" />
              <col width="10%" />
              <col width="35%" />
              <col width="10%" />
              <col width="10%" />
              <col width="10%" />
              <col width="10%" />
              <col width="10%" />
            </colgroup>
            <TableHead>
              <TableRow>
                <TableCell>
                  <div className="font-bold">ลำดับที่</div>
                </TableCell>
                {/* <TableCell>
                  <div className="font-bold">หมายเลขเอกสาร</div>
                </TableCell> */}
                <TableCell>
                  <div className="font-bold">หมายเลขโครงการ/บิล</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">คู่ค้า/ผู้จอง</div>
                </TableCell>
                {/* <TableCell>
                  <div className="font-bold">ชื่อสินค้า</div>
                </TableCell> */}
                <TableCell>
                  <div className="font-bold">
                    รุ่นสินค้า / คลัง / จำนวนสินค้า
                  </div>
                </TableCell>
                {/* <TableCell>
                  <div className="font-bold">คลัง</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">จำนวนสินค้า</div>
                </TableCell> */}
                <TableCell>
                  <div className="font-bold">สถานะ</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">วันที่จัดสินค้า</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">วันที่ส่งออก</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">ผู้ส่งออก</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">ดำเนินการ</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(productTransactionExport.rows) ? (
                productTransactionExport.rows.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    className={index % 2 === 0 ? 'bg-gray-100' : ''}
                  >
                    <TableCell component="th" scope="row">
                      <IconButton
                        color="primary"
                        // disabled={row?.status_export === 'SUCCESS'}
                      >
                        <EditOutlinedIcon
                          onClick={() => handleToEditPage(row?.id)}
                        />
                      </IconButton>
                      {(page - 1) * size + index + 1}
                    </TableCell>
                    {/* <TableCell
                      component="th"
                      scope="row"
                      className="text-center"
                    >
                      {row?.reserve_order && (
                        <div className="rounded-md bg-blue-400 p-1 text-center mb-1">
                          จองสินค้า
                        </div>
                      )}
                      <a
                        className="hover:underline cursor-pointer w-full text-center"
                        onClick={() => handleToInfoPage(row?.id)}
                      >
                        {row?.running_number || '-'}
                      </a>
                    </TableCell> */}
                    <TableCell component="th" scope="row">
                      {row?.reserve_order && (
                        <div className="rounded-md bg-blue-400 p-1 text-center mb-1">
                          จองสินค้า
                        </div>
                      )}
                      <div>{row?.project_number || '-'}</div>
                      <div>{row?.bill_number || '-'}</div>
                    </TableCell>
                    <TableCell onClick={() => handleToInfoPage(row?.id)}>
                      <a className="hover:underline cursor-pointer">
                        {row?.customer?.name || '-'}
                      </a>
                      <div
                        className="hover:underline cursor-pointer"
                        onClick={() => handleToInfoPage(row?.id)}
                      >
                        {row?.reserve_employee || '-'}
                      </div>
                    </TableCell>
                    {/* <TableCell>
                      {row?.orders?.map((eachOrder, _index) => (
                        <div key={_index} className="w-full py-1">
                          <div className="flex">
                            <p className="">
                              {_index + 1} : {eachOrder?.product?.name}
                            </p>
                          </div>
                        </div>
                      ))}
                    </TableCell> */}
                    <TableCell>
                      {row?.orders
                        ?.slice(0, visibleOrders)
                        .map((eachOrder, _index) => (
                          <OrderComponent
                            key={_index}
                            eachOrder={eachOrder}
                            _index={_index}
                          />
                        ))}
                      {row?.orders?.length > 5 && (
                        <>
                          {visibleOrders === 105 ? (
                            <div className="flex justify-center mt-2">
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={loadMoreOrdersNa}
                              >
                                {'ย่อ'}
                              </Button>
                            </div>
                          ) : (
                            <div className="flex justify-center mt-2">
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={loadMoreOrders}
                              >
                                {'แสดงเพิ่ม'}
                              </Button>
                            </div>
                          )}
                        </>
                      )}
                    </TableCell>
                    {/* <TableCell>
                      {row?.orders?.map((eachOrder, _index) => (
                        <div key={_index} className="w-full py-1">
                          <div className="">{eachOrder?.warehouse?.name}</div>
                        </div>
                      ))}
                    </TableCell>
                    <TableCell>
                      {row?.orders?.map((eachOrder, _index) => (
                        <div key={_index} className="w-full py-1">
                          <div className="flex gap-1">
                            <p className="">{eachOrder?.amount}</p>
                            <p className=" ">{eachOrder?.product?.unit}</p>
                          </div>
                        </div>
                      ))}
                    </TableCell> */}
                    <TableCell>
                      {row?.status_export === 'WAIT_FOR_EXPORT' ? (
                        <div className="rounded-md bg-yellow-400 p-1 text-center">
                          รอจ่ายสินค้า
                        </div>
                      ) : (
                        <div className="rounded-md bg-green-300 p-1 text-center">
                          รับสินค้าแล้ว
                        </div>
                      )}
                      {row?.status_return === 'ALL_IN' && (
                        <div className="rounded-md bg-green-400 p-1 text-center mt-1">
                          คืนสินค้าแล้ว
                        </div>
                      )}
                    </TableCell>
                    <TableCell>
                      {`${dayjs(row?.createdAt).format('D MMM BBBB ')}`}
                    </TableCell>
                    <TableCell>
                      {`${
                        // eslint-disable-next-line no-nested-ternary
                        row?.status_export === 'SUCCESS'
                          ? row?.real_export_date
                            ? dayjs(row?.real_export_date).format('D MMM BBBB ')
                            : '-'
                          : '-'
                      }`}
                    </TableCell>
                    <TableCell>
                      <div>{row?.export_employee_real?.firstname || '-'}</div>
                    </TableCell>
                    <TableCell>
                      <div className="mb-1">
                        <Button
                          variant="contained"
                          fullWidth
                          startIcon={<LogoutIcon />}
                          // onClick={() => {
                          //   setExportId(row?.id);
                          //   handleOpenExportOrder();
                          // }}
                          onClick={() => {
                            setEditExportAmount(row);
                            setUploadedImage(row?.images || []);
                            handleOpenEditExportAmount(row);
                            setNameExport(row?.export_employee_real_string);
                          }}
                          disabled={row?.status_export === 'SUCCESS'}
                        >
                          จ่ายสินค้า
                        </Button>
                      </div>
                      <div className="mb-1">
                        <Button
                          variant="contained"
                          fullWidth
                          color="success"
                          startIcon={<CheckCircleIcon />}
                          onClick={() => {
                            handleUpdateStatus(row?.id);
                          }}
                          disabled={row?.status_export === 'SUCCESS'}
                        >
                          จ่ายสำเร็จ
                        </Button>
                      </div>
                      <div>
                        <Button
                          variant="contained"
                          fullWidth
                          color="error"
                          startIcon={<CancelIcon />}
                          onClick={() => {
                            handleDelete(row?.id);
                          }}
                          disabled={row?.status_export === 'SUCCESS'}
                        >
                          ยกเลิก
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <div className="text-center">ไม่มีข้อมูล</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          rowsPerPageOptions={[5, 10, 25, 100]}
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={page - 1}
          count={total || 1}
          rowsPerPage={size}
          onPageChange={handleChangePage}
        />
      </Paper>
    </div>
  );

  if (productTransactionExport.isLoading || !productTransactionExport.rows) {
    return <Loading />;
  }
  if (
    !productTransactionExport.isLoading &&
    productTransactionExport.isCompleted
  ) {
    if (accessSubModule(me?.userData?.access, module, subModule)) {
      return (
        <div className="">
          {renderModalExportOrders()}
          {renderModalEditExportAmount()}
          <div className="flex justify-between">
            <div>{renderTitle()}</div>
            <div className="mt-6">{renderAddButton()}</div>
          </div>
          {renderSearch()}
          {renderTable()}
        </div>
      );
    }
    return <NotAuthorized />;
  }
  return <Error message={productTransactionExport?.message} />;
};

ExportsManagement.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

ExportsManagement.defaultProps = {
  title: '',
  subtitle: '',
};

export default ExportsManagement;
