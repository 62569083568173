import {
  DRIVERLINE_ALL,
  DRIVERLINE_GET,
  DRIVERLINE_DEL,
  DRIVERLINE_PUT,
  DRIVERLINE_POST,
  DRIVERLINE_LOADING,
  DRIVERLINE_ERROR,
} from '../../actions/types';

const initialState = {
  driverLine: null,
  isLoading: true,
  isCompleted: true,
};
// eslint-disable-next-line func-names
export default function (state = initialState, action) {
  switch (action.type) {
    case DRIVERLINE_LOADING:
      return { isLoading: true, isCompleted: true };
    case DRIVERLINE_ERROR:
      return { isLoading: false, isCompleted: false, ...action.payload };
    case DRIVERLINE_ALL:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case DRIVERLINE_GET:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case DRIVERLINE_POST:
      return { isLoading: false, isCompleted: true };
    case DRIVERLINE_PUT:
      return { isLoading: false, isCompleted: true };
    case DRIVERLINE_DEL:
      return { isLoading: false, isCompleted: true };
    default:
      return state;
  }
}
