/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import dayjs from 'dayjs';

import {
  Card,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Autocomplete,
  TextField,
  Modal,
  Backdrop,
  Fade,
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';

import { Trash2 as Delete } from 'react-feather';

import { useForm, Controller, useFieldArray } from 'react-hook-form';
import PropTypes from 'prop-types';
// eslint-disable-next-line no-unused-vars
import _ from 'lodash';
import * as actions from '../../redux/actions';
import { EditReturnForm, AddProductEditForm } from '../../components/Forms';
import Loading from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';
import { BackButton } from '../../components/Button';

const defaultValues = {
  type_code: '',
  name: '',
  description: '',
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90vw',
  boxShadow: 24,
  p: 4,
};

const EditOrderReturn = ({ title, subtitle }) => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const productTransactionReturn = useSelector(
    (state) => state.productTransactionReturn,
  );

  const customer = useSelector((state) => state.customer);
  const me = useSelector((state) => state.me);
  const product = useSelector((state) => state.product);
  const productType = useSelector((state) => state.productType);
  const employee = useSelector((state) => state.employee);
  const warehouse = useSelector((state) => state.warehouse);

  const history = useHistory();
  const [reload, setReload] = useState(false);
  const [productList, setProductList] = useState([]);
  const [selectSerial, setSelectSerial] = useState([]);
  const [selectOption, setSelectOption] = useState([]);
  const [testReset, setTestReset] = useState(0);

  const [createLoading, setCreateLoading] = useState(false);
  const [openEditProduct, setOpenEditProduct] = useState(false);

  console.log(
    'productTransactionReturn in edit page',
    productTransactionReturn,
  );

  // Setting React hook form
  const {
    formState: { errors },
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
  } = useForm({
    mode: 'onBlur',
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'itemList',
  });

  const [name] = useState('');
  const [page] = useState(1);
  const [size] = useState(5);
  const [type1, setType1] = useState(null);
  const [filterProduct, setFilterProduct] = useState(null);
  const [defaultMe, setDefaultMe] = useState(me?.userData);
  const [uploadedImage, setUploadedImage] = useState('');

  // console.log(
  //   "dayjs(new Date()).format('BBMM')",
  //   dayjs(new Date()).format('BBMM'),
  // );

  useEffect(() => {
    dispatch(actions.productTransactionReturnReset());
    dispatch(actions.productTransactionReturnGet(id));
    dispatch(actions.productAllWithLightWeight(''));
    dispatch(actions.warehouseAll(''));
    dispatch(actions.employeeAll(''));
    dispatch(actions.productTypeAll(''));
    dispatch(actions.customerAll(''));
    dispatch(actions.meGet());
    setDefaultMe(me?.userData);

    return () => {};
  }, [name, page, size]);

  useEffect(() => {
    setProductList(productTransactionReturn?.orders);
    setValue('itemList', productTransactionReturn?.orders);
    return () => {};
  }, [productTransactionReturn, testReset]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const handleOpenEditProduct = () => {
    setOpenEditProduct(true);
  };
  const handleCloseEditProduct = () => {
    setOpenEditProduct(false);
  };

  console.log('productList', productList);
  console.log('fields', fields);

  const onSubmit = async (data, event) => {
    console.log('data on submit', data);
    console.log('productList on submit', productList);
    setCreateLoading(true);
    try {
      const preprocessedData = {
        ...data,
        _id: productTransactionReturn?._id,
        customer: data?.customer?._id,
        project_number: data?.project_number,
        return_import_employee_string: data?.return_import_employee_string,
        bill_number: data?.bill_number,
        import_date: productTransactionReturn?.import_date || data?.import_date,
        remark: data?.remark,
        modify_employee: me?.userData?._id,
        images: _.map(uploadedImage, (image) => ({
          image: image.data_url,
        })),
        running_number: productTransactionReturn?.running_number,
        orders: _.map(productList, (each) => ({
          // eslint-disable-next-line no-underscore-dangle
          product: each.product._id,
          warehouse: each.warehouse._id,
          amount: each.amount,
        })),
      };

      console.log('preprocessedData', preprocessedData);

      await dispatch(actions.productTransactionReturnPut(id, preprocessedData));
      setCreateLoading(false);
      await dispatch(actions.productTransactionReturnReset());
      await dispatch(actions.productTransactionReturnGet(id));
      event.preventDefault();
      reset(defaultValues);
      setCreateLoading(false);
      await dispatch(actions.productTransactionReturnAll({ name, page, size }));
      history.goBack();
    } catch (error) {
      console.error(error);
    }
  };

  console.log('fields', fields);

  const onSubmitUpdateOrders = async (data) => {
    console.log('data', data);
    try {
      setCreateLoading(true);

      console.log('DATA', data);
      const newOrders = _.map(data?.itemList, (item) => {
        const payload = {
          ...item,
          product: item?.product,
          warehouse: item?.warehouse,
          amount: parseInt(item?.amount, 10),
        };
        console.log('payload', payload);
        return payload;
      });

      setProductList(newOrders);
      setCreateLoading(false);
      setOpenEditProduct(false);
    } catch (error) {
      console.error(error);
    }
  };

  // const handleDeleteItem = (index) => {
  //   remove(index);
  // };

  // const handleAddItem = () => {
  //   append({
  //     product: product?.rows[0],
  //     amount: 1,
  //   });
  // };

  const renderTableEditProduct = () => (
    <div>
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size={'small'}>
            <colgroup>
              <col width="8%" />
              <col width="32%" />
              <col width="50%" />
              <col width="10%" />
            </colgroup>
            <TableHead>
              <TableRow className="bg-yellow-100">
                <TableCell>
                  <div className="font-bold py-1">ลำดับที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold py-1">คลัง</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold py-1">ชื่อสินค้า</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold py-1">จำนวน</div>
                </TableCell>
                {/* <TableCell>
                  <div className="font-bold py-1">ดำเนินการ</div>
                </TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(fields) ? (
                _.map(fields, (item, index) => (
                  <TableRow
                    key={item.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <div className="py-1">{`${index + 1}`}</div>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <div className="py-1">
                        <Controller
                          name={`itemList.${index}.warehouse`}
                          control={control}
                          // disabled
                          render={({ field }) => (
                            <Autocomplete
                              size={'small'}
                              {...field}
                              onChange={(e, newValue) => {
                                field.onChange(newValue);
                                // setSelectCustomerType(newValue);
                              }}
                              options={warehouse?.rows}
                              getOptionLabel={(type) =>
                                // eslint-disable-next-line implicit-arrow-linebreak
                                `${type?.name}`
                              }
                              required
                              disabled={watch(`itemList.${index}.warehouse`)}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="เลือกคลัง"
                                  required
                                />
                              )}
                            />
                          )}
                        />
                      </div>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <div className="py-1">
                        <Controller
                          name={`itemList.${index}.product`}
                          control={control}
                          // disabled
                          render={({ field }) => (
                            <Autocomplete
                              size={'small'}
                              {...field}
                              onChange={(e, newValue) => {
                                field.onChange(newValue);
                                // setSelectCustomerType(newValue);
                              }}
                              defaultValue={
                                product?.rows ? product?.rows[0] : ' '
                              }
                              options={product?.rows}
                              getOptionLabel={(type) =>
                                // eslint-disable-next-line implicit-arrow-linebreak
                                `${type?.name} ( ${type?.model_name} )`
                              }
                              required
                              disabled={watch(`itemList.${index}.product`)}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="เลือกสินค้า"
                                  required
                                />
                              )}
                            />
                          )}
                        />
                      </div>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <div className="py-1">
                        <Controller
                          name={`itemList.${index}.amount`}
                          control={control}
                          defaultValue={watch(`itemList.${index}.amount`) || 1}
                          render={({ field }) => (
                            <TextField
                              size="small"
                              fullWidth
                              required
                              type="number"
                              label="จำนวน"
                              {...field}
                            />
                          )}
                        />
                      </div>
                    </TableCell>
                    {/* <TableCell component="th" scope="row">
                      <Button
                        size="sm"
                        variant="outlined"
                        color="error"
                        onClick={() => {
                          handleDeleteItem(index);
                        }}
                      >
                        <Delete size={16} color="red" />
                      </Button>
                    </TableCell> */}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <div className="text-center">ไม่มีข้อมูล</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      {/* <div className="mt-2 w-full flex justify-center">
        <Button
          size="small"
          variant="outlined"
          color="success"
          onClick={() => {
            handleAddItem();
          }}
        >
          เพิ่มสินค้า
        </Button>
      </div> */}
    </div>
  );

  const renderModalEditProducts = () => (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={openEditProduct}
      onClose={handleCloseEditProduct}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openEditProduct}>
        <Card sx={style} className="max-w-8xl my-4">
          <form onSubmit={handleSubmit(onSubmitUpdateOrders)}>
            <div className="max-h-screen overflow-y-auto">
              <div className="flex justify-between">
                <div className="text-xl pb-2 underline">แก้ไขข้อมูล</div>
                <div>
                  <Button
                    variant="outlined"
                    onClick={() => setTestReset(testReset + 1)}
                  >
                    รีเซ็ต
                  </Button>
                </div>
              </div>
              <div className="flex flex-wrap my-2">
                <div className="w-full  px-1 min-h-full">
                  <div className="flex flex-wrap">
                    <div className="w-full">{renderTableEditProduct()}</div>
                  </div>
                </div>
              </div>

              <div className="flex flex-row justify-end gap-1 py-4">
                <Button
                  type="submit"
                  variant="contained"
                  startIcon={<SaveIcon />}
                >
                  บันทึก
                </Button>
                <Button variant="outlined" onClick={handleCloseEditProduct}>
                  ปิด
                </Button>
              </div>
            </div>
          </form>
        </Card>
      </Fade>
    </Modal>
  );

  const renderForm = () => (
    <Card className="p-6">
      <div className="py-2">เพิ่มข้อมูล</div>
      <EditReturnForm
        Controller={Controller}
        control={control}
        errors={errors}
        watch={watch}
        customers={customer.rows}
        product={product.rows}
        productType={productType.rows}
        employees={employee.rows}
        warehouse={warehouse.rows}
        type={type1}
        setType={setType1}
        filterProduct={filterProduct}
        setFilterProduct={setFilterProduct}
        me={me}
        productTransaction={productTransactionReturn}
        uploadedImage={uploadedImage}
        setUploadedImage={setUploadedImage}
      />
    </Card>
  );

  const displayProduct = () => (
    <TableContainer>
      <Table size="small" className="border-lg">
        <TableHead className="bg-yellow-100">
          <TableRow>
            <TableCell>
              <div className="font-bold">ลำดับที่</div>
            </TableCell>
            <TableCell>
              <div className="font-bold"> คลัง</div>
            </TableCell>
            <TableCell>
              <div className="font-bold"> ชื่อสินค้า</div>
            </TableCell>
            <TableCell>
              <div className="font-bold"> รุ่นสินค้า</div>
            </TableCell>
            <TableCell>
              <div className="font-bold"> จำนวน</div>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!_.isEmpty(productList) ? (
            productList.map((_product, index) => (
              <TableRow
                key={index}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                className={index % 2 === 0 ? 'bg-gray-100' : ''}
              >
                <TableCell>{index + 1}</TableCell>
                <TableCell>
                  <div className="my-auto">
                    <>{_product?.warehouse?.name || '-'}</>
                  </div>
                </TableCell>
                <TableCell>{_product?.product?.name}</TableCell>
                <TableCell>{_product?.product?.model_name}</TableCell>
                <TableCell>{`${_product?.amount} ${_product?.product?.unit}`}</TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow key={''}>
              <TableCell colSpan={6}>
                <div className="text-center">ไม่มีรายการ</div>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const renderShowProductList = () => (
    <Card className="p-6">
      <div className="flex justify-between gap-1 mb-2">
        <div>รายการสินค้า</div>
        <div className="">
          <Button
            fullWidth
            variant="contained"
            startIcon={<EditIcon />}
            onClick={() => {
              handleOpenEditProduct();
            }}
          >
            แก้ไขข้อมูล
          </Button>
        </div>
      </div>
      {displayProduct()}
    </Card>
  );

  if (
    !productTransactionReturn._id ||
    customer.isLoading ||
    product.isLoading ||
    employee.isLoading ||
    createLoading
  ) {
    return <Loading />;
  }
  if (
    !productTransactionReturn.isLoading &&
    productTransactionReturn.isCompleted &&
    !product.isLoading &&
    product.isCompleted &&
    !customer.isLoading &&
    customer.isCompleted &&
    !employee.isLoading &&
    employee.isCompleted &&
    !createLoading
  ) {
    return (
      <div>
        {renderModalEditProducts()}

        <div className="flex justify-between">
          <div>{renderTitle()}</div>
        </div>
        <div className="flex flex-row justify-start pb-4">
          <div>
            <BackButton />
          </div>
          <div className="text-lg font-semibold ml-6">แก้ไขการรับคืนสินค้า</div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="md:flex">
            <div className="w-full md:w-1/3 pr-4">{renderForm()}</div>
            <div className="w-full md:w-2/3 pt-4 md:pt-0">
              <div className="w-full">{renderShowProductList()}</div>
            </div>
          </div>
          <div className="flex flex-row justify-end gap-1 py-4">
            <Button variant="contained" type="submit" startIcon={<SaveIcon />}>
              บันทึก
            </Button>
          </div>
        </form>
      </div>
    );
  }
  return <Error message={customer?.message} />;
};

EditOrderReturn.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

EditOrderReturn.defaultProps = {
  title: '',
  subtitle: '',
};

export default EditOrderReturn;
