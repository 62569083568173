/* eslint-disable import/extensions */
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Chart from 'react-apexcharts';

// eslint-disable-next-line object-curly-newline
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
  Card,
} from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

import { useParams } from 'react-router';

import * as actions from '../../redux/actions';
import Loading from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';
// import ProductGraph from '../../components/Graph/ProductGraph';
// import ProductDonut from '../../components/Graph/ProductDonut';
// import ProductGraphProfit from '../../components/Graph/ProductGraphProfit';
import StatusCards from '../../components/Graph/StatusCards';
import StatusCardsOnline from '../../components/Graph/StatusCardsOnline';
import TableStatus from '../../components/Graph/TableStatus';
import TableStatusOnline from '../../components/Graph/TableStatusOnline';

const ProductDashboard = ({ title, subtitle }) => {
  const dispatch = useDispatch();
  const me = useSelector((state) => state.me);
  const { id } = useParams();
  const product = useSelector((state) => state.product);

  // console.log(
  //   'first date',
  //   _.first(product?.filter_sales_offline_out_before)?.date,
  // );
  // console.log(
  //   'last date',
  //   _.last(product?.filter_sales_offline_out_before)?.date,
  // );

  const name = '';

  const [loading, setLoading] = useState(true);

  const NewArrayData = _.map(
    _.groupBy(product.filter_sales_offline_out_before, 'date'),
    (e, i) => {
      const data = {
        data: _.sumBy(e, 'amount'),
        date: i,
      };
      return data;
    },
  );

  // console.log('NewArrayData', NewArrayData);

  useEffect(() => {
    const fetchCustomer = async () => {
      try {
        await dispatch(actions.customerAll({ name }));
      } catch (error) {
        console.error(error);
      }
    };
    const fetchMe = async () => {
      try {
        await dispatch(actions.meGet(''));
      } catch (error) {
        console.error(error);
      }
    };
    fetchCustomer();
    fetchMe();

    return () => {};
  }, []);

  useEffect(async () => {
    setLoading(true);

    await dispatch(actions.productGet(id));

    setLoading(false);
    console.log('ทำ2');
    return () => {};
  }, []);

  const getDateArray = function (start, end) {
    // eslint-disable-next-line no-array-constructor
    const arr = new Array();
    const dt = new Date(start);
    while (dt <= end) {
      arr.push(new Date(dt).getTime());
      dt.setDate(dt.getDate() + 1);
    }
    return arr;
  };

  const start1 = new Date(_.first(_.sortBy(NewArrayData, 'date'))?.date);

  const end1 = new Date(
    _.first(_.sortBy(NewArrayData, 'date').reverse())?.date,
  );

  console.log('getDateArray', getDateArray(start1, end1));
  // console.log('getDateArray', new Date(start1).getTime());
  // while (start1 <= end1) {
  //   const newDate1 = start1.setDate(start1.getDate() + 1);
  //   console.log(new Date(newDate1).getTime());
  // }

  const NewProductTransactionDay = _.sortBy(
    _.map(NewArrayData, (e) => {
      const data = {
        amount: e.data,
        date: new Date(e.date).getTime(),
      };
      return data;
    }),
    ['date'],
  );

  console.log('NewProductTransactionDay', NewProductTransactionDay);

  const NewProductTransactionDayNew = _.map(
    getDateArray(start1, end1),
    (e, i) => {
      if (_.find(NewProductTransactionDay, (b) => b.date === e)) {
        const data = {
          amount: NewProductTransactionDay[i]?.amount,
          date: e,
        };
        return data;
      }
      const data = {
        amount: 0,
        date: e,
      };
      return data;
    },
  );

  console.log('NewProductTransactionDayNew', NewProductTransactionDayNew);

  const generateDayWiseTimeSeries = (count) => {
    let i = 0;
    const series = [];

    while (i < count) {
      series.push([
        NewProductTransactionDay[i]?.date,
        NewProductTransactionDay[i]?.amount,
      ]);
      i += 1;
    }
    // console.log('series', series);
    return series;
  };

  const chartSeriesLine = [
    {
      name: 'ยอดขาย',
      data: generateDayWiseTimeSeries(
        _.size(product?.filter_sales_offline_out_before),
      ),
    },
  ];

  const chartOptionsLine = {
    chart: {
      type: 'line',
      height: 300,
      foreColor: '#999',
      stacked: true,
      dropShadow: {
        enabled: true,
        enabledSeries: [0],
        top: -2,
        left: 2,
        blur: 5,
        opacity: 0.06,
      },
      locales: [
        {
          name: 'th',
          options: {
            months: [
              'มกราคม',
              'กุมภาพันธ์',
              'มีนาคม',
              'เมษายน',
              'พฤษภาคม',
              'มิถุนายน',
              'กรกฎาคม',
              'สิงหาคม',
              'กันยายน',
              'ตุลาคม',
              'พฤศจิกายน',
              'ธันวาคม',
            ],
            shortMonths: [
              'ม.ค.',
              'ก.พ.',
              'มี.ค.',
              'เม.ย.',
              'พ.ค.',
              'มิ.ย.',
              'ก.ค.',
              'ส.ค.',
              'ก.ย.',
              'ต.ค.',
              'พ.ย.',
              'ธ.ค.',
            ],
            days: [
              'อาทิตย์',
              'จันทร์',
              'อังคาร',
              'พุธ',
              'พฤหัสบดี',
              'ศุกร์',
              'เสาร์',
            ],
            shortDays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
            toolbar: {
              exportToSVG: 'Download SVG',
              exportToPNG: 'Download PNG',
              menu: 'Menu',
              selection: 'Selection',
              selectionZoom: 'Selection Zoom',
              zoomIn: 'Zoom In',
              zoomOut: 'Zoom Out',
              pan: 'Panning',
              reset: 'Reset Zoom',
            },
          },
        },
      ],
      defaultLocale: 'th',
    },
    stroke: {
      curve: 'smooth',
      width: 3,
    },
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 0,
      strokeColor: '#fff',
      strokeWidth: 3,
      strokeOpacity: 1,
      fillOpacity: 1,
      hover: {
        size: 6,
      },
    },
    xaxis: {
      type: 'datetime',
      labels: {
        rotate: -45,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      rotateAlways: true,
      hideOverlappingLabels: true,
      crosshairs: {
        fill: {
          type: 'gradient',
          gradient: {
            colorFrom: '#D8E3F0',
            colorTo: '#BED1E6',
            stops: [0, 100],
            opacityFrom: 0.4,
            opacityTo: 0.5,
          },
        },
      },
    },
    yaxis: {
      labels: {
        offsetX: 14,
        offsetY: -5,
      },
      tooltip: {
        enabled: true,
      },
    },
    grid: {
      padding: {
        left: -5,
        right: 5,
      },
    },
    tooltip: {
      y: {
        formatter(val) {
          return `${parseFloat(val, 10 || 0, 10).toLocaleString(
            undefined,
          )} ชิ้น`;
        },
      },
      x: {
        format: 'dd MMM yyyy',
      },
    },
    legend: {
      position: 'top',
      horizontalAlign: 'left',
    },
    fill: {
      type: 'solid',
      fillOpacity: 0.7,
    },
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (me.isLoading || product.isLoading) {
    return <Loading />;
  }
  if (
    !me.isLoading &&
    me.isCompleted &&
    !product.isLoading &&
    product.isCompleted
  ) {
    return (
      <div>
        <div>
          กำลังพัฒนา
          {/* <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={!loading}
          onClose={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop> */}
          <div className="flex justify-between">
            <div>{renderTitle()}</div>
          </div>
          <div className="">กราฟการขายของสินค้าขายดี 10 อันดับแรกของร้าน</div>
          <div>
            <Card className="p-6">
              <h1 className="font-bold text-xl text-center">
                สรุปยอดขายโดยรวมสินค้า
              </h1>
              <h1 className="text-center">{product?.name}</h1>
              <div className="w-full justify-self-center">
                <Chart
                  options={chartOptionsLine}
                  series={chartSeriesLine}
                  type="line"
                />
              </div>
            </Card>
          </div>
        </div>
      </div>
    );
  }
  return <Error message={me?.message} />;
};

ProductDashboard.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

ProductDashboard.defaultProps = {
  title: '',
  subtitle: '',
};

export default ProductDashboard;
