/* eslint-disable arrow-body-style */
/* eslint-disable operator-linebreak */
import React, { useState } from 'react';
import _ from 'lodash';
import { Autocomplete, TextField, Tooltip, Button } from '@mui/material';
import PropTypes from 'prop-types';

// import CircularProgress from '@mui/material/CircularProgress';

import dayjs from 'dayjs';
import CreateCustomer from '../../Modal/CreateCustomer';

import { HRMS, SPM } from '../../../utils/Proptypes';
import ImageUpload from '../../ImageUpload/ImageUpload';

export const OrderForm = ({
  errors,
  Controller,
  control,
  customers,
  employees,
  me,
  warehouse,
  uploadedImage,
  setUploadedImage,
  setSelectWarehouse,
}) => {
  const [isModalCreateCustomerOpen, setIsModalCreateCustomerOpen] = useState(
    false,
  );
  const [reload, setReload] = useState(false);
  const handleOpen = () => setIsModalCreateCustomerOpen(true);
  const handleClose = () => {
    setIsModalCreateCustomerOpen(false);
  };

  console.log('warehouse', warehouse);

  const NewWarehouse = _.map(warehouse, (w, index) => ({
    index: index + 1,
    ...w,
  }));

  return (
    <div>
      <CreateCustomer
        isOpen={isModalCreateCustomerOpen}
        handleOnClose={handleClose}
      />
      <div className="flex flex-row flex-wrap">
        <div className="w-full px-1 py-1">
          <Controller
            name={'warehouse'}
            control={control}
            rules={{ required: false }}
            render={({ field }) => (
              <Autocomplete
                size={'small'}
                disableClearable
                {...field}
                onChange={(e, newValue) => {
                  field.onChange(newValue);
                  setSelectWarehouse(newValue);
                  setReload(!reload);
                }}
                options={NewWarehouse}
                getOptionLabel={(option) =>
                  // eslint-disable-next-line implicit-arrow-linebreak
                  `${option?.index} : ${option?.name}`
                }
                required
                renderInput={(params) => (
                  <TextField {...params} label="คลังสินค้า" />
                )}
              />
            )}
          />
        </div>
        <div className="w-full px-1 py-1">
          <div className="py-1">วันนำเข้าสินค้า</div>
          <Controller
            name={'date'}
            control={control}
            defaultValue={dayjs(new Date()).locale('th').format('YYYY-MM-DD')}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                type="date"
                format={'DD/MM/YYYY'}
                fullWidth
                size={'small'}
                helperText={errors.expirationDate && 'กรุณาใส่ข้อมูล'}
              />
            )}
          />
        </div>
        <div className="w-full px-1 py-2">
          <Controller
            name={'employee'}
            control={control}
            rules={{ required: false }}
            render={({ field }) => (
              <Autocomplete
                size={'small'}
                disableClearable
                {...field}
                onChange={(e, newValue) => {
                  field.onChange(newValue);
                  setReload(!reload);
                }}
                options={employees}
                getOptionLabel={(option) =>
                  // eslint-disable-next-line implicit-arrow-linebreak
                  `${option?.firstname} ${option?.lastname}`
                }
                defaultValue={me?.userData || employees[0]}
                renderInput={(params) => (
                  <TextField {...params} label="ผู้นำเข้าสินค้า" />
                )}
              />
            )}
          />
        </div>
        <div className="w-full px-1 py-2">
          <Controller
            name={'remark'}
            control={control}
            rules={{ required: false }}
            render={({ field }) => (
              <TextField
                {...field}
                label="หมายเหตุ"
                fullWidth
                size={'small'}
                rows={3}
                multiline={true}
                helperText={errors.remark && 'กรุณาใส่ข้อมูล'}
              />
            )}
          />
        </div>
        <div className="p-2 bg-gray-600 text-white my-2 w-full">
          รูปภาพการนำเข้า
        </div>
        <div className="w-full px-1">
          <ImageUpload
            images={uploadedImage}
            setImages={setUploadedImage}
            preview_size="250"
            maxNumber={1}
          />
        </div>
      </div>
    </div>
  );
};

OrderForm.propTypes = {
  errors: PropTypes.shape({
    product_transaction_type: PropTypes.object,
    orderNumber: PropTypes.billNumber,
    customer: PropTypes.array,
    source: PropTypes.object,
    quantity: PropTypes.object,
    price: PropTypes.object,
    product_stock_lots: PropTypes.object,
    warehouse: PropTypes.object,
    product_type: PropTypes.array,
    productionDate: PropTypes.object,
    expirationDate: PropTypes.object,
    receiptDate: PropTypes.object,
    sellDate: PropTypes.object,
    remark: PropTypes.object,
    recipientName: PropTypes.object,
    sellName: PropTypes.object,
    trackingNumber: PropTypes.object,
    sellPrice: PropTypes.object,
    shippingCost: PropTypes.object,
    discount: PropTypes.object,
    payment: PropTypes.payment,
  }),
  product: PropTypes.array,
  productType: PropTypes.array,
  productTransactionType: PropTypes.array,
  order: PropTypes.oneOf([SPM.Order, SPM.OrderArray]),
  Controller: PropTypes.func,
  control: PropTypes.object,
  type: PropTypes.object,
  setType: PropTypes.func,
  selectProduct: PropTypes.array,
  setSelectProduct: PropTypes.func,
  filterProduct: PropTypes.object,
  setFilterProduct: PropTypes.func,
  customers: PropTypes.array,
  warehouse: PropTypes.array,
  departments: HRMS.departmentArray,
  watch: PropTypes.func.isRequired,
  products: PropTypes.object,
  selectedProducts: PropTypes.array,
  setSelectedProducts: PropTypes.func,
  employees: PropTypes.array,
  productTypes: PropTypes.object,
  me: PropTypes.object,
  uploadedImage: PropTypes.array,
  setUploadedImage: PropTypes.func,
  setSelectWarehouse: PropTypes.func,
};

OrderForm.defaultProps = {
  product: null,
  productType: null,
  productTransactionType: null,
  customer: null,
  department: null,
  me: null,
  type: null,
  selectProduct: null,
  filterProduct: null,
};

export default OrderForm;
