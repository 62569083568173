import {
  SEOMANAGEMENT_ALL,
  SEOMANAGEMENT_GET,
  SEOMANAGEMENT_DELETE,
  SEOMANAGEMENT_UPDATE,
  SEOMANAGEMENT_CREATE,
  SEOMANAGEMENT_LOADING,
  SEOMANAGEMENT_ERROR,
  SEOMANAGEMENT_RESET,
} from '../../actions/types';

const initialState = {
  SEOManagement: null,
  isLoading: true,
  isCompleted: false,
};
// eslint-disable-next-line func-names
export default function (state = initialState, action) {
  switch (action.type) {
    case SEOMANAGEMENT_ALL:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case SEOMANAGEMENT_GET:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case SEOMANAGEMENT_CREATE:
      return { isLoading: false, isCompleted: true };
    case SEOMANAGEMENT_UPDATE:
      return { isLoading: false, isCompleted: true };
    case SEOMANAGEMENT_DELETE:
      return { isLoading: false, isCompleted: true };
    case SEOMANAGEMENT_RESET:
      return { customer: null, isLoading: true, isCompleted: true };
    case SEOMANAGEMENT_LOADING:
      return { isLoading: true, isCompleted: false };
    case SEOMANAGEMENT_ERROR:
      return { isLoading: false, isCompleted: false };
    default:
      return state;
  }
}
