import { TextField, Checkbox, FormControlLabel } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

import ImageUpload from '../../ImageUpload/ImageUpload';

export function ProductTypeForm({
  errors,
  sponsor,
  Controller,
  control,
  addImage,
  setAddImage,
}) {
  return (
    <div className="flex flex-row flex-wrap">
      <div className="w-full px-1 py-2">
        <Controller
          name={'name'}
          control={control}
          defaultValue={sponsor ? sponsor.name : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="ชื่อ"
              fullWidth
              size={'small'}
              helperText={errors.name && 'กรุณาใส่ข้อมูล'}
              required
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name={'description'}
          control={control}
          defaultValue={sponsor ? sponsor.description : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="รายละเอียด"
              fullWidth
              size={'small'}
              helperText={errors.description && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>

      <div className="w-full px-1 py-2">
        <Controller
          name={'url'}
          control={control}
          defaultValue={sponsor ? sponsor.url : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="ลิงค์"
              fullWidth
              size={'small'}
              helperText={errors.description && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>

      <div className="w-full pl-2">
        <Controller
          name={'show_front'}
          control={control}
          defaultValue={sponsor ? sponsor.show_front : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <FormControlLabel
              control={<Checkbox {...field} defaultChecked={field.value} />}
              label="แสดงหน้าเว็ปไซต์"
            />
          )}
        />
      </div>

      <div className="w-full px-1 py-2">
        <ImageUpload
          preview_size="250"
          profile={1}
          maxNumber={1}
          images={addImage}
          setImages={setAddImage}
          title={sponsor?.image ? 'แก้ไขรูปภาพ' : 'อัพโหลดรูปภาพ'}
        />
      </div>
    </div>
  );
}

ProductTypeForm.propTypes = {
  errors: PropTypes.shape({
    name: PropTypes.object,
    description: PropTypes.object,
  }),
  addImage: PropTypes.arrayOf(PropTypes.object),
  setAddImage: PropTypes.func.isRequired,
  sponsor: PropTypes.object,
  Controller: PropTypes.func,
  control: PropTypes.object,
  showFront: PropTypes.bool,
  setShowFront: PropTypes.func,
};

ProductTypeForm.defaultProps = {
  sponsor: null,
};

export default ProductTypeForm;
