/* eslint-disable no-confusing-arrow */
import _ from 'lodash';
import appConfig from '../../configs/app';

const columns = [
  {
    label: 'ลำดับที่',
    value: 'index',
  },
  {
    label: 'รูปภาพ',
    value: 'image_show',
  },
  {
    label: 'รหัสสินค้า',
    value: 'type_code',
  },
  {
    label: 'ชื่อสินค้า',
    value: 'name',
  },
  {
    label: 'รุ่นสินค้า',
    value: 'model_name',
  },
  {
    label: 'แบรนด์สินค้า',
    value: (col) => col?.brand.name,
  },
  {
    label: 'ประเภทสินค้า',
    value: (col) => col?.product_type?.name,
  },
  {
    label: 'ลิงค์รูปภาพ',
    value: 'image_url',
  },
  {
    label: 'คงเหลือรวม',
    value: (col) => col?.inventory,
  },
];

const query = ({ id }) => ({
  place: id,
  fetchStockLot: true,
  fetchProcess: false,
  page: 1,
  size: appConfig.maxFetchSize,
});

export default { columns, query };
