import {
  PRODUCTTYPES_ALL,
  PRODUCTTYPES_GET,
  PRODUCTTYPES_DEL,
  PRODUCTTYPES_PUT,
  PRODUCTTYPES_POST,
  PRODUCTTYPES_LOADING,
  PRODUCTTYPES_ERROR,
} from '../../actions/types';

const initialState = {
  productType: null,
  isLoading: true,
  isCompleted: true,
};
// eslint-disable-next-line func-names
export default function (state = initialState, action) {
  switch (action.type) {
    case PRODUCTTYPES_LOADING:
      return { isLoading: true, isCompleted: true };
    case PRODUCTTYPES_ERROR:
      return { isLoading: false, isCompleted: false, ...action.payload };
    case PRODUCTTYPES_ALL:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case PRODUCTTYPES_GET:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case PRODUCTTYPES_POST:
      return { isLoading: false, isCompleted: true };
    case PRODUCTTYPES_PUT:
      return { isLoading: false, isCompleted: true };
    case PRODUCTTYPES_DEL:
      return { isLoading: false, isCompleted: true };
    default:
      return state;
  }
}
