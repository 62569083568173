/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
  Button,
  Card,
  // FormControlLabel,
  // Switch,
  // Autocomplete,
  // TextField,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  InputAdornment,
  Modal,
  Fade,
  Backdrop,
  // FormControl,
  // InputLabel,
  // Select,
  // MenuItem,
  // Checkbox,
  Switch,
  TextField,
  FormControlLabel,
} from '@mui/material';

import dayjs from 'dayjs';

// import MuiAccordion from '@mui/material/Accordion';
// import MuiAccordionSummary from '@mui/material/AccordionSummary';
// import MuiAccordionDetails from '@mui/material/AccordionDetails';
// import { Trash2 as Delete } from 'react-feather';

// import { styled } from '@mui/material/styles';

import SaveIcon from '@mui/icons-material/Save';
import AddBoxIcon from '@mui/icons-material/AddBox';
import EditIcon from '@mui/icons-material/Edit';
// import DescriptionIcon from '@mui/icons-material/Description';
import DeleteIcon from '@mui/icons-material/Delete';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import DescriptionIcon from '@mui/icons-material/Description';

import { useForm, Controller } from 'react-hook-form';
import PropTypes from 'prop-types';

import { ViewTitle } from '../../components/ViewTitle';
import * as actions from '../../redux/actions';
import Loading from '../../components/Loading';
import Error from '../../components/Error/Error';

import { CouponForm } from '../../components/Forms';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90vw',
  boxShadow: 24,
  p: 4,
};

const defaultValue = {
  name: '',
  description: '',
  code: '',
  type: '',
  min: '',
  max_value: '',
  value: '',
  type_value: '',
  inventory: '',
};

const CouponManager = ({ title, subtitle }) => {
  const dispatch = useDispatch();
  const coupon = useSelector((state) => state.coupon);
  console.log('coupon', coupon?.rows);

  const history = useHistory();
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();
  const [searchTerm, setSearchTerm] = useState('');
  const [name, setName] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(undefined);
  const [open, setOpen] = useState(false);
  const [selectType, setSelectType] = useState();
  const [codeFinal, setCodeFinal] = useState(null);
  const [openTapRandom, setOpenTapRandom] = useState(false);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [stateOpenCode, setStateOpenCode] = useState(
    _.chain(coupon?.rows).keyBy('id').mapValues('status').value(),
  );

  console.log('state code', stateOpenCode);

  // console.log('endDate', endDate);
  // console.log('startDate', startDate);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChangeOpenCode = async (event) => {
    console.log('event.target', event.target);
    setStateOpenCode({
      ...stateOpenCode,
      [event.target.name]: event.target.checked,
    });
    const payload = {
      status: event.target.checked,
    };
    console.log('payload', payload);
    await dispatch(actions.couponPut(event.target.name, payload));
    await dispatch(actions.couponAll({ name, page, size }));
  };

  const handleChangeType = (event) => {
    setSelectType(event.target.value);
  };

  const handleChangeStartDate = (newValue) => {
    setStartDate(newValue);
  };

  const handleChangeEndDate = (newValue) => {
    setEndDate(newValue);
  };

  useEffect(() => {
    dispatch(actions.couponAll({ name, page, size }));
    return () => {};
  }, [name, page, size]);

  useEffect(() => {
    setStateOpenCode(
      _.chain(coupon?.rows).keyBy('code').mapValues('status').value(),
    );
    return () => {};
  }, [coupon?.rows]);

  useEffect(() => {
    setTotal(coupon?.total);
    return () => {};
  }, [coupon]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchTerm);
      setPage(1);
    }, 700);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const onSubmit = async (data) => {
    try {
      const payload = {
        ...data,
        type_value: selectType,
        start_date: startDate,
        expire_date: endDate,
      };

      const payloadCodeRandom = {
        ...data,
        type_value: selectType,
        start_date: startDate,
        expire_date: endDate,
        code: codeFinal,
      };

      console.log('payload in submit', payload);
      console.log('payloadCodeRandom in submit', payloadCodeRandom);

      if (openTapRandom) {
        await dispatch(actions.couponCreate(payloadCodeRandom));
      } else {
        await dispatch(actions.couponCreate(payload));
      }

      reset(defaultValue);
      alert('สำเร็จ');
      handleClose();
      await dispatch(actions.couponAll({ name, page, size }));
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteCoupon = async (id) => {
    const confirm = window.confirm('ยืนยันการลบข้อมูล');
    if (confirm) {
      try {
        await dispatch(actions.couponDelete(id));
        await dispatch(actions.couponAll({ name, page, size }));
      } catch (error) {
        console.error(error);
      }
    }
  };

  // const handlePushToDetailCoupon = async (id) => {
  //   history.push(`/discount/coupon/${id}`);
  // };

  const handlePushToEditCoupon = async (id) => {
    history.push(`/discount/coupon/edit/${id}`);
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const renderAddButton = () => (
    <div className="flex flex-row justify-end pb-4">
      <div>
        <Button
          startIcon={<AddBoxIcon />}
          variant="contained"
          onClick={handleOpen}
        >
          เพิ่ม
        </Button>
      </div>
    </div>
  );

  const renderSearch = () => (
    <Card>
      <div className="p-4 flex flex-row">
        <div className="w-full md:w-1/2">
          <TextField
            label="ค้นหา"
            fullWidth
            size={'small'}
            id="outlined-start-adornment"
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <i className="fas fa-search"></i>
                </InputAdornment>
              ),
            }}
          />
        </div>
      </div>
    </Card>
  );

  const renderModal = () => (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Card sx={style} className="max-w-4xl">
          <div className="w-full overflow-y-auto max-h-screen">
            <div className="py-2 text-lg">เพิ่มข้อมูลคูปอง</div>
            <div className="py-2">
              <form onSubmit={handleSubmit(onSubmit)}>
                <CouponForm
                  control={control}
                  Controller={Controller}
                  errors={errors}
                  coupon={coupon}
                  selectType={selectType}
                  handleChangeType={handleChangeType}
                  handleChangeStartDate={handleChangeStartDate}
                  handleChangeEndDate={handleChangeEndDate}
                  startDate={startDate}
                  endDate={endDate}
                  codeFinal={codeFinal}
                  setCodeFinal={setCodeFinal}
                  openTapRandom={openTapRandom}
                  setOpenTapRandom={setOpenTapRandom}
                />
                <div className="flex flex-row justify-end gap-1 py-8 mb-10">
                  <Button
                    variant="contained"
                    type="submit"
                    startIcon={<SaveIcon />}
                  >
                    บันทึก
                  </Button>
                  <Button variant="outlined" onClick={handleClose}>
                    ยกเลิก
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </Card>
      </Fade>
    </Modal>
  );

  const renderTable = () => (
    <div className="my-2">
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <div className="font-bold"> ลำดับที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ชื่อคูปอง</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> โค๊ด</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ประเภทคูปอง</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> คงเหลือ</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> วันเวลาคูปอง</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ดำเนินการ</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(coupon.rows) ? (
                coupon.rows.map((row, index) => (
                  <TableRow
                    key={row.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    className={index % 2 === 0 ? 'bg-gray-100' : ''}
                  >
                    <TableCell component="th" scope="row">
                      <div className="my-auto">
                        {(page - 1) * size + index + 1}
                      </div>
                    </TableCell>
                    <TableCell>
                      <div>{`${row.name}`}</div>
                      <div>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={stateOpenCode[row.code]}
                              onChange={handleChangeOpenCode}
                              name={row.id}
                            />
                          }
                          label="เปิด"
                        />
                      </div>
                    </TableCell>
                    <TableCell>{`${row.code}`}</TableCell>
                    <TableCell>
                      {row.type === 'bill'
                        ? 'ส่วนลดท้ายบิล'
                        : 'ส่วนลดการจัดส่ง'}
                    </TableCell>
                    <TableCell>{`${row.inventory}`}</TableCell>
                    <TableCell>
                      <div>
                        {`${dayjs(row?.start_date).format('D MMM BBBB')}`}{' '}
                        {`${dayjs(row?.start_date).format('HH:mm')}`}
                      </div>
                      <div>ถึง</div>
                      <div>
                        {`${dayjs(row?.expire_date).format('D MMM BBBB')}`}{' '}
                        {`${dayjs(row?.expire_date).format('HH:mm')}`}
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className="flex flex-row flex-wrap gap-1">
                        {/* <Button
                          variant="contained"
                          startIcon={<DescriptionIcon />}
                          color={'info'}
                          size={'small'}
                          onClick={() => handlePushToDetailCoupon(row.id)}
                        >
                          รายละเอียด
                        </Button> */}
                        <Button
                          variant="contained"
                          startIcon={<EditIcon />}
                          color={'warning'}
                          size={'small'}
                          onClick={() => handlePushToEditCoupon(row.id)}
                        >
                          แก้ไข
                        </Button>
                        <Button
                          variant="contained"
                          startIcon={<DeleteIcon />}
                          color={'error'}
                          size={'small'}
                          onClick={() => {
                            handleDeleteCoupon(row?.id);
                          }}
                        >
                          ลบ
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <div className="text-center">ไม่มีข้อมูล</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          rowsPerPageOptions={[5, 10, 25, 100]}
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={page - 1}
          count={total || 1}
          rowsPerPage={size}
          onPageChange={handleChangePage}
        />
      </Paper>
    </div>
  );

  if (coupon?.isLoading) {
    return <Loading />;
  }
  if (!coupon?.isLoading && coupon?.isCompleted) {
    return (
      <div>
        {renderModal()}
        <div className="flex justify-between">
          <div>{renderTitle()}</div>
          <div className="mt-6">{renderAddButton()}</div>
        </div>
        {renderSearch()}
        {renderTable()}
      </div>
    );
  }
  return <Error />;
};

CouponManager.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

CouponManager.defaultProps = {
  title: '',
  subtitle: '',
};

export default CouponManager;
