/* eslint-disable no-underscore-dangle */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';

import { useForm, Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
// import _ from 'lodash';

import { ViewTitle } from '../../components/ViewTitle';
import * as actions from '../../redux/actions';
import { SEOManagementForm } from '../../components/Forms';
import Loading from '../../components/Loading';
import { Error } from '../../components/Error';

function SEOManagement({ title, subtitle }) {
  const dispatch = useDispatch();
  const SEOManagements = useSelector((state) => state.SEOManagement);
  // const [logo, setLogo] = useState([]);
  // const [shortcut, setShortcut] = useState([]);
  const {
    formState: { errors },
    handleSubmit,
    control,
  } = useForm();

  useEffect(() => {
    dispatch(actions.SEOAll({}));
    return () => {};
  }, []);

  const onSubmit = async (data) => {
    try {
      // console.log(data);
      // if (!_.isEmpty(logo) || !_.isEmpty(shotcut)) {
      const payload = {
        ...data,
        // logo_url: logo[0]?.data_url,
        // shortcut_icon: shortcut[0]?.data_url,
      };
      await dispatch(actions.SEOPut(SEOManagements?._id, payload));
      // setLogo([]);
      // setShortcut([]);
      // } else {
      //   await dispatch(actions.SEOPut(SEOManagements?._id, data));
      //   await dispatch(actions.SEOAll({}));
      // }
      alert('สำเร็จ');
      dispatch(actions.SEOAll({}));
      //  await dispatch(actions.settingAll());
    } catch (error) {
      console.error(error);
    }
  };
  console.log('SEOManagement', SEOManagements);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const renderForm = () => (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div>
        <SEOManagementForm
          errors={errors}
          SEOManagement={SEOManagements}
          control={control}
          Controller={Controller}
          // logo={logo}
          // setLogo={setLogo}
          // shortcut={shortcut}
          // setShortcut={setShortcut}
        />
      </div>
      <div className="flex flex-row justify-end gap-1 py-4">
        <Button variant="contained" type="submit" startIcon={<SaveIcon />}>
          บันทึก
        </Button>
      </div>
    </form>
  );

  if (SEOManagements.isLoading) {
    return <Loading />;
  }
  if (!SEOManagements.isLoading && SEOManagements.isCompleted) {
    return (
      <div>
        {renderTitle()}
        {renderForm()}
      </div>
    );
  }
  return <Error />;
}
SEOManagement.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

SEOManagement.defaultProps = {
  title: '',
  subtitle: '',
};

export default SEOManagement;
