import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import _ from 'lodash';
// eslint-disable-next-line object-curly-newline
import { Button, Card } from '@mui/material';
import PropTypes from 'prop-types';
import SaveIcon from '@mui/icons-material/Save';
import * as actions from '../../redux/actions';
import Loading from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';

// eslint-disable-next-line import/no-unresolved, import/extensions
import RichtextEditor from '../../components/Richtext/RichtextEditor';

const CookiesPolicy = ({ title, subtitle }) => {
  const dispatch = useDispatch();
  const setting = useSelector((state) => state.setting);
  const policy = useSelector((state) => state.policy);

  const [detail, setDetail] = useState(policy?.cookies_policy);

  useEffect(() => {
    dispatch(actions.policyAll());
    return () => {};
  }, []);

  useEffect(() => {
    setDetail(policy?.cookies_policy);
    return () => {};
  }, [policy]);

  console.log('policy', policy);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;
  const onSubmit = async (data, event) => {
    try {
      const preprocessedData = {
        ...policy,
        cookies_policy: detail,
      };
      console.log('preprocessedData', preprocessedData);

      await dispatch(
        // eslint-disable-next-line no-underscore-dangle
        actions.policyPut(policy?.id, preprocessedData),
      );
      alert('สำเร็จ');
      await dispatch(actions.policyAll());
    } catch (error) {
      console.error(error);
    }
  };

  if (policy.isLoading) {
    return <Loading />;
  }

  if (!policy.isLoading && policy.isCompleted) {
    return (
      <div>
        <div className="flex justify-between">
          <div>{renderTitle()}</div>
          {/* <div className="mt-6">{renderAddButton()}</div> */}
        </div>
        <Card className="p-6">
          {/* <MainSiteForm /> */}
          <div className="flex flex-row flex-wrap">
            <RichtextEditor
              value={detail}
              setValue={setDetail}
              useImage={false}
            />
          </div>
          <div className="flex flex-row justify-end gap-1 py-4 mt-14">
            <Button
              variant="contained"
              onClick={() => onSubmit()}
              type="submit"
              startIcon={<SaveIcon />}
            >
              บันทึก
            </Button>
          </div>
        </Card>
      </div>
    );
  }
  return <Error message={setting?.message} />;
};

CookiesPolicy.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

CookiesPolicy.defaultProps = {
  title: '',
  subtitle: '',
};

export default CookiesPolicy;
