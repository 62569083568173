import {
  PROCESS_TEMPLATE_ALL,
  PROCESS_TEMPLATE_GET,
  PROCESS_TEMPLATE_POST,
  PROCESS_TEMPLATE_PUT,
  PROCESS_TEMPLATE_DEL,
  PROCESS_TEMPLATE_ERROR,
  PROCESS_TEMPLATE_LOADING,
} from '../../actions/types';

const initialState = {
  processTemplate: null,
  isLoading: true,
  isCompleted: true,
};

export default function ProcessTemplateReducer(state = initialState, action) {
  switch (action.type) {
    case PROCESS_TEMPLATE_LOADING:
      return { isLoading: true, isCompleted: true };
    case PROCESS_TEMPLATE_ERROR:
      return { isLoading: false, isCompleted: false, ...action.payload };
    case PROCESS_TEMPLATE_ALL:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case PROCESS_TEMPLATE_GET:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case PROCESS_TEMPLATE_POST:
      return { isLoading: false, isCompleted: true };
    case PROCESS_TEMPLATE_PUT:
      return { isLoading: false, isCompleted: true };
    case PROCESS_TEMPLATE_DEL:
      return { isLoading: false, isCompleted: true };
    default:
      return state;
  }
}
