import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@mui/material';
import PropTypes from 'prop-types';

import { ViewTitle } from '../../components/ViewTitle';
import * as actions from '../../redux/actions';
import {} from '../../components/Forms';
import Loading from '../../components/Loading';
import { Error } from '../../components/Error';

function Notify({ title, subtitle }) {
  const dispatch = useDispatch();
  const setting = useSelector((state) => state.setting);

  useEffect(() => {
    dispatch(actions.meGet());
    return () => {};
  }, []);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const renderForm = () => (
    <form>
      <div className="flex flex-row justify-end gap-1 py-4">
        <Button variant="contained" type="submit">
          บันทึก
        </Button>
      </div>
    </form>
  );

  if (setting.isLoading) {
    return <Loading />;
  }
  if (!setting.isLoading && setting.isCompleted) {
    return (
      <div>
        {renderTitle()}
        {renderForm()}
      </div>
    );
  }
  return <Error />;
}
Notify.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

Notify.defaultProps = {
  title: '',
  subtitle: '',
};

export default Notify;
