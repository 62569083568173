import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import {
  Button,
  FormControlLabel,
  Switch,
  TextField,
  InputAdornment,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';

import SaveIcon from '@mui/icons-material/Save';
// import DescriptionIcon from '@mui/icons-material/Description';

import { Trash2 as Delete } from 'react-feather';

export function PromotionCash({
  onSubmitDiscountPayment,
  handleChangePaymentDiscount,
  selectPaymentType,
  setSelectPaymentType,
  handleChangesetCheckDiscountCash,
  discountPayment,
  handleDeleteDiscountPayments,
  checkDiscountCash,
  discountPaymentValue,
}) {
  console.log('discountPaymentValue', discountPaymentValue);
  const renderTableDiscountPayment = () => (
    <div className="mt-2">
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size={'small'}>
            <TableHead>
              <TableRow className="bg-yellow-100">
                <TableCell>
                  <div className="font-bold py-1">ลำดับที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold py-1">ประเภทการชำระเงิน</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold py-1">ส่วนลด</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold py-1">ดำเนินการ</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(discountPayment.rows) ? (
                _.map(discountPayment.rows, (eachPayment, index) => (
                  <TableRow
                    key={eachPayment.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <div className="py-1">{`${index + 1}`}</div>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <div className="py-4">{eachPayment?.payment_type}</div>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <div className="py-4">{eachPayment?.percent} %</div>
                    </TableCell>

                    <TableCell component="th" scope="row">
                      <div className="gap-1">
                        <Button
                          size="sm"
                          variant="outlined"
                          color="error"
                          onClick={() => {
                            handleDeleteDiscountPayments(eachPayment);
                          }}
                        >
                          <Delete size={16} color="red" />
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <div className="text-center">ยังไม่มีข้อมูล</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );

  return (
    <div className="m-4">
      <div className="text-red-500">( กำลังพัฒนา )</div>

      <div className="mb-4">
        <FormControlLabel
          control={
            <Switch
              checked={checkDiscountCash}
              onChange={handleChangesetCheckDiscountCash}
            />
          }
          label="ปิด-เปิด"
        />
      </div>
      <div className="mt-6 text-lg pb-2">เพิ่มส่วนลด</div>

      <div className="w-full flex rounded-lg border-2 p-2">
        <div className="w-1/2  px-1 py-2">
          <FormControl sx={{ minWidth: 120 }} fullWidth>
            <InputLabel id="type" size={'small'}>
              ประเภทการชำระเงิน
            </InputLabel>
            <Select
              label="ประเภทการชำระเงิน"
              size={'small'}
              onChange={(e, newValue) => {
                setSelectPaymentType(newValue.props);
              }}
              fullWidth
            >
              <MenuItem value="เงินสด">เงินสด</MenuItem>
              <MenuItem value="15day_credit">เครดิต 15 วัน</MenuItem>
              <MenuItem value="30day_credit">เครดิต 30 วัน</MenuItem>
              <MenuItem value="60day_credit">เครดิต 60 วัน</MenuItem>
            </Select>
          </FormControl>
        </div>

        <div className="w-1/2 px-1 py-2">
          {selectPaymentType && (
            <div className="flex">
              <div className="w-2/3">
                <TextField
                  size="small"
                  fullWidth
                  onChange={handleChangePaymentDiscount}
                  type="number"
                  label="ส่วนลด"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className="gap-1 my-auto ml-2 w-1/3">
                <Button
                  variant="contained"
                  fullWidth
                  onClick={() => onSubmitDiscountPayment()}
                  startIcon={<SaveIcon />}
                >
                  บันทึก
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="mt-6 text-lg pb-2">รายการส่วนลด</div>
      <div>{renderTableDiscountPayment()}</div>
    </div>
  );
}

PromotionCash.propTypes = {
  onSubmitDiscountPayment: PropTypes.object,
  handleChangePaymentDiscount: PropTypes.func,
  selectPaymentType: PropTypes.object,
  setSelectPaymentType: PropTypes.func,
  handleChangesetCheckDiscountCash: PropTypes.func,
  discountPayment: PropTypes.object,
  handleDeleteDiscountPayments: PropTypes.func,
  checkDiscountCash: PropTypes.bool,
  discountPaymentValue: PropTypes.number,
};

PromotionCash.defaultProps = {
  customer: null,
};

export default PromotionCash;
