import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Button, Card } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import * as actions from '../../redux/actions';
import { ProcessTemplateForm } from '../../components/Forms';
import Loading from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';
import { BackButton } from '../../components/Button';

const defaultValues = {
  type_code: '',
  name: '',
  description: '',
};

const EditProcessTemplate = ({ title, subtitle }) => {
  const dispatch = useDispatch();
  const processTemplate = useSelector((state) => state.processTemplate);
  const departments = useSelector((state) => state.department);
  const history = useHistory();
  const params = useParams();
  const [step, setStep] = useState(processTemplate?.steps);

  // Setting React hook form
  const {
    formState: { errors },
    handleSubmit,
    control,
    watch,
    reset,
  } = useForm();

  const [name] = useState('');
  const [page] = useState(1);
  const [size] = useState(5);

  useEffect(() => {
    const fetchProcessTemplate = async () => {
      try {
        await dispatch(actions.processTemplateGet(params.id));
      } catch (error) {
        console.error(error);
      }
    };

    const fetchDepartment = async () => {
      try {
        await dispatch(actions.departmentAll({ name: '' }));
      } catch (error) {
        console.error('Department get all error', error);
      }
    };

    fetchProcessTemplate();
    fetchDepartment();

    return () => {};
  }, [name, page, size, params]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const onSubmit = async (data, event) => {
    try {
      event.preventDefault();
      const preprocessedData = { ...data, steps: step };
      console.log(preprocessedData);
      await dispatch(actions.processTemplatePut(params.id, preprocessedData));
      reset(defaultValues);
      alert('สำเร็จ');

      await dispatch(actions.processTemplateAll({ name, page, size }));
      history.goBack();
    } catch (error) {
      console.error(error);
    }
  };

  const renderForm = () => (
    <Card className="p-6">
      <div className="py-2">เพิ่มข้อมูล</div>
      <div className="py-2">
        <form onSubmit={handleSubmit(onSubmit)}>
          <ProcessTemplateForm
            Controller={Controller}
            control={control}
            errors={errors}
            processTemplate={processTemplate}
            departments={departments}
            watch={watch}
            setStep={setStep}
          />
          <div className="flex flex-row justify-end gap-1 py-4">
            <Button variant="contained" type="submit">
              บันทึก
            </Button>
          </div>
        </form>
      </div>
    </Card>
  );

  if (
    processTemplate.isLoading ||
    processTemplate.rows ||
    departments.isLoading
  ) {
    return <Loading />;
  }
  if (
    !processTemplate.isLoading &&
    processTemplate.isCompleted &&
    !departments.isLoading &&
    departments.isCompleted
  ) {
    return (
      <div>
        <div className="flex justify-between">
          <div>{renderTitle()}</div>
        </div>
        <div className="flex flex-row justify-start pb-4">
          <div>
            <BackButton />
          </div>
          <div className="text-lg font-semibold ml-6">
            แก้ไขรูปแบบไลน์การผลิต
          </div>
        </div>
        {renderForm()}
      </div>
    );
  }

  return <Error message={processTemplate?.message} />;
};

EditProcessTemplate.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

EditProcessTemplate.defaultProps = {
  title: '',
  subtitle: '',
};

export default EditProcessTemplate;
