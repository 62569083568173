import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
// eslint-disable-next-line object-curly-newline
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Card,
  TextField,
  InputAdornment,
  IconButton,
} from '@mui/material';

import AddBoxIcon from '@mui/icons-material/AddBox';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import HistoryIcon from '@mui/icons-material/History';

import dayjs from 'dayjs';
import _ from 'lodash';
import 'dayjs/locale/th';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import PropTypes from 'prop-types';
import * as actions from '../../redux/actions';
import Loading from '../../components/Loading';
import { Error, NotAuthorized } from '../../components/Error';
import accessSubModule from '../../utils/functions/accessSubModule';
import { ViewTitle } from '../../components/ViewTitle';

dayjs.locale('th');
dayjs.extend(buddhistEra);

const OrdersReturnManagement = ({ title, subtitle }) => {
  const module = 'SPM';
  const subModule = 'RETURN';
  const dispatch = useDispatch();
  const productTransactionReturn = useSelector(
    (state) => state.productTransactionReturn,
  );
  const me = useSelector((state) => state.me);

  const history = useHistory();
  // const product = useSelector((state) => state.product);
  console.log('productTransactionReturn', productTransactionReturn.rows);

  // Setting React hook form
  const [searchTerm, setSearchTerm] = useState('');
  const [name, setName] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(5);
  const [total, setTotal] = useState(undefined);
  const transactionType = 'IMPORT';

  useEffect(() => {
    dispatch(actions.meGet());

    const fetchProductTransactionReturn = async () => {
      try {
        // await dispatch(actions.productTransactionReturnReset());
        await dispatch(
          actions.productTransactionReturnAll({
            name,
            page,
            size,
            transactionType,
          }),
        );
      } catch (error) {
        console.error(error);
      }
    };
    fetchProductTransactionReturn();
    return () => {};
  }, [name, page, size]);

  useEffect(() => {
    setTotal(productTransactionReturn?.total);
    return () => {};
  }, [productTransactionReturn]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchTerm);
      setPage(1);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const handleDelete = async (id) => {
    const confirm = window.confirm('ยืนยันการลบข้อมูล');
    if (confirm) {
      try {
        await dispatch(actions.productTransactionReturnDelete(id));
        await dispatch(
          actions.productTransactionReturnAll({
            name,
            page,
            size,
            transactionType,
          }),
        );
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleToInfoPage = async (id) => {
    history.push(`/spm/order-return/${id}`);
  };

  const handleToEditPage = async (id) => {
    history.push(`/spm/order-return/edit/${id}`);
  };

  const renderAddButton = () => (
    <div className="flex flex-row justify-end pb-4">
      <Link to="/spm/order-return/create">
        <Button startIcon={<AddBoxIcon />} variant="contained">
          สร้างใบรับคืนสินค้า
        </Button>
      </Link>
    </div>
  );

  const renderImportButton = () => (
    <div className="flex flex-row justify-end pb-4">
      <Link to="/spm/orders/create_stock">
        <Button startIcon={<AddBoxIcon />} variant="outlined">
          นำสินค้าเข้าคลัง
        </Button>
      </Link>
    </div>
  );

  const renderSearch = () => (
    <Card>
      <div className="p-4 flex flex-row">
        <div className="w-full md:w-1/2">
          <TextField
            label="ค้นหา"
            fullWidth
            size={'small'}
            id="outlined-start-adornment"
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <i className="fas fa-search"></i>
                </InputAdornment>
              ),
            }}
          />
        </div>
      </div>
    </Card>
  );

  const renderTable = () => (
    <div className="my-2">
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <div className="font-bold">ลำดับที่</div>
                </TableCell>
                {/* <TableCell>
                  <div className="font-bold">หมายเลขเอกสาร</div>
                </TableCell> */}
                <TableCell>
                  <div className="font-bold">หมายเลขโครงการ/บิล</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">คู่ค้า</div>
                </TableCell>
                {/* <TableCell>
                  <div className="font-bold">ชื่อสินค้า</div>
                </TableCell> */}
                <TableCell>
                  <div className="font-bold">รุ่นสินค้า</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">จำนวนสินค้า</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">วันที่รับคืน</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">ผู้คืน</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">ผู้รับคืน</div>
                </TableCell>
                {/* <TableCell>
                  <div className="font-bold">ประวัติแก้ไข</div>
                </TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(productTransactionReturn.rows) ? (
                productTransactionReturn.rows.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    className={index % 2 === 0 ? 'bg-gray-100' : ''}
                  >
                    <TableCell component="th" scope="row">
                      <IconButton color="primary">
                        <EditOutlinedIcon
                          // disabled
                          onClick={() => handleToEditPage(row?.id)}
                        />
                      </IconButton>
                      {(page - 1) * size + index + 1}
                    </TableCell>
                    {/* <TableCell component="th" scope="row">
                      <a
                        className="hover:underline cursor-pointer"
                        onClick={() => handleToInfoPage(row?.id)}
                      >
                        {row?.running_number || '-'}
                      </a>
                    </TableCell> */}
                    <TableCell component="th" scope="row">
                      <div>{row?.project_number || '-'}</div>
                      <div>{row?.bill_number || '-'}</div>
                    </TableCell>
                    <TableCell onClick={() => handleToInfoPage(row?.id)}>
                      <a className="hover:underline cursor-pointer">
                        {row?.customer?.name || '-'}
                      </a>
                    </TableCell>
                    {/* <TableCell>
                      {row?.orders?.map((eachOrder, _index) => (
                        <div key={_index} className="w-full py-1">
                          <div className="flex">
                            <p className="">
                              {_index + 1} : {eachOrder?.product?.name}
                            </p>
                          </div>
                        </div>
                      ))}
                    </TableCell> */}
                    <TableCell>
                      {row?.orders?.map((eachOrder, _index) => (
                        <div key={_index} className="w-full py-1">
                          <div className="flex">
                            <p className="">
                              {_index + 1} : {eachOrder?.product?.model_name}
                            </p>
                          </div>
                        </div>
                      ))}
                    </TableCell>
                    <TableCell>
                      {row?.orders?.map((eachOrder, _index) => (
                        <div key={_index} className="w-full py-1">
                          <div className="flex gap-1">
                            <p className="">{eachOrder?.amount}</p>
                            <p className=" ">{eachOrder?.product?.unit}</p>
                          </div>
                        </div>
                      ))}
                    </TableCell>
                    <TableCell>
                      {`${dayjs(row?.import_date).format('D MMM BBBB ')}`}
                    </TableCell>
                    <TableCell>
                      {row?.return_import_employee?.firstname || '-'}
                    </TableCell>
                    <TableCell>
                      {row?.import_employee?.firstname || '-'}
                    </TableCell>
                    {/* <TableCell>
                      <IconButton color="primary">
                        <HistoryIcon />
                      </IconButton>
                    </TableCell> */}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <div className="text-center">ไม่มีข้อมูล</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          rowsPerPageOptions={[5, 10, 25, 100]}
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={page - 1}
          count={total || 1}
          rowsPerPage={size}
          onPageChange={handleChangePage}
        />
      </Paper>
    </div>
  );

  if (productTransactionReturn.isLoading || !productTransactionReturn.rows) {
    return <Loading />;
  }
  if (
    !productTransactionReturn.isLoading &&
    productTransactionReturn.isCompleted
  ) {
    if (accessSubModule(me?.userData?.access, module, subModule)) {
      return (
        <div className="">
          <div className="flex justify-between">
            <div>{renderTitle()}</div>
            <div className="mt-6 flex gap-2">
              {/* {renderImportButton()} */}
              {renderAddButton()}
            </div>
          </div>
          {renderSearch()}
          {renderTable()}
        </div>
      );
    }
    return <NotAuthorized />;
  }
  return <Error message={productTransactionReturn?.message} />;
};

OrdersReturnManagement.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

OrdersReturnManagement.defaultProps = {
  title: '',
  subtitle: '',
};

export default OrdersReturnManagement;
