const accessSubModule = (access, module, subModuleName) => {
  console.log('access', access);
  console.log('module', module);
  console.log('subModuleName', subModuleName);
  console.log(
    'access?.[module]?.[subModuleName]?.access',
    access?.[module]?.[subModuleName]?.access,
  );
  if (access?.[module]?.[subModuleName]?.access) {
    return true;
  }
  return false;
};

export default accessSubModule;
